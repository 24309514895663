import { theme } from "../../theme";

export const stylesComunionPage = {
  boxGeneral: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1em",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: "0",
    overflow: "auto",
  },
  innerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "50px 0px",
  },
  typography: {
    color: theme.font.primary,
    fontSize: "18px",
    fontWeight: 600,
  },
};
