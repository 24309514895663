import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { theme } from "../../theme";
import backgroundImage from "../../assets/home/home_01.jpg";
import Navbar from "../../components/navbar/main";

const Home = () => {
  const buttonStyle = {
    backgroundColor: theme.button.primary,
    color: theme.button.text,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.button.hover,
    },
    padding: "12x 35px",
  };

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "25px 0",
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontSize: "30px", fontWeight: 600 }}>
            Bienvenido a Efusión!
          </Typography>
          <Typography sx={{ margin: "10px 0" }}>
            Esto es una primera prueba gratuita de nuestro servicio por lo que
            algunas funciones pueden presentar errores. Recomendamos
            visualizarla desde una computadora.
          </Typography>
          <Typography sx={{ margin: "10px 0" }}>
            Te invitamos a recorrerla y ponerte en contacto con nosotros por
            cualquier consulta.
          </Typography>
        </Box>
        <Link to="/start">
          <Button sx={buttonStyle}>Click aca para continuar!</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Home;
