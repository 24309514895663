import React, { useEffect, useState } from "react";
import { TextField, Button, Box, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import sectorService from "../../services/sectores";
import { addNotification } from "../../utils/notifications";
import { RoutesEnum } from "../../utils/RoutesEnum";
import { useQueryClient } from "react-query";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { diasSemana, SectorEnum } from "../../enums/components/SectorEnum";
import { createHorarioData, handleDescriptionChange, handleEditClick, handleGoBack, handleHorarioChange, handleSaveClick, handleUseChange, transformHorarios } from "../../utils/pages/SectorUtils";
import { TEXT } from "../../enums/components/componentsEnum";
import { styles } from "./EditSectorStyles";

const EditSector = () => {
  const [description, setDescription] = useState("");
  const [use, setUse] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const location = useLocation();
  const sector = location.state.sector;

  const horarios = diasSemana.map(dia => {
    const horario = sector.horarioSector.find((h: any) => h.dia === dia) || {};
    return createHorarioData(
      dia,
      horario.horarioInicial ? horario.horarioInicial : "-",
      horario.horarioFinal ? horario.horarioFinal : "-",
      horario.id ? horario.id : null
    );
  });

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editedHorarios, setEditedHorarios] = useState(horarios);

  useEffect(() => {
    setDescription(sector.description);
    setUse(sector.use);
  }, [sector]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    //@ts-ignore
    sectorService
      //@ts-ignore
      .editSector(id, {
        description: description,
        use: use,
        horarioSector: transformHorarios(editedHorarios),
        sectorId: sector.id,
      })
      .then((result) => {
        addNotification(
          queryClient,
          SectorEnum.SUCCESS,
          SectorEnum.SECTOR + result.description + SectorEnum.ACTUALIZADO_CORRECTAMENTE,
        );
        navigate(RoutesEnum.SECTORES);
      })
      .catch((error) => {
        addNotification(
          queryClient,
          SectorEnum.ERROR,
          SectorEnum.ERROR_ACTUALIZAR_SECTOR + error,
        );
      });
  };

  return (
    <Box>
      <Box sx={styles.container}></Box>
      <Box sx={styles.innerContainer}>
        <Box sx={styles.header}>
          <IconButton onClick={() => handleGoBack(navigate)}>
            <ArrowBackIcon />
          </IconButton>
          <h2 style={{ color: "black" }}>{SectorEnum.EDITAR_SECTOR}</h2>
          <div></div>
        </Box>
        <div style={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            <Box sx={styles.form}>
              <TextField
                id={SectorEnum.NAMES}
                label={SectorEnum.NOMBRE}
                type={TEXT}
                value={description}
                onChange={(e) => handleDescriptionChange(e, setDescription)}
                fullWidth
              />
              <TextField
                label={SectorEnum.USO}
                type={TEXT}
                id={SectorEnum.USE}
                value={use}
                onChange={(e) => handleUseChange(e, setUse)}
                fullWidth
              />
              <TableContainer component={Paper}>
                <Table sx={styles.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{SectorEnum.DIA}</TableCell>
                      <TableCell>{SectorEnum.HORARIO_INICIAL}</TableCell>
                      <TableCell>{SectorEnum.HORARIO_FINAL}</TableCell>
                      <TableCell>{SectorEnum.ACCIONES}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {editedHorarios.map((row, index) => (
                      <TableRow key={row.dia}>
                        <TableCell component="th" scope="row">
                          {row.dia}
                        </TableCell>
                        <TableCell>
                          {editIndex === index ? (
                            <Box sx={styles.horarioInput}>
                              <TextField
                                label={SectorEnum.HORARIO_INICIAL}
                                type={SectorEnum.TIME}
                                value={row.horarioInicial}
                                onChange={(e) => handleHorarioChange(index, SectorEnum.HORARIOINICIAL, e.target.value, setEditedHorarios)}
                                InputLabelProps={{ shrink: true }}
                              />
                              <IconButton onClick={() => handleHorarioChange(index, SectorEnum.HORARIOINICIAL, '', setEditedHorarios)}>
                                <ClearIcon />
                              </IconButton>
                            </Box>
                          ) : (
                            row.horarioInicial || '-'
                          )}
                        </TableCell>
                        <TableCell>
                          {editIndex === index ? (
                            <Box sx={styles.horarioInput}>
                              <TextField
                                label={SectorEnum.HORARIO_FINAL}
                                type={SectorEnum.TIME}
                                value={row.horarioFinal}
                                onChange={(e) => handleHorarioChange(index, SectorEnum.HORARIOFINAL, e.target.value, setEditedHorarios)}
                                InputLabelProps={{ shrink: true }}
                              />
                              <IconButton onClick={() => handleHorarioChange(index, SectorEnum.HORARIOFINAL, '', setEditedHorarios)}>
                                <ClearIcon />
                              </IconButton>
                            </Box>
                          ) : (
                            row.horarioFinal || '-'
                          )}
                        </TableCell>
                        <TableCell>
                          {editIndex === index ? (
                             <IconButton onClick={() => handleSaveClick(setEditIndex)}>
                              <SaveIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleEditClick(index, setEditIndex)}>
                              <EditIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={styles.submitButton}>
              <Button type={SectorEnum.SUBMIT} variant={SectorEnum.CONTAINED} color={SectorEnum.PRIMARY}>
                {SectorEnum.ACTUALIZAR_SECTOR}
              </Button>
            </Box>
          </form>
        </div>
      </Box>
    </Box>
  );
};
export default EditSector;