import React from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import {
  AlertModalEnum,
  Status,
} from "../../../enums/components/componentsEnum";
import { getTitleAndColor } from "../../../utils/alerts/AlertsUtils";
import { styles } from "./AlertModalStyles";

const AlertModal: React.FC<any> = ({ open, onClose, type }) => {
  // Llamamos a la función getTitleAndColor para obtener el título y el color del título basado en el tipo de alerta.
  const { title, color } = getTitleAndColor(type);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={AlertModalEnum.ALERT_MODAL_TITLE}
      aria-describedby={AlertModalEnum.ALERT_MODAL_DESCRIPTION}
    >
      <Box sx={styles.box}>
        <Box sx={styles.box1}>
          {/* Mostramos el título de la alerta */}
          <Typography
            sx={{ fontWeight: "500", fontSize: "18px", mt: 2, color: color }}
          >
            {title}
          </Typography>
          {/* Mostramos el mensaje dependiendo del tipo de alerta */}
          {type === Status.SUCCESS ? (
            <Typography sx={styles.typography1}>
              {AlertModalEnum.SUCCESS_MESSAGE}
            </Typography>
          ) : (
            <Typography sx={styles.typography2}>
              {AlertModalEnum.ERROR_MESSAGE}
            </Typography>
          )}
          {/* Botón "Volver al inicio" */}
          <Button sx={styles.button} onClick={onClose}>
            <Typography sx={styles.typography3}>
              {AlertModalEnum.BACK_TO_HOME}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AlertModal;
