import React, { useState, useEffect } from "react";
import { FormHelperText, TextField } from "@mui/material";
import { EmailValidationEnum } from "../../enums/components/componentsEnum";
import { EmailValidationProps } from "../../interfaces/components/interfacesEmail";

const EmailValidation: React.FC<EmailValidationProps> = ({
  value,
  onChange,
  showError,
}) => {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const validateEmail = (email: string): string | null => {
      const isValid = /\S+@\S+.\S+/.test(email);
      return !isValid ? EmailValidationEnum.INVALID_MESSAGE : null;
    };

    setError(validateEmail(value));
  }, [value]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value);
  };

  return <>{showError && error && <FormHelperText>{error}</FormHelperText>}</>;
};

export default EmailValidation;
