import { useState, useRef, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import StepperComponent from "../../components/stepper";
import { defaultErrorsData } from "../bautismo/utils";
import TurnoStep from "../../components/stepers/TurnoStep";
import SchedulerUtil from "../../utils/SchedulerUtil";
import { SacramentoEnum } from "../../enums/SacramentoEnum";
import eventosService from "../../services/eventos";
import { validateFirstStep } from "../bautismo/validation";
import { validateSecondStep } from "./validation";
import sacramentosService from "../../services/sacramentos";
import ChildForm from "../../components/forms/childForm";
import AlertModal from "../../components/forms/alertModal";
import ObservableService, {
  ObservableEvents,
} from "../../services/ObservableService";
import { initialFormStateComunion, STEPS_COMUNION } from "./utils/index";
import { stylesComunionPage } from "./comunionPageStyles";
import BaptismForm from "../../components/forms/baptismForm";
import { RolSacramentoEnum } from "../../enums/RolSacramentoEnum";
import { getTimeZone } from "../../utils/datesUtils/dateUtils";

const ComunionPage = () => {
  const [alertType, setAlertType] = useState<"success" | "error" | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>(initialFormStateComunion);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [errors, setErrors] = useState<any>(defaultErrorsData);
  const dateSelected = useRef(null);
  const location = useLocation();
  const eventId = location.state?.eventId;
  const timeZone = getTimeZone();

  useEffect(() => {
    if (eventId) {
      setActiveStep(1);
      ObservableService.notifyListeners(ObservableEvents.SACRAMENTO, eventId);

      eventosService
        .getRolsSacramentosByEventId(eventId)
        .then((response: any) => {
          response.forEach((assitent: any) => {
            if (assitent.rol == RolSacramentoEnum.owner) {
              let mapping = {
                ...formData,
                state: { validate: true },
                child: {
                  ...assitent.person,
                  street: assitent.person?.address?.street,
                  number: assitent.person?.address?.number,
                  document_type: assitent?.person?.document_type,
                  nationality: { ...assitent?.person?.nationality },
                  birthProvince: {
                    ...assitent?.person?.locality?.part?.province,
                  },
                  birthPart: { ...assitent?.person?.locality?.part },
                  birthLocality: { ...assitent?.person?.locality },
                  country: {
                    ...assitent.person.address?.locality?.part?.province
                      ?.country,
                  },
                  part: { ...assitent.person.address?.locality?.part },
                  province: {
                    ...assitent.person.address?.locality?.part?.province,
                  },
                  locality: { ...assitent.person.address?.locality },
                  role: RolSacramentoEnum.owner,
                  checkMother: assitent.person?.checkMother,
                  checkFather: assitent.person?.checkFather,
                },
              };

              assitent.person.sacramentos.forEach((sacramento: any) => {
                if (
                  !!sacramento.sacramentoShadow &&
                  sacramento.sacramentoShadow.type == SacramentoEnum.Bautismo
                ) {
                  mapping = {
                    ...mapping,
                    child: {
                      ...mapping.child,
                      baptism: {
                        ...sacramento.sacramentoShadow,
                        country: {
                          ...sacramento.sacramentoShadow?.iglesia?.locality
                            ?.part?.province?.country,
                        },
                        locality: {
                          ...sacramento.sacramentoShadow?.iglesia?.locality,
                        },
                        part: {
                          ...sacramento.sacramentoShadow?.iglesia?.locality
                            ?.part,
                        },
                        province: {
                          ...sacramento.sacramentoShadow?.iglesia?.locality
                            ?.part?.province,
                        },
                        iglesia: { ...sacramento.sacramentoShadow?.iglesia },
                      },
                    },
                  };
                }
              });

              setFormData(mapping);
            }
          });
        });
    }
  }, [eventId]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepComplete = async () => {
    const noErrors: any = validateStep(activeStep);

    if (noErrors) {
      setErrors(defaultErrorsData);
      if (activeStep === tabsData.length - 1) {
        try {
          await tabsData[activeStep].action!();
          await handleSubmitForm();
        } catch (error) {}
      } else {
        if (!!tabsData[activeStep].action) {
          try {
            await tabsData[activeStep].action!();
            handleNext();
          } catch (error) {}
        }
      }
    }
  };

  const validateStep = (stepIndex: number) => {
    let currentStepErrors: any = {};

    switch (stepIndex) {
      case 0:
        currentStepErrors = validateFirstStep(dateSelected, "la comunion");
        break;
      case 1:
        currentStepErrors = validateSecondStep(formData);
        break;
      default:
        currentStepErrors = {};
    }

    if (currentStepErrors.hasError) {
      setErrors({ ...errors, ...currentStepErrors });
      return false;
    } else {
      return true;
    }
  };

  const validateIndiviualStep = (currentStepErrors: any) => {
    setErrors({ ...errors, ...currentStepErrors });
  };

  const handleFormChange = (key: any, value: string) => {
    if (key.split(".").length > 2) {
      const [parentKey, childKey, subChildKey] = key.split(".") as any;

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [parentKey]: {
          ...prevFormData[parentKey],
          [childKey]: {
            ...prevFormData[parentKey][childKey],
            [subChildKey]: value,
          },
        },
      }));
    } else {
      const [parentKey, childKey] = key.split(".") as any;

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [parentKey]: {
          ...prevFormData[parentKey],
          [childKey]: value,
        },
      }));
    }
  };

  const tabsData: any[] = [
    {
      label: STEPS_COMUNION[0],
      component: (action: any) => (
        <TurnoStep
          type={SacramentoEnum.Comunion}
          formData={formData}
          handleStepComplete={handleStepComplete}
          dateSelected={dateSelected}
          errors={errors}
        />
      ),
      action: () => {
        SchedulerUtil.handleAction(dateSelected, SacramentoEnum.Comunion, timeZone);
      },
    },
    {
      label: STEPS_COMUNION[1],
      component: (action: any) => {
        return (
          <Grid item container gap={15} justifyContent={"center"}>
            <Grid item xs={4}>
              <ChildForm
                title={""}
                formData={formData.child}
                role={RolSacramentoEnum.owner}
                onChange={(key: any, value: any) =>
                  handleFormChange(`child.${key}`, value)
                }
                errors={errors.child}
                setFormData={null as any}
                type={SacramentoEnum.Comunion}
              />
            </Grid>
            <Grid item xs={4}>
              <BaptismForm
                title="Datos del Bautismo"
                formData={formData.child}
                onChange={(key: any, value: any) => {
                  handleFormChange(`child.baptism.${key}`, value);
                }}
                //errors={errors.baptism}
                inGeneratedTurn={true}
              />
            </Grid>
          </Grid>
        );
      },
      action: async () => {
        await sacramentosService.addPeoples([formData?.child] as any);
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "success",
          message: "Se guardaron los datos de la familia correctamente",
        });
      },
    },
  ];

  const handleSubmitForm = async () => {
    try {
      eventosService.sendEmail(sacramentosService.getEventId());

      setAlertType("success");
      setIsModalOpen(true);
    } catch (error) {
      setAlertType("error");
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/panel");
  };

  return (
    <Box sx={stylesComunionPage.boxGeneral}>
      <Box sx={stylesComunionPage.container}>
        <Box sx={stylesComunionPage.innerBox}>
          <Typography sx={stylesComunionPage.typography}>
            Generacion de turno para la Comunion
          </Typography>
        </Box>

        <AlertModal
          onClose={handleCloseModal}
          open={isModalOpen}
          type={alertType}
        />
        <StepperComponent
          stepsData={tabsData}
          showBackButton={activeStep > 0}
          showNextButton={activeStep > 0}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepComplete={handleStepComplete}
        />
      </Box>
    </Box>
  );
};

export default ComunionPage;
