import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class FeriadosService extends GenericService {
    constructor() {
        super();
    }

    async createFeriado(feriadoDto: any): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.post(
                "/feriado/createFeriado",
                feriadoDto,
            );
            return { message: "Feriado creado con éxito", data: response.data };
        } catch (error: any) {
            if (error && error.response && error.response.data && error.response.data.message) {
                console.log("Error al crear el feriado");
            } else {
                console.log("Error inesperado al crear el feriado");
            }
            throw error;
        }
    }

    async getFeriados(page: number, size: number, filter?: string): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.get(
                "/feriado/getFeriados/" + page + "/" + size + "?" + filter,
            );
            return response;
        } catch (error) {
            console.log("Error al obtener los feriados");
        }
    }

    async deleteFeriado(id: any) {
        return await this.delete(`/feriado/${id}`);
    }

    async getAllFeriadosDays (): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.get(
                "/feriado/getAllFeriadosDays",
            );
            return response;
        } catch (error) {
            console.log("Error al obtener los feriados");
        }
    }

    async getFeriadoById (id: any): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.get(
                "/feriado/getFeriadoById/" + id,
            );
            return response;
        } catch (error) {
            console.log("Error al obtener los feriados");
        }
    }

    async updateFeriado(feriadoDto: any, id: number): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.put(
                `/feriado/updateFeriado/${id}`,
                feriadoDto
            );
            return { message: "Feriado actualizado con éxito", data: response.data };
        } catch (error: any) {
            if (error && error.response && error.response.data && error.response.data.message) {
                console.log("Error al actualizar el feriado");
            } else {
                console.log("Error inesperado al actualizar el feriado");
            }
            throw error;
        }
    }

    async aprovedFeriado (id: number): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.put(
                `/feriado/aprovedFeriado/${id}`,
                {}
            );
            return { message: "Feriado aprobado con éxito", data: response.data };
        } catch (error: any) {
            if (error && error.response && error.response.data && error.response.data.message) {
                console.log("Error al aprobar el feriado");
            } else {
                console.log("Error inesperado al aprobar el feriado");
            }
            throw error;
        }
    }

    async getFeriadosForCalendar (): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.get(
                `/feriado/getFeriadosForCalendar`,
            );
            return response;
        } catch (error) {
            console.log("Error al obtener los feriados");
        }
    }
}

const feriadosService = new FeriadosService();

export default feriadosService;
