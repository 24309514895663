import React from "react";
import { Box, IconButton, Typography, Paper } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { stylesAdultForm } from "./AddAdultFormStyles";
import { ADD_PADRINO_MADRINA } from "../../../enums/components/componentsEnum";

const AddAdultForm: React.FC<any> = ({ onAddPadrino }) => {
  return (
    <Paper sx={stylesAdultForm.paper} onClick={() => onAddPadrino()}>
      <Box sx={stylesAdultForm.box}>
        <IconButton
          onClick={() => {}}
          //onAddPadrino}
          sx={stylesAdultForm.iconButton}
        >
          <AddCircleIcon />
        </IconButton>
        <Typography sx={stylesAdultForm.typography}>
          {ADD_PADRINO_MADRINA}
        </Typography>
      </Box>
    </Paper>
  );
};

export default AddAdultForm;
