import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useEffect, useState } from "react";
import { getValidationColor } from "../../../utils/autocomplete/autoCompleteUtils";
import { inputLabelStyles, styles } from "./PadrinosTypeStyles";
import { NEW_PASSWORD, Sizes } from "../../../enums/components/componentsEnum";

const RoleSelector = ({
  id,
  label,
  roles,
  onChange,
  value,
  errors,
  loadState,
  helperText,
  disabled = false,
  size,
}: any) => {
  const [selectedRole, setSelectedRole] = useState({ id: "", name: "" });
  const [isRoleInRoles, setIsRoleInRoles] = useState(false);

  useEffect(() => {
    if (value && value !== selectedRole.id) {
      const foundRole = roles.find((role: any) => role.id === value);
      if (foundRole) {
        setSelectedRole({
          id: foundRole.id,
          name: foundRole.name,
        });
      }

      onChange(id, value);
    }
  }, [value, selectedRole]);

  useEffect(() => {
    const newIsRoleInRoles = roles
      ? roles.some((role: any) => {
          return role?.id === selectedRole.id;
        })
      : false;
    setIsRoleInRoles(newIsRoleInRoles);
  }, [selectedRole, roles]);

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    const newValue =
      roles.find((role: any) => role.name === event.target.value) || null;
    setSelectedRole(newValue);
    onChange(id, newValue);
  };

  return (
    <FormControl
      variant="outlined"
      size={size}
      margin={Sizes.NORMAL}
      fullWidth
      error={errors}
      style={{
        border: getValidationColor(
          errors,
          errors?.[id],
          value,
          loadState?.current,
        ),
        borderRadius: "4px",
      }}
    >
      <InputLabel id={id} sx={inputLabelStyles}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        id={id}
        value={isRoleInRoles ? selectedRole?.name : ""}
        onChange={handleRoleChange}
        label={label}
        disabled={disabled || !roles || roles.length === 0}
      >
        {roles &&
          roles.map((role: any) => (
            <MenuItem key={role.id} value={role.name}>
              {role.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default RoleSelector;
