export const initialFormState: any = {
  state: {
    validate: false,
  },
  child: {
    name: "",
    surname: "",
    document_type: "",
    document: "",
    phone: "",
    email: "",
    role: "Owner",
    nationality: "",
    country: "",
    province: null,
    part: "",
    locality: "",
    street: "",
    birthdate: "",
    birthLocality: "",
    birthProvince: null,
    birthPart: "",
    checkMother: false,
    checkFather: false,
  },
  father: {
    name: "",
    surname: "",
    document_type: "",
    document: "",
    role: "father",
    phone: "",
    email: "",
    nationality: "",
    country: "",
    province: null,
    part: "",
    locality: "",
    street: "",
    number: "",
    birthdate: "",
    birthLocality: "",
    birthProvince: null,
    birthPart: "",
  },
  mother: {
    name: "",
    surname: "",
    document_type: "",
    document: "",
    role: "mother",
    phone: "",
    email: "",
    nationality: "",
    country: "",
    province: null,
    part: "",
    locality: "",
    street: "",
    number: "",
    birthdate: "",
    birthLocality: "",
    birthProvince: null,
    birthPart: "",
  },
  godFather: [
    {
      name: "",
      surname: "",
      document_type: "",
      document: "",
      phone: "",
      role: "godfather",
      email: "",
      nationality: "",
      country: "",
      street: "",
      number: "",
      birthdate: "",
      birthLocality: "",
      birthProvince: null,
      birthPart: "",
      baptism: {
        country: "",
        province: "",
        part: "",
        locality: "",
        church: "",
        location: "",
        diocese: "",
        date: "",
        book: 0,
        folio: 0,
      },
    },
  ],
  godMother: [
    {
      name: "",
      surname: "",
      document: "",
      role: "godfather",
      phone: "",
      email: "",
      nationality: "",
      country: "",
      birthdate: "",
    },
  ],
  baptism: {
    church: "",
    location: "",
    diocese: "",
    date: "",
    book: 0,
    folio: 0,
  },
  civilRegistration: {
    office: "",
    certificate: "",
    page: "",
    section: "",
    book: 0,
    date: "",
  },
  scheduleAppointment: {
    date: "",
    start: "",
    end: "",
  },
};

export const defaultPadrinoData = {
  name: "",
  surname: "",
  document: "",
  document_type: "",
  phone: "",
  email: "",
  role: "",
  nationality: "",
  country: "",
  street: "",
  number: "",
  birthLocality: "",
  birthProvince: null,
  birthPart: "",
  baptism: {
    country: "",
    province: "",
    part: "",
    locality: "",
    church: "",
    location: "",
    diocese: "",
    date: "",
    book: 0,
    folio: 0,
  },
};

export const defaultErrorsData = {
  child: {},
  father: {},
  mother: {},
  godFather: [{}, {}],
  godMother: [{}, {}],
  baptism: {},
  civilRegistration: {},
  scheduleAppointment: {},
};

export const defaultIglesia: any = {
  id_igle: 0,
  denomination: "",
  name: null,
  tipo: "",
  id_arz: "",
  id_decanato: null,
  fecha_ere_can: null,
  domicilio: null,
  loc_id: "",
  id_patrono: [],
  coord: null,
  id_comunidad: null,
  casilla_arz: null,
  depende_parroquia: null,
};

export const initialCalendario: any = {
  id: 0,
  id_igle: 0,
  id_comuni: 0,
  id_lugar: 0,
  celebracion: "",
  acontecimiento: "",
  id_actividad: null,
  fecha: null,
  hora_ini: null,
  hora_fin: null,
  id_celebrante: null,
  guia: null,
  musica: null,
  stream: null,
  co_celebrantes: null,
  idioma: null,
  precepto: null,
  id_rito: null,
  suspendido: null,
  motivo_sus: null,
  horario_crono: null,
  iglesia: defaultIglesia,
};

export const STEPS_CONFIRMACION = [
  "Turnos",
  "Datos de la Familia",
  "Datos de los Padrinos",
  "Datos de la Confirmacion",
];
