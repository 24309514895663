import { theme } from "../../theme";

export const stylesBautismoPage = {
  boxGeneral: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1em",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    padding: "0",
  },
  innerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "50px 0px",
  },
  typography: {
    color: theme.font.primary,
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: "1em",
  },
};
