import React, { useState } from "react";
import { Box, Button, Grid, TableCell } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import turnoExpecificationService from "../../services/turnosExpecification";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { RoutesEnum } from "../../utils/RoutesEnum";
import GenericTable from "../../components/genericTable/GenericTable";
import DeleteIcon from "@mui/icons-material/Delete";
import ObservableService, {
  ObservableEvents,
} from "../../services/ObservableService";
import { formatCriteryFeriados, formatPeriodicity } from "../../utils/pages/TurnoUtils";
import { ResponseData } from "./interfacesTurnos";
import { convertUTCToLocal } from "../../utils/datesUtils/dateUtils";

const eventsColumns = [
  {
    name: "Fecha de inicio / fin",
    accessor: "startTime",
    customReder: (row: any, column: any) => {
      return (
        <TableCell>
          {row.startTime && row.endTimeRepetition ? `${moment(row.startTime).format("DD-MM-YYYY")} / ${moment(row.endTimeRepetition).format("DD-MM-YYYY")}` : ''}
        </TableCell>
      );
    },
  },
  {
    name: "Hora de Inicio / Fin",
    accessor: "startHour",
    customReder: (row: any, column: any) => {
      return (
        <TableCell>
          {row.startHour && row.endHour ? `${convertUTCToLocal(row.startHour)} / ${convertUTCToLocal(row.endHour)}` : ''}
        </TableCell>
      );
    },
  },
  {
    name: "Sacerdote",
    accessor: "sacerdote",
    customReder: (row: any, column: any) => {
      const sacerdote = row.sacerdote;
      return (
        <TableCell>
          {sacerdote ? `${sacerdote.nombre} ${sacerdote.apellido}` : '-'}
        </TableCell>
      );
    },  
  },
  {
    name: "Periodicidad",
    accessor: "periodicity",
    customReder: (row: any, column: any) => {
      return (
        <TableCell>
          {row.periodicity ? formatPeriodicity(row.periodicity) : '-'}
        </TableCell>
      );
    },
  },
  { name: "Semana", accessor: "week",  customReder: (row: any, column: any) => {
    return (
      <TableCell>
         {row.week ? `${row.week}` : '-'}
      </TableCell>
    );
  }, },
  { name: "Día", accessor: "day", customReder: (row: any, column: any) => {
      return (
        <TableCell>
          {row.day ? `${row.day}` : '-'}
        </TableCell>
      );
    }
  },
  {
    name: "Días Habilitados",
    accessor: "enabledDays",
    customReder: (row: any, column: any) => {
      return (
        <TableCell>
          {(row.enabledDays && row.enabledDays.length > 0) ? row.enabledDays?.join(', ') : '-'}
        </TableCell>
      );
    },
  },
  {
    name: "Criterio Feriados",
    accessor: "criteryFeriados",
    customReder: (row: any, column: any) => {
      return (
        <TableCell>
          {row.criteryFeriados ? formatCriteryFeriados(row.criteryFeriados) : '-'}
        </TableCell>
      );
    },
  },
  {
    name: "Sector",
    accessor: "sector",
    customReder: (row: any, column: any) => {
      return (
        <TableCell>
          {row.sector ? row.sector.description : '-'}
        </TableCell>
      );
    },
  },
  {
    name: "Tipo de Turno",
    accessor: "type",
  }
];

const TurnosProgramados = () => {
  const [_forceRefresh, setForceRefresh] = React.useState(new Date());
  const navigate = useNavigate();

  const fetchEvents = async (page: number, pageSize: number) => {
    return await turnoExpecificationService.getAllWithPagination(page, pageSize)
      .then((response: ResponseData) => {

        return {
          data: response?.data,
          total: response.total
        };
      });
  };

  const eventsActions = [
    {
      icon: (row: any) => <EditIcon />,
      onClick: (row: any) => {
        const url = `/panel/turnos/edit/${row.id}`;
        navigate(url, { state: { turnoId: row.id } });
      },
    },
    {
      icon: (row: any) => <DeleteIcon />,
      onClick: (row: any) => {
        const confirmDelete = window.confirm(
          "¿Estás seguro que deseas eliminar esta programación?",
        );
        if (confirmDelete) {
          turnoExpecificationService
            .deleteExpecificacion(row.id)
            .then((response: any) => {
              setForceRefresh(new Date());
              ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
                type: "success",
                message: "Se elimino el turno correctamente",
              });
            })
            .catch((error: any) => {
              ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
                type: "error",
                message: "Ocurrio un error al eliminar el turno",
              });
            });
        }
      },
    },
  ];

  return (
    <GenericTable<any>
      refresh={new Date()}
      columns={eventsColumns}
      actions={eventsActions}
      title="Events"
      //@ts-ignore
      fetchData={fetchEvents}
    />
  );
};

const ListadoDeTurnosProgramados = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          width: "90%",
          padding: "0",
          overflow: "auto",
        }}
      >
        <Grid container style={{ paddingTop: "2%" }}>
          <Grid item xs={9}>
            <Button
              style={{ backgroundColor: "#3A8DBE" }}
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(RoutesEnum.TURNOS_CREATE);
              }}
            >
              Crear Turno
            </Button>
          </Grid>
        </Grid>
        <TurnosProgramados />
      </Box>
    </Box>
  );
};

export default ListadoDeTurnosProgramados;
