import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class SectorService extends GenericService {
  constructor() {
    super();
  }

  async create(data: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.post(
        "/sector/createSector",
        data,
      );
      return response;
    } catch (error) {
      console.log("Error al crear el sector");
    }
  }

  async getSectores(page: number, size: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/sector/getSectores/" + page + "/" + size,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener sectores");
    }
  }

  async findSectorById(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/sector/findSectorById/" + id,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener el sector");
    }
  }

  async editSector(id: number, data: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.put(
        "/sector/editSector/" + id,
        data,
      );
      return response;
    } catch (error) {
      console.log("Error al editar el sector");
    }
  }

  async deleteSector(id: number) {
    return await this.delete(`/sector/${id}`);
  }
}

const sectorService = new SectorService();

export default sectorService;
