import React from "react";
import Home from "../pages/home/HomePage";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { RoutesEnum } from "../utils/RoutesEnum";
import Certifications from "../pages/certifications/CertificationsPage";
import ResetPasswordPage from "../pages/resetPassword/ResetPasswordPage";

/**
 * Public navigator
 * @returns
 */
const PublicNavigator = (): JSX.Element => {
  return (
    <Router>
      <Routes>
        <Route path={RoutesEnum.HOME} element={<Home />} />
        <Route path={RoutesEnum.LOGIN} element={<Home />} />
        <Route path={RoutesEnum.CERTIFICATIONS} element={<Certifications />} />
        <Route
          path={RoutesEnum.RESET_PASSWORD}
          element={<ResetPasswordPage />}
        />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default PublicNavigator;
