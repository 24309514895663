export default class ApiResponse<T> {
  private httpStatusCode: number | null;
  private success: boolean;
  private content: T;
  private errorCode: string;
  private message: string;

  private constructor(
    httpStatusCode: number | null,
    success: boolean,
    content: T,
    errorCode: string,
    message: string,
  ) {
    this.httpStatusCode = httpStatusCode;
    this.success = success;
    this.content = content;
    this.errorCode = errorCode;
    this.message = message;
  }

  public static fromAxiosResponse<T>(response: any) {
    return new ApiResponse<T>(
      response.status,
      response.data.success,
      response.data.content,
      response.data.errorCode || "",
      response.data.message,
    );
  }

  public static fromBlobResponse<T>(response: any) {
    return new ApiResponse<T>(response.status, true, response.data, "", "");
  }

  public getHttpStatusCode() {
    return this.httpStatusCode;
  }

  public isSuccess() {
    return this.success;
  }

  public getContent() {
    return this.content;
  }

  public getErrorCode() {
    return this.errorCode;
  }

  public getMessage() {
    return this.message;
  }

  public hasError() {
    return !this.success;
  }

  public hasContent() {
    return this.content !== null;
  }
}
