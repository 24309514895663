export const styles = {
  boxPrincipal: {
      position: 'relative',
      width: '100%',
      height: '100%',
  },
  box1: {
      position: 'absolute',
      left: '30%',
      top: '175px',
      width: '35%',
      height: '100%',
  },
  box2: {

  },
  paper: {
  },
  box: { 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
  },
  renderContentGrid: { 
  },
  gridItem: { 
  },
  typography: { 
      fontWeight: 500, 
      color: '#31876C', 
  },
  button: { 
      marginTop: '30px' 
  },
  h2: {
      color: "black", 
      textAlign: "center" as const,
  },
  icon: {
  },
  alert: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: '15px',
    },
    errorStyle: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '80%',
          fontSize: '12px',
          color: 'red',
    },
    errorStyleOk: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80%',
      fontSize: '12px',
      color: 'green',
    },

}