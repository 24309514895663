import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import religionCultoService from "../../../services/religionCulto";
import { Grid, Typography } from "@mui/material";
import { getValidationColor } from "../../../utils/autocomplete/autoCompleteUtils";
import { inputLabelStyles, stylesReligion } from "./ReligionSelectorStyles";
import {
  NEW_PASSWORD,
  RELIGION,
  Sizes,
} from "../../../enums/components/componentsEnum";

const ReligionSelector = ({
  id,
  label,
  onChange,
  value,
  errors,
  loadState,
  helperText,
  disabled = false,
  size,
}: any) => {
  const [selectedReligion, setSelectedReligion] = useState(null);
  const [religiones, setReligiones] = useState([]);

  useEffect(() => {
    religionCultoService.getAll().then((response: any) => {
      setReligiones(response);
    });
  }, []);

  useEffect(() => {
    let arquidiocesis: any = religiones.find(
      (arquidiocesis: any) => arquidiocesis.id == value,
    );
    setSelectedReligion(arquidiocesis);

    if (!!arquidiocesis) {
      onChange(id, arquidiocesis);
    }
  }, [religiones, value]);

  return (
    <Grid style={stylesReligion.grid}>
      <Typography sx={stylesReligion.typography}>Religion</Typography>
      <Autocomplete
        id={id}
        options={religiones}
        autoHighlight
        isOptionEqualToValue={(option, value) =>
          value ? option.religion === value.religion : false
        }
        value={selectedReligion ?? null}
        getOptionLabel={(option: any) => option?.religion || ""}
        onChange={(event, newValue) => {
          setSelectedReligion(newValue);
          if (newValue) {
            //@ts-ignore
            onChange(id, newValue);
          } else {
            onChange(id, "");
          }
        }}
        key={value?.id}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={stylesReligion.box}
            {...props}
            key={option.id} // Asegúrate de que 'id' sea único para cada opción
          >
            {
              //@ts-ignore
              option.religion
            }
          </Box>
        )}
        fullWidth
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={RELIGION}
            inputProps={{
              ...params.inputProps,
              autoComplete: NEW_PASSWORD,
            }}
            InputLabelProps={{ sx: inputLabelStyles }}
            disabled={disabled}
            style={{
              border: getValidationColor(
                errors,
                errors?.[id],
                value,
                loadState?.current,
              ),
              borderRadius: "4px",
            }}
            size={size}
            margin={Sizes.NORMAL}
            error={errors}
            helperText={helperText}
          />
        )}
      />
    </Grid>
  );
};

export default ReligionSelector;
