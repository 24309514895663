import React, { useEffect, useState } from 'react';
import { TextField, Button, Paper, Typography, Grid, Box, IconButton, Alert } from '@mui/material';
import moment from 'moment';
import 'moment/locale/es';
import { useNavigate } from 'react-router-dom';
import iglesiasService from '../../services/iglesias';
import authService from '../../services/auth';
import administratorService from '../../services/administrator';
import CancelIcon from '@mui/icons-material/Cancel';
import Avatar from 'react-avatar';
import { errorStyle, errorStyleOk, gridErrorStyle } from './ProfilePageStyles';
import { queryClient } from '../../App';
import { addNotification } from '../../utils/notifications';

moment.locale('es');

const ProfilePage = ({ custom = {}, isAdmin = false, isOwner = false }: any) => {
  const [fullName, setFullName] = useState('');
  const [lastName, setLastName] = useState('');
  const [document, setDocument] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [idUser, setIdUser] = useState(Number);
  const [birthDate, setBirthDate] = useState<string | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({
    email: '',
    nombre: '',
    apellido: '',
    contraseña: '',
    telefono: '',
    fechaNacimiento: '',
    documento: '',
    confirmarContraseña: '',
    iglesias: "",
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [rolesIglesia, setRolesIglesia] = useState<any>([{}]);

  useEffect(() => {
    if (!!custom?.item) {
      administratorService.findById(custom.item.id!).then((response: any) => {
        const {
          nombre,
          apellido,
          documento,
          email,
          telefono,
          fechaNacimiento,
          imageProfile,
          id,
          rolesIglesia,
        } = response;
        setRolesIglesia(rolesIglesia)
        setFullName(nombre);
        setLastName(apellido);
        setDocument(documento);
        setEmail(email);
        setPhone(telefono);
        setBirthDate(fechaNacimiento);
        setIdUser(id);
        iglesiasService.getImage("/auth/get-image/", imageProfile)
          .then((response: any) => {
            try {
              //@ts-ignore
              setImagePreview(URL.createObjectURL(response))
            } catch (error) {
            }
          }).catch((error: any) => {
          })

      });
    } else {
      const {
        nombre,
        apellido,
        documento,
        email,
        telefono,
        fechaNacimiento,
        imageProfile,
        id,
        rolesIglesia
      } = authService.getUserData();

      setRolesIglesia(rolesIglesia)
      setFullName(nombre);
      setLastName(apellido);
      setDocument(documento);
      setEmail(email);
      setPhone(telefono);
      setBirthDate(fechaNacimiento);
      setIdUser(id);

      iglesiasService.getImage("/auth/get-image/", imageProfile)
        .then((response: any) => {
          try {
            //@ts-ignore
            setImagePreview(URL.createObjectURL(response))
          } catch (error) {
          }
        });
    }
  }, []);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const { id } = !!custom?.item ? custom?.item : authService.getUserData();
      const response: any = await iglesiasService.uploadImage('/auth/upload-image/' + id, file); // Asegúrate de reemplazar '/your/upload/url' con la URL correcta de tu backend
      if (!!response) {
        try {
          const imageUrl: any = URL.createObjectURL(file);
          setImageUrl(response);
          setImagePreview(imageUrl);
        } catch (error) {
        }
      }
    }

  };

  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1);
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    //TODO validate data

    const userData = {
      nombre: fullName,
      apellido: lastName,
      documento: document,
      email: email,
      telefono: phone,
      fechaNacimiento: birthDate,
      imageProfile: imageUrl,
    };

    try {
      const response = await administratorService.editUser(idUser, userData)
        .then((result: any) => {
          addNotification(queryClient, 'success', "User " + result.nombre + ' ' + result.apellido + " actualizado correctamente.");
          custom.handleClose();
        }).catch((error: any) => {
          addNotification(queryClient, 'error', "No se pudo actualizar el sector.  Error: " + error);
        })
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: !!custom?.backgroundColor ? custom?.backgroundColor : "#f2f2f2", height: "100%" }}>

      <Box sx={{ width: !!custom.fullScreen ? custom.fullScreen : '50%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end", marginBottom: '15px' }}>
          <IconButton onClick={!!custom?.handleGoBack ? custom.handleGoBack : handleGoBack}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '5%',
            paddingRight: '5%',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        >
          <form onSubmit={handleSubmit} style={{ marginTop: '0px' }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ flex: 1 }}>
                <Typography variant="subtitle1" sx={{ margin: '15px 0', fontWeight: 500, color: '#31876C' }}>
                  Perfil
                </Typography>
              </div>
              <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <Typography variant="subtitle1" sx={{ paddingLeft: '50px', margin: '15px 0', fontWeight: 500, color: '#31876C' }}>
                    Iglesias
                  </Typography>
                  <ul>
                    {
                      rolesIglesia?.map((rol: any) => (
                        <li key={rol?.iglesia?.denomination}>
                          <a href="#" style={{ textDecoration: "none", fontSize: 15, fontWeight: 400, color: 'grey' }} onClick={() => { authService.selectIglesia(rol) }}>
                            {rol?.iglesia?.denomination}
                          </a>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>

            <Grid container spacing={2} sx={{ '& > .MuiGrid-item': { paddingTop: 0 } }}>
              <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "center", paddingBottom: "5%" }}>
                  {!!imagePreview && <Button
                    variant="contained"
                    component="label"
                    style={{
                      borderRadius: "75px",
                      height: "150px",
                      width: "150px",
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundImage: `url(${imagePreview})`
                    }}
                  >
                    <input
                      type="file"
                      hidden
                      disabled={false}
                      onChange={handleFileChange}
                    />
                  </Button>
                  }
                  {!imagePreview &&
                    <label style={{ cursor: 'pointer' }}>
                      <Avatar size='150' round={true} name={fullName + " " + lastName} />
                      <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                      />
                    </label>}
                </div>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="subtitle1" sx={{ margin: '15px 0', fontWeight: 500, color: '#31876C' }}>
                    Datos personales
                  </Typography>
                </Grid>
                <TextField
                  id="full-name"
                  label="Nombre"
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value)
                  }}
                  margin="normal"
                  disabled={false} //TODO logic isAdmin and isOwner
                  size="small"
                  autoComplete="off"
                  fullWidth
                  error={!!errors.nombre}
                  helperText={errors.nombre}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="last-name"
                  label="Apellido"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  margin="normal"
                  disabled={false}
                  size="small"
                  autoComplete="off"
                  fullWidth
                  error={!!errors.apellido}
                  helperText={errors.apellido}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="document"
                  label="Documento"
                  type="number"
                  disabled={true}
                  value={document}
                  onChange={(e) => setDocument(e.target.value)}
                  margin="normal"
                  size="small"
                  autoComplete="off"
                  fullWidth
                  error={!!errors.documento}
                  helperText={errors.documento}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="email"
                  label="Email"
                  type="text"
                  autoComplete="off"
                  value={email}
                  disabled={false}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  size="small"
                  fullWidth
                  error={!!errors.email}
                  helperText={""}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="birth-date"
                  label="Fecha de Nacimiento"
                  type="date"
                  disabled={false}
                  autoComplete="off"
                  value={birthDate}
                  onChange={(e) => setBirthDate(e.target.value)}
                  margin="normal"
                  size="small"
                  fullWidth
                  error={!!errors.fechaNacimiento}
                  helperText={errors.fechaNacimiento}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="phone"
                  label="Teléfono"
                  type="number"
                  autoComplete="off"
                  value={phone}
                  disabled={false}
                  onChange={(e) => setPhone(e.target.value)}
                  margin="normal"
                  size="small"
                  fullWidth
                  error={!!errors.telefono}
                  helperText={errors.telefono}
                />
              </Grid>
              {(isAdmin || isOwner) && <Grid item xs={6}>
                <Button type="button" onClick={() => {
                  administratorService.requestPasswordReset(email)
                    .then((response: any) => {
                      setMessage(response);
                      setShowAlert(true);
                      setTimeout(() => setShowAlert(false), 10000);
                    })
                    .catch((error: any) => {
                      setError('Error al solicitar el cambio de contraseña');
                      setShowAlert(true);
                      setTimeout(() => setShowAlert(false), 10000);
                    });
                }} variant="outlined" sx={{ marginTop: '18px', backgroundColor: "white", color: "grey" }} fullWidth>
                  Cambiar contraseña
                </Button>
              </Grid>
              }
              <Grid style={gridErrorStyle}>
                {showAlert && (error ? <Alert style={errorStyle} severity="error">{error}</Alert> : <Alert style={errorStyleOk} severity="success">{message}</Alert>)}
              </Grid>
            </Grid>

            <Button variant="contained" color="primary" type="submit" sx={{ marginTop: '10px', marginBottom: "3%" }} fullWidth>
              Editar
            </Button>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};

export default ProfilePage;
