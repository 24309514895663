export const initialFormStateComunion: any = {
  child: {
    name: "",
    surname: "",
    document_type: "",
    document: "",
    phone: "",
    email: "",
    nationality: "",
    country: "",
    province: null,
    role: "Owner",
    part: "",
    locality: "",
    street: "",
    number: "",
    birthdate: "",
    birthLocality: "",
    birthProvince: null,
    birthPart: "",
    checkMother: false,
    checkFather: false,
    baptism: {
      country: "",
      province: "",
      part: "",
      locality: "",
      church: "",
      location: "",
      diocese: "",
      date: "",
      book: 0,
      folio: 0,
    },
  },
  scheduleAppointment: {
    date: "",
    start: "",
    end: "",
  },
};

export const STEPS_COMUNION = [
  "Turnos",
  "Datos de la Familia",
  "Datos de la Comunion",
];
