import { useState, useEffect, useRef } from "react";
import { isAuthenticated } from "../utils/auth";

export const useAuthentication = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const isAuthenticated = !!localStorage.getItem("access_token");
    setAuthenticated(isAuthenticated);
  }, []);

  return { authenticated, setAuthenticated };
};
