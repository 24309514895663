export const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "18rem",
  minHeight: "9rem",
  border: "1px",
  borderRadius: "1rem",
  boxShadow: "0px 0px 10px .2px #31876C",
  backgroundColor: "#F8F8F8",
};

export const containerStyle2: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "90vh",
  width: "100%",
};

export const centerElementStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
};

export const labelStyle: React.CSSProperties = {
  width: "80%",
  height: "1rem",
  margin: "1rem 0 .3rem 0",
  fontFamily: "Arial",
  fontWeight: "400",
  fontSize: "12px",
  color: "#000000",
};

export const inputStyle: React.CSSProperties = {
  width: "100%",
  height: "1.4rem",
  marginBottom: ".5rem",
  border: ".5px solid #31876C",
  borderRadius: ".25rem",
};

export const buttonStyle: React.CSSProperties = {
  width: "15rem",
  height: "2rem",
  borderRadius: "2px",
  margin: "1rem 0",
  color: "#FFFFFF",
  backgroundColor: "#2A6A8F",
  fontFamily: "Almarai, sans-serif",
};

export const containerCertificateStyle: React.CSSProperties = {
  height: "25.5rem",
  marginBottom: "1rem",
  backgroundColor: "#F8F8F8",
};

export const containerPopUpStyle: React.CSSProperties = {
  width: "64rem",
  height: "35.25rem",
  alignItems: "center",
};

export const dialogTitleStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  height: "1.4rem",
};

export const dialogContenStyle: React.CSSProperties = {
  maxWidth: "47rem",
  height: "18rem",
  backgroundColor: "#2A6A8F1A",
};

export const errorStyle: React.CSSProperties = {
  display: "flex",
  width: "80%",
  fontSize: "12px",
  color: "red",
};

export const gridItemStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

export const boxStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  overflow: "auto",
};

export const girdContainerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  /* height:'100vh', */
  /* width:'100vw', */
};
