export const stylesPeopleData = {
  main: {
    flexDirection: "column !important",
    backgroundColor: "red",
  },
  buttonNewSearch: {},
  boxPrincipal: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    gap: "3em",
    height: "87%",
  },
  cardGeneral: {
    width: "100%",
  },
  gridGeneralData: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "6em",
    paddingBottom: "2em",
  },
  gridBoxes: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
  containerTitle: {
    padding: "0.5em",
    paddingBottom: "2em",
    alignItems: "center",
    justifyContent: "center",
  },
  gridTitle: {
    alignItems: "center",

    justifyContent: "flex-end",
  },
  gridSecondTitle: {
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: "1.5em",
    minWidth: "100%",
    margin: "1em",
  },

  typographyTitle: {
    fontWeight: "bold",
  },
  containerImg: {
    justifyContent: "flex-start",
  },
  gridContainerTexts: {
    alignItems: "center",
  },
  gridsTextsStatics: {
    alignItems: "center",
    paddingBottom: "0.9em",
  },
  gridsTextsDynamics: {
    paddingBottom: "0.9em",
    paddingLeft: "1em",
  },
  typographyStaticsTexts: {
    fontSize: "1em",
  },

  typographyDynamicTexts: {
    fontSize: "1em",
  },
};
