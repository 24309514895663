export const BautismoStepStyles = {
  grid: {
    marginTop: "2%",
  },
  div: {
    display: "flex",
    flex: 1,
    height: "100%",
    width: "100%",
    margin: "3%",
    marginTop: "7%",
  },
  div2: {
    display: "flex",
    flex: 1,
    width: "100%",
    margin: "2%",
    marginLeft: "6%",
  },
};

export const FamilyStepStyles = {
  grid: {
    display: "flex",
    padding: "20px",
  },
};

export const GodParentStepStyles = {
  grid: {
    display: "flex",
    padding: "20px",
  },
  div: {
    paddingTop: "5%",
  },
};

export const TurnoStepStyles = {
  box: {
    display: "flex",
    padding: "20px",
  },
};
