import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class ReligionCultoService extends GenericService {
  constructor() {
    super();
  }

  async getAll(): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await this.get("/religionCulto");
      //@ts-ignore
      return response;
    } catch (error) {
      console.log("Error al obtener las religiones");
      return [];
    }
  }
}

const religionCultoService = new ReligionCultoService();

export default religionCultoService;
