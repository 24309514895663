import { Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomModal = ({ open, handleClose, children }: any) => {
  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)} // Aquí cambiamos cómo se maneja el cierre
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiPaper-root": {
          outline: "none",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "none",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIcon onClick={() => handleClose(false)} />
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
