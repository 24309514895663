import { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  defaultErrorsData,
  defaultPadrinoData,
  initialFormState,
} from "./utils";
import StepperComponent from "../../components/stepper";
import {
  validateFirstStep,
  validateSecondStep,
  validateThirdStep,
} from "./validation";
import sacramentosService from "../../services/sacramentos";
import SchedulerUtil from "../../utils/SchedulerUtil";
import { SacramentoEnum } from "../../enums/SacramentoEnum";
import AlertModal from "../../components/forms/alertModal";
import TurnoStep from "../../components/stepers/TurnoStep";
import FamilyStep from "../../components/stepers/FamilyStep";
import GodParentsStep from "../../components/stepers/GodParentsStep";
import eventosService from "../../services/eventos";
import ObservableService, {
  ObservableEvents,
} from "../../services/ObservableService";
import { stylesBautismoPage } from "./bautismoPageStyles";
import { RolSacramentoEnum } from "../../enums/RolSacramentoEnum";
import { STEPS } from "../../interfaces/pages/InterfacesPages";
import { getTimeZone } from "../../utils/datesUtils/dateUtils";

const BautismoPage = () => {
  const [alertType, setAlertType] = useState<"success" | "error" | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<any>(initialFormState);
  const activeStepRef = useRef(0);
  const [godFatherForm, setGodFatherForm] = useState<any>([
    { ...defaultPadrinoData },
    { ...defaultPadrinoData },
  ]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [errors, setErrors] = useState<any>(defaultErrorsData);
  const dateSelected = useRef({
    day: null,
    start: null,
    end: null,
    turnoId: null,
    specificationId: null,
    limit: null,
    count: null,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const eventId = location.state?.eventId;
  const timeZone = getTimeZone();

  useEffect(() => {
    ObservableService.addListeners(
      ObservableEvents.UPLOAD_CHILD,
      (data: any) => {
        if (!!data.father) {
          setFormData((prevFormData: any) => ({
            ...prevFormData,
            state: { validate: true },
            father: {
              ...createFormData(data.father, RolSacramentoEnum.father),
            },
          }));
        }
        if (!!data.mother) {
          setFormData((prevFormData: any) => ({
            ...prevFormData,
            state: { validate: true },
            mother: {
              ...createFormData(data.mother, RolSacramentoEnum.mother),
            },
          }));
        }

        validateSecondStep(formData);
      },
    );
  }, []);

  useEffect(() => {
    activeStepRef.current = activeStep;
  }, [activeStep]);

  const createFormData = (assitent: any, role: string) => {
    let formData = {
      ...assitent,
      street: assitent.address?.street,
      number: assitent.address?.number,
      document_type: assitent?.document_type,
      nationality: { ...assitent?.nationality },
      birthProvince: { ...assitent.locality?.part?.province },
      birthPart: { ...assitent.locality?.part },
      birthLocality: { ...assitent?.locality },
      country: { ...assitent.address?.locality?.part?.province?.country },
      locality: { ...assitent.address?.locality },
      part: { ...assitent.address?.locality?.part },
      province: { ...assitent.address?.locality?.part?.province },
      role: role,
    };

    if (role === RolSacramentoEnum.owner) {
      formData = {
        ...formData,
        checkMother: assitent.checkMother,
        checkFather: assitent.checkFather,
      };
    }

    return formData;
  };

  useEffect(() => {
    if (eventId) {
      setActiveStep(1);
      ObservableService.notifyListeners(ObservableEvents.SACRAMENTO, eventId);
      eventosService
        .getRolsSacramentosByEventId(eventId)
        .then((response: any) => {
          response.forEach((assitent: any) => {
            if (assitent.rol === RolSacramentoEnum.father) {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                state: { validate: true },
                father: {
                  ...createFormData(assitent?.person, RolSacramentoEnum.father),
                },
              }));
            }
            if (assitent.rol === RolSacramentoEnum.mother) {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                state: { validate: true },
                mother: {
                  ...createFormData(assitent?.person, RolSacramentoEnum.mother),
                },
              }));
            }

            if (assitent.rol === RolSacramentoEnum.owner) {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                state: { validate: true },
                child: {
                  ...createFormData(assitent?.person, RolSacramentoEnum.owner),
                },
              }));
            }
          });

          let gofathers = response
            .filter(
              (assitent: any) =>
                assitent.rol == "godfather" || assitent.rol == "godmother",
            )
            .map((assitent: any) => {
              let mapping = {
                ...assitent.person,
                street: assitent.person.address?.street,
                number: assitent.person.address?.number,
                document_type: assitent.person?.document_type,
                nationality: { ...assitent.person?.nationality },
                birthProvince: { ...assitent.person?.locality?.part?.province },
                birthPart: { ...assitent.person?.locality?.part },
                birthLocality: { ...assitent.person?.locality },
                country: {
                  ...assitent.person.address?.locality?.part?.province?.country,
                },
                locality: { ...assitent.person.address?.locality },
                part: { ...assitent.person.address?.locality?.part },
                province: {
                  ...assitent.person.address?.locality?.part?.province,
                },
                role: assitent.rol,
              };

              assitent.person.sacramentos.forEach((sacramento: any) => {
                if (
                  !!sacramento.sacramentoShadow &&
                  sacramento.sacramentoShadow.type == SacramentoEnum.Bautismo
                ) {
                  mapping = {
                    ...mapping,
                    baptism: {
                      ...sacramento.sacramentoShadow,
                      country: {
                        ...sacramento.sacramentoShadow?.iglesia?.locality?.part
                          ?.province?.country,
                      },
                      locality: {
                        ...sacramento.sacramentoShadow?.iglesia?.locality,
                      },
                      part: {
                        ...sacramento.sacramentoShadow?.iglesia?.locality?.part,
                      },
                      province: {
                        ...sacramento.sacramentoShadow?.iglesia?.locality?.part
                          ?.province,
                      },
                      iglesia: { ...sacramento.sacramentoShadow?.iglesia },
                    },
                  };
                }
              });
              return mapping;
            });

          setGodFatherForm([...gofathers]);
        });
    }
  }, [eventId]);

  useEffect(() => {
    if (formData?.state?.validate) {
      validateStep(1);
    }
  }, [formData]);

  const handleSubmitForm = async () => {
    try {
      eventosService.sendEmail(sacramentosService.getEventId());
      setAlertType("success");
      setIsModalOpen(true);
    } catch (error) {
      setAlertType("error");
      setIsModalOpen(true);
    }
  };

  const validateIndiviualStep = (currentStepErrors: any) => {
    setErrors({ ...errors, ...currentStepErrors });
  };

  const tabsData: any = [
    {
      label: STEPS[0],
      component: (action: any) => (
        <TurnoStep
          type={SacramentoEnum.Bautismo}
          formData={formData}
          dateSelected={dateSelected}
          handleStepComplete={handleStepComplete}
          errors={errors}
        />
      ),
      action: async () => {
        await SchedulerUtil.handleAction(dateSelected, SacramentoEnum.Bautismo, timeZone);
      },
    },
    {
      label: STEPS[1],
      component: () => (
        <FamilyStep
          title="Bautisado/ada"
          formData={formData}
          handleFormChange={handleFormChange}
          errors={errors}
          validateIndiviualStep={validateIndiviualStep}
          childs={[
            {
              accessor: "child",
              label: "Bautisado/ada",
              role: RolSacramentoEnum.owner,
            },
          ]}
          adults={[
            { accessor: RolSacramentoEnum.father, label: "Padre" },
            { accessor: "mother", label: "Madre" },
          ]}
        />
      ),
      action: async () => {
        await sacramentosService.addPeoples([
          formData?.child,
          formData?.father,
          formData?.mother,
        ] as any);
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "success",
          message: "Se guardaron los datos de la familia correctamente",
        });
      },
    },
    {
      label: STEPS[2],
      component: () => (
        <GodParentsStep
          godFatherForm={godFatherForm}
          handleAddPadrino={handleAddPadrino}
          handleFormChange={handleFormChange}
          handlePadrinoChange={handlePadrinoChange}
          handleRemovePadrino={handleRemovePadrino}
          errors={errors}
          enableBautismoData={true}
          showRole={true}
        />
      ),
      action: async () => {
        await sacramentosService.addPeoples(godFatherForm as any);
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "success",
          message: "Se guardaron los datos de los padrinos correctamente",
        });
      },
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepComplete = async () => {
    const noErrors: any = validateStep(activeStep);

    if (noErrors) {
      setErrors(defaultErrorsData);
      if (activeStep === tabsData.length - 1) {
        try {
          await tabsData[activeStep].action!();
          await handleSubmitForm();
        } catch (error) {}
      } else {
        if (!!tabsData[activeStep].action) {
          try {
            await tabsData[activeStep].action!();
            handleNext();
          } catch (error) {}
        }
      }
    }
  };

  const validateStep = (stepIndex: number) => {
    let currentStepErrors: any = {};

    switch (stepIndex) {
      case 0:
        currentStepErrors = validateFirstStep(dateSelected, "el bautismo");
        break;
      case 1:
        currentStepErrors = validateSecondStep(formData);
        break;
      case 2:
        currentStepErrors = validateThirdStep(godFatherForm);
        break;
      default:
        currentStepErrors = {};
    }

    if (currentStepErrors.hasError) {
      setErrors({ ...errors, ...currentStepErrors });
      return false;
    } else {
      setErrors(defaultErrorsData);
      return true;
    }
  };

  const handleAddPadrino = () => {
    setGodFatherForm([...godFatherForm, { ...defaultPadrinoData }]);
  };

  const handleRemovePadrino = (index: number) => {
    if (godFatherForm.length > 2) {
      setGodFatherForm((prevData: any) => {
        const newData = [...prevData];
        newData.splice(index, 1);
        return newData;
      });
    } else {
    }
  };

  const handlePadrinoChange = (index: number, key: any, value: string) => {
    setGodFatherForm((prevData: any) => {
      const newData = [...prevData];
      let keys = key.split(".");
      if (keys.length > 1) {
        newData[index] = {
          ...newData[index],
          [keys[0]]: {
            ...newData[index][keys[0]],
            [keys[1]]: value,
          },
        };
      } else {
        newData[index] = { ...newData[index], [key]: value };
      }
      return newData;
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/panel");
  };

  const handleFormChange = (key: any, value: string) => {
    const [parentKey, childKey] = key.split(".") as any;

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [parentKey]: {
        ...prevFormData[parentKey],
        [childKey]: value,
      },
    }));
  };

  return (
    <Box sx={stylesBautismoPage.boxGeneral}>
      <Box sx={stylesBautismoPage.container}>
        <Box sx={stylesBautismoPage.innerBox}>
          <Typography sx={stylesBautismoPage.typography}>
            Generacion de turno para Bautismo
          </Typography>
        </Box>
        <AlertModal
          onClose={handleCloseModal}
          open={isModalOpen}
          type={alertType}
        />
        <StepperComponent
          stepsData={tabsData}
          activeStep={activeStep}
          showBackButton={activeStep > 0}
          showNextButton={activeStep > 0}
          setActiveStep={setActiveStep}
          handleStepComplete={handleStepComplete}
        />
      </Box>
    </Box>
  );
};

export default BautismoPage;
