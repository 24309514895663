import { CSSProperties } from "react";
import { getValidationColor } from "../../../utils/autocomplete/autoCompleteUtils";

export const styles = {
  paper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "25px",
    marginTop: "20px",
    width: "100%",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#F8F8F8",
  },
  typography: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "16px",
    fontWeight: 600,
    color: "#333",
    marginBottom: "20px",
  },
  gridItem: {
    "& > .MuiGrid-item": { paddingTop: 0 },
  },
  textField: (errors: any, formData: any, loadState: any) => ({
    border: getValidationColor(
      errors,
      errors?.street,
      formData?.street,
      loadState?.current,
    ),
    opacity: loadState.current == "LOADED" ? 0.8 : 1,
    borderRadius: "5px",
  }),
  formGroup: {
    margin: "15px",
  },
  checkBox: {
    margin: "15px",
  },
};

export const inputLabelStyles: CSSProperties = {
  backgroundColor: "white",
};
