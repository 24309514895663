export const errorStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "15px",
  fontSize: "14px",
  color: "red",
};

export const errorStyleOk: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  fontSize: "14px",
  marginTop: "15px",
  color: "green",
};

export const gridErrorStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "20px",
};
