import React, { CSSProperties, useState } from "react";
import { Alert, Box, Button, Grid } from "@mui/material";
import DialogComponent from "../certifications/dialogComponent";
import * as styles from "../certifications/certificationsStyles";
import {
  RenderImputTexts,
  RenderLabelTexts,
  RenderButtonTextsPeople,
  CertificationType,
  CertificationValidationMessages,
  DNI_MAX_LENGTH,
  DNI_MIN_LENGTH,
  DniValidationRegex,
} from "../../utils/cerficates/bautismoEnum";
import DocumentTypeComponent from "../../components/autocomplete/documentType/DocumentTypeComponent";
import { useNavigate } from "react-router-dom";
import peopleServie from "../../services/people";

const SearchPeople = (props: any = {}) => {
  const [dni, setDni] = useState("");
  const [documentType, setDocumentType] = useState("DNI");
  const [errorDni, setErrorDni] = useState<{
    isValid: boolean;
    message: string[];
  }>({ isValid: true, message: [] });
  const {
    containerStyle,
    containerStyle2,
    labelStyle,
    inputStyle,
    buttonStyle,
    dialogContenStyle,
    dialogTitleStyle,
    containerPopUpStyle,
    containerCertificateStyle,
    errorStyle,
    gridItemStyle,
    boxStyle,
  } = styles;
  const [dialogState, setDialogState] = React.useState({
    state: false,
    pdfUrl: "",
  });
  const [error, setError] = useState(String);
  const navigate = useNavigate();

  const handleToggleState = async () => {
    if (!dni || !documentType) {
      setError("Por favor, ingrese tanto el DNI como el tipo de documento");
      return;
    }

    const people = await peopleServie.findPeople(dni, documentType);

    if (people) {
      //@ts-ignore
      navigate(`/panel/personas/${people.id}`, { state: { people } });
    } else {
      setError("No existe una persona con este documento");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setDni(inputValue);
    /* setErrorDni(validateDniUtils(inputValue)); */
  };

  const renderLabel = (): JSX.Element => {
    return (
      <Grid item style={labelStyle}>
        <label htmlFor="#">{RenderLabelTexts.NRO_DOC}</label>
      </Grid>
    );
  };

  const renderInput = (): JSX.Element => {
    return (
      <Grid container justifyContent={"center"}>
        <Grid item xs={10}>
          <DocumentTypeComponent
            id="document_type"
            label="tipo"
            countries={[
              { id: "DNI", name: "DNI" },
              { id: "PASAPORTE", name: "PASAPORTE" },
              { id: "CUIL", name: "CUIL" },
              { id: "CUIT", name: "CUIT" },
              { id: "CDI", name: "CDI" },
              { id: "TI", name: "TI" },
              { id: "LC", name: "LC" },
              { id: "LE", name: "LE" },
              { id: "CI", name: "CI" },
            ]}
            value={documentType}
            onChange={(e: any, value: any) => {
              setDocumentType(value.id);
            }}
            size="small"
            errors={false}
            helperText={""}
          />
        </Grid>
        <Grid item xs={10}>
          <input
            type={RenderImputTexts.TEXT}
            placeholder={RenderImputTexts.PLACEHOLDER}
            style={inputStyle}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    );
  };

  const renderErrors = () => {
    if (!errorDni.isValid) {
      return (
        <Grid item style={errorStyle}>
          {errorDni.message.map((errorMessage, index) => (
            <React.Fragment key={index}>
              {errorMessage}
              <br />
            </React.Fragment>
          ))}
        </Grid>
      );
    }
    if (error) {
      return (
        <Grid item style={errorStyle}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      );
    }
  };

  const renderButton = (): JSX.Element => {
    return (
      <Grid item style={gridItemStyle}>
        <Button style={buttonStyle} onClick={handleToggleState}>
          {RenderButtonTextsPeople.SEE_PERSON}
        </Button>
      </Grid>
    );
  };

  const renderDialogComponent = () => {
    return (
      <DialogComponent
        open={dialogState.state}
        onClose={() =>
          setDialogState((prevState) => ({ ...prevState, state: false }))
        }
        pdfUrl={dialogState.pdfUrl}
        type={props.type || CertificationType.ALL}
        handleOpenDialog={() => {}}
      />
    );
  };

  return (
    <Box>
      <Grid container xs={12} style={containerStyle2}>
        <Grid item style={containerStyle}>
          {renderLabel()}
          {renderInput()}
          {renderErrors()}
          {renderButton()}
          {renderDialogComponent()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchPeople;
