import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { stylesPeopleData } from "./PeopleDataStyles";
import {
  NewSearchEnum,
  StaticTexts,
  calcularEdad,
  hasSacramento,
} from "../../utils/peoples/peopleDataEnum";
import { RoutesEnum } from "../../utils/RoutesEnum";

const PeopleData = () => {
  const location = useLocation();
  const data = location.state.people;
  const navigate = useNavigate();

  const isBaptized = hasSacramento(data, "Bautismo");
  const isConfirm = hasSacramento(data, "Confirmacion");
  const isMarried = hasSacramento(data, "Matrimonio");
  const isComulged = hasSacramento(data, "Comunion");

  return (
    <Box
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        display: "flex",
        justifyContent: "center,",
        alignItems: "center",
        height: "90vh",
        width: "100%",
        paddingLeft: "4em",
      }}
    >
      <Box sx={stylesPeopleData.boxPrincipal}>
        <Box sx={stylesPeopleData.buttonNewSearch}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(RoutesEnum.PERSONAS);
            }}
          >
            {NewSearchEnum.NUEVA_BUSQUEDA}
          </Button>
        </Box>
        <Card sx={stylesPeopleData.cardGeneral}>
          <CardContent>
            <Grid container sx={stylesPeopleData.containerTitle}>
              <Grid item xs={6} container sx={stylesPeopleData.gridTitle}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={stylesPeopleData.typographyTitle}
                >
                  {StaticTexts.FICHA_PERSONAL}
                </Typography>
              </Grid>
              <Grid item xs={6} container sx={stylesPeopleData.containerImg}>
                <img
                  src={StaticTexts.URL_IMG}
                  alt="Imagen"
                  width="80"
                  height="80"
                />
              </Grid>
            </Grid>
            <Grid container sx={stylesPeopleData.gridGeneralData}>
              <Grid item xs={4} sx={stylesPeopleData.gridBoxes}>
                <Grid
                  item
                  xs={7}
                  container
                  sx={stylesPeopleData.gridSecondTitle}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    sx={stylesPeopleData.typographyTitle}
                  >
                    {StaticTexts.DATOS_PERSONALES}
                  </Typography>
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.name !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.NAME}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          {data.name}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.surname !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.SURNAME}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          {data.surname}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.document_type !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.DNI_TYPE}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          {data.document_type}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.document !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.DNI}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          {data.document}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.email !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.EMAIL}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          {data.email}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.phone !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.PHONE}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          {data.phone}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.birthdate !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.BIRTHDATE}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          {data.birthdate}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.birthdate !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.EDAD}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          {calcularEdad(data.birthdate)}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.nationality?.name !== ("" || null) ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.NACIONALITY}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          {data.nationality?.name}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.father?.name !== "" &&
                  data.father?.name !== undefined ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.FATHER_NAME}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          <Link
                            to={`/panel/personas/${data.id}/padre/${data.father?.id}`}
                          >
                            {`${data.father?.name} ${data.father?.surname}`}
                          </Link>
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  {data.mother?.name !== "" &&
                  data.mother?.name !== undefined ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesPeopleData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyStaticsTexts}
                        >
                          {StaticTexts.MOTHER_NAME}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesPeopleData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesPeopleData.typographyDynamicTexts}
                        >
                          <Link
                            to={`/panel/personas/${data.id}/padre/${data.mother?.id}`}
                          >
                            {`${data.mother?.name} ${data.mother?.surname}`}
                          </Link>
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={4} sx={stylesPeopleData.gridBoxes}>
                <Grid
                  item
                  xs={7}
                  container
                  sx={stylesPeopleData.gridSecondTitle}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    sx={stylesPeopleData.typographyTitle}
                  >
                    {StaticTexts.DATOS_RESIDENCIALES}
                  </Typography>
                </Grid>
                {data.address && (
                  <>
                    <Grid container sx={stylesPeopleData.gridContainerTexts}>
                      {data.address.locality.part.province.country.name !==
                      ("" || null) ? (
                        <>
                          <Grid
                            item
                            xs={6}
                            container
                            sx={stylesPeopleData.gridsTextsStatics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyStaticsTexts}
                            >
                              {StaticTexts.ADDRESS_COUNTRY}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={stylesPeopleData.gridsTextsDynamics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyDynamicTexts}
                            >
                              {data.address.locality.part.province.country.name}
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                    <Grid container sx={stylesPeopleData.gridContainerTexts}>
                      {data.address.locality.part.province.name !== "" ? (
                        <>
                          <Grid
                            item
                            xs={6}
                            container
                            sx={stylesPeopleData.gridsTextsStatics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyStaticsTexts}
                            >
                              {StaticTexts.ADDRES_PROVINCE}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={stylesPeopleData.gridsTextsDynamics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyDynamicTexts}
                            >
                              {data.address.locality.part.province.name}
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                    <Grid container sx={stylesPeopleData.gridContainerTexts}>
                      {data.address.locality.part.name !== "" ? (
                        <>
                          <Grid
                            item
                            xs={6}
                            container
                            sx={stylesPeopleData.gridsTextsStatics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyStaticsTexts}
                            >
                              {StaticTexts.ADDRESS_PART}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={stylesPeopleData.gridsTextsDynamics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyDynamicTexts}
                            >
                              {data.address.locality.part.name}
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                    <Grid container sx={stylesPeopleData.gridContainerTexts}>
                      {data.address.locality.name !== "" ? (
                        <>
                          <Grid
                            item
                            xs={6}
                            container
                            sx={stylesPeopleData.gridsTextsStatics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyStaticsTexts}
                            >
                              {StaticTexts.ADDRESS_LOCALITY}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={stylesPeopleData.gridsTextsDynamics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyDynamicTexts}
                            >
                              {data.address.locality.name}
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                    <Grid container sx={stylesPeopleData.gridContainerTexts}>
                      {data.address.street !== "" ? (
                        <>
                          <Grid
                            item
                            xs={6}
                            container
                            sx={stylesPeopleData.gridsTextsStatics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyStaticsTexts}
                            >
                              {StaticTexts.ADDRESS_STREET}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={stylesPeopleData.gridsTextsDynamics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyDynamicTexts}
                            >
                              {data.address.street}
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                    <Grid container sx={stylesPeopleData.gridContainerTexts}>
                      {data.address.number !== "" ? (
                        <>
                          <Grid
                            item
                            xs={6}
                            container
                            sx={stylesPeopleData.gridsTextsStatics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyStaticsTexts}
                            >
                              {StaticTexts.ADDRESS_NUMBER}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={stylesPeopleData.gridsTextsDynamics}
                          >
                            <Typography
                              variant="h5"
                              component="div"
                              sx={stylesPeopleData.typographyDynamicTexts}
                            >
                              {data.address.number}
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={4} sx={stylesPeopleData.gridBoxes}>
                <Grid
                  item
                  xs={7}
                  container
                  sx={stylesPeopleData.gridSecondTitle}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    sx={stylesPeopleData.typographyTitle}
                  >
                    {StaticTexts.HISTORIA_ECLESIASTICA}
                  </Typography>
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  <Grid
                    item
                    xs={6}
                    container
                    sx={stylesPeopleData.gridsTextsStatics}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={stylesPeopleData.typographyStaticsTexts}
                    >
                      {StaticTexts.BAUTISMO}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={stylesPeopleData.gridsTextsDynamics}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={stylesPeopleData.typographyDynamicTexts}
                    >
                      {isBaptized ? "Si" : "No"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  <Grid
                    item
                    xs={6}
                    container
                    sx={stylesPeopleData.gridsTextsStatics}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={stylesPeopleData.typographyStaticsTexts}
                    >
                      {StaticTexts.CONFIRMACION}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={stylesPeopleData.gridsTextsDynamics}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={stylesPeopleData.typographyDynamicTexts}
                    >
                      {isConfirm ? "Si" : "No"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  <Grid
                    item
                    xs={6}
                    container
                    sx={stylesPeopleData.gridsTextsStatics}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={stylesPeopleData.typographyStaticsTexts}
                    >
                      {StaticTexts.COMUNION}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={stylesPeopleData.gridsTextsDynamics}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={stylesPeopleData.typographyDynamicTexts}
                    >
                      {isComulged ? "Si" : "No"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={stylesPeopleData.gridContainerTexts}>
                  <Grid
                    item
                    xs={6}
                    container
                    sx={stylesPeopleData.gridsTextsStatics}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={stylesPeopleData.typographyStaticsTexts}
                    >
                      {StaticTexts.CASAMIENTO}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={stylesPeopleData.gridsTextsDynamics}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={stylesPeopleData.typographyDynamicTexts}
                    >
                      {isMarried ? "Si" : "No"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default PeopleData;
