import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Logo from "../../../assets/logo/default_02.svg";
import { Link, useNavigate } from "react-router-dom";
import { isSuperAdmin, logout } from "../../../utils/auth";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { MenuItemType } from "../../../interfaces/interfacesGeneral/interfacesPanel";
import { PUSH_SUPERADMIN, getMenuItems } from "../../../utils/panel/PanelUtils";
import { stylesPanel } from "./PanelStyles";

const Navbar = () => {
  const navigate = useNavigate();
  const { authenticated, setAuthenticated } = useAuthentication();
  const [activeSubMenu, setActiveSubMenu] = useState("");
  const [activeSecundaryMenu, setActiveSecundaryMenu] = useState<string>();

  const handleCloseSession = () => {
    logout();
    setAuthenticated(false);
    navigate("/");
    window.location.reload();
  };

  const [menuItems, setMenuItems] = useState<MenuItemType[]>(
    getMenuItems(handleCloseSession),
  );

  useEffect(() => {
    let menu = [...menuItems];

    if (isSuperAdmin()) {
      menu.push(PUSH_SUPERADMIN);
    }
    setMenuItems(menu);
  }, []);

  const handleSubMenuOpen = (menu: string) => {
    setActiveSubMenu(menu);
    setActiveSecundaryMenu("");
  };

  const handleSubMenuClose = () => {
    setActiveSubMenu("");
    setActiveSecundaryMenu("");
  };

  const renderSubMenu = (items: MenuItemType[]) => {
    return items.map((item: MenuItemType, index) => {
      if (!!item.options) {
        return (
          <div key={index} style={stylesPanel.div}>
            {!!item.link && (
              <Link
                key={"submenu-" + item?.name}
                to={item.link}
                className="submenu-link"
                onClick={() => {
                  if (!!item.action) {
                    item.action();
                  }
                  setActiveSubMenu("");
                  setActiveSecundaryMenu("");
                }}
              >
                {item.name}
              </Link>
            )}
            {!item.link && (
              <Box
                className="submenu-link"
                onClick={() => {
                  if (activeSecundaryMenu === index.toString()) {
                    setActiveSecundaryMenu("");
                  } else {
                    setActiveSecundaryMenu(index.toString());
                  }
                }}
              >
                <p style={stylesPanel.linkStyle}>{item.name}</p>
              </Box>
            )}

            {activeSecundaryMenu === index.toString() && (
              <Box
                key={"submenu-" + item?.name}
                sx={stylesPanel.box}
                className="submenu"
              >
                {renderSubMenu(item.options || [])}
              </Box>
            )}
          </div>
        );
      }

      return (
        !!item.link && (
          <Link
            key={"submenu-" + item?.name}
            to={item.link}
            style={stylesPanel.linkStyle}
            className="submenu-link"
            onClick={() => {
              if (!!item.action) {
                item.action();
              }
              setActiveSubMenu("");
              setActiveSecundaryMenu("");
            }}
          >
            {item.name}
          </Link>
        )
      );
    });
  };

  const renderMenu = () => {
    return menuItems.map((menuItem: MenuItemType, index) => (
      <Box
        key={index}
        sx={stylesPanel.box1}
        onMouseEnter={() => handleSubMenuOpen(index.toString())}
        onMouseLeave={handleSubMenuClose}
      >
        {!!menuItem.link && (
          <Link
            to={menuItem.link}
            style={stylesPanel.linkStyle}
            className="menu-link"
          >
            {menuItem.name}
          </Link>
        )}
        {activeSubMenu === index.toString() && (
          <Box sx={stylesPanel.box2} className="submenu">
            {renderSubMenu(menuItem.options || [])}
          </Box>
        )}
      </Box>
    ));
  };

  return (
    <Box sx={stylesPanel.box3}>
      <Box flexDirection={"row"} display={"flex"} alignItems={"flex-end"}>
        <Link to="/panel" style={{ zIndex: 10 }}>
          <img src={Logo} alt="logo" />
        </Link>
      </Box>
      <Box>{renderMenu()}</Box>
      <style>
        {`
            
            .submenu {
                background-color: #3A8DBE !important;
            }

            .submenu-link:hover {
                background-color: #2C6B9C;
                color: gray;
                border-radius: 5px;
          }
        `}
      </style>
    </Box>
  );
};

export default Navbar;
