import { theme } from "../../../theme";

export const stylesNavbar = {
  buttonStyle: {
    backgroundColor: theme.button.primary,
    color: theme.button.text,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.button.hover,
    },
    padding: "12px 30px",
  },
  box: {
    display: "flex",
    padding: "15px 5% 15px 5%",
    maxWidth: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    zIndex: 100,
    background: "none",
    filter: "drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2))",
    marginBotton: "50px",
  },
};
