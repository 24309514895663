import {
  TextField,
  Paper,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { theme } from "../../../theme";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import peopleServie from "../../../services/people";
import LocationForm from "../genericLocationForm/LocationForm";
import { LoadState } from "../../../enums/LoadStateEnum";
import { assignSacramentoToFormData } from "../../../utils/forms/formsUtils";
import {
  SacramentoData,
  SacramentoDatos,
  Sacramentos,
  Sizes,
  TEXT,
} from "../../../enums/components/componentsEnum";
import {
  inputLabelStyles,
  stylesBaptism,
  stylesBaptmisForm,
} from "./BaptismFormStyles";
import { getValidationColor } from "../../../utils/autocomplete/autoCompleteUtils";
import React from "react";

export interface Arquidiocesis {
  id: number;
  denomination: string;
  type: string;
}

const BaptismForm = ({
  title,
  formData,
  onChange,
  errors,
  inGeneratedTurn = true,
  customStyle,
}: any) => {
  const loadState = useRef(LoadState.DEFAULT);
  const location = useLocation();
  const eventId = location.state?.eventId;
  const [isFetch, setIsFetch] = React.useState(false);

  useEffect(() => {
    if (eventId) {
      loadState.current = LoadState.EDITING;
    }
    if (!!formData?.document && !!formData?.documentType) {
      peopleServie
        .findBautismoByDocumentAndType(
          formData?.document,
          formData?.documentType,
        )
        .then((response: any) => {});
    }
  }, []);

  const resetStateValidator = (
    state: LoadState = LoadState.DEFAULT,
    isFetch = false,
  ) => {
    setIsFetch(isFetch);
    loadState.current = state;
    setForceRender(new Date());
  };

  useEffect(() => {
    if (!!formData?.document && !!formData?.document_type) {
      peopleServie
        .findBautismoByDocumentAndType(
          formData?.document,
          formData?.document_type,
        )
        .then((sacramento: any) => {
          if (sacramento && sacramento.type == Sacramentos.BAUTISMO) {
            assignSacramentoToFormData(formData, sacramento);
            resetStateValidator(LoadState.LOADED, true);
          }
        });
    }
  }, [formData?.document, formData?.documentType, formData?.baptism]);

  const handleFormChange = (key: any, value: string) => {
    onChange(key, value);
  };

  const renderContent = () => {
    return (
      <>
        <Typography sx={stylesBaptmisForm(customStyle, theme).typography}>
          {title ?? ""}
        </Typography>

        <Grid container spacing={2} sx={stylesBaptism.grid}>
          <LocationForm
            formData={formData}
            onChange={handleFormChange}
            errors={errors}
            nacimiento={false}
            baptismForm={true}
            disabled={false}
            isFetch={isFetch}
            loadState={loadState}
          />

          <Grid item xs={inGeneratedTurn ? 6 : 12}>
            <TextField
              id={SacramentoData.BOOK}
              label={SacramentoDatos.LIBRO}
              value={formData?.baptism?.book}
              onChange={(e) =>
                handleFormChange(SacramentoData.BOOK, e.target.value)
              }
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              error={!!errors?.book}
              helperText={errors?.book}
              InputLabelProps={{ sx: inputLabelStyles, shrink: true }}
              disabled={false}
              style={{
                border: getValidationColor(
                  errors,
                  errors?.book,
                  formData?.baptism?.book,
                  loadState?.current,
                ),
              }}
            />
          </Grid>
          <Grid item xs={inGeneratedTurn ? 6 : 12}>
            <TextField
              id={SacramentoData.FOLIO}
              label={SacramentoDatos.FOLIO}
              value={formData?.baptism?.folio}
              InputLabelProps={{ sx: inputLabelStyles, shrink: true }}
              onChange={(e) =>
                handleFormChange(SacramentoData.FOLIO, e.target.value)
              }
              disabled={false}
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              type={TEXT}
              error={!!errors?.folio}
              helperText={errors?.folio}
              style={{
                border: getValidationColor(
                  errors,
                  errors?.folio,
                  formData?.baptism?.folio,
                  loadState?.current,
                ),
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Box>
      {!!customStyle && renderContent()}
      {!customStyle && (
        <Paper sx={stylesBaptism.paper}>{renderContent()}</Paper>
      )}
    </Box>
  );
};

export default BaptismForm;
function setForceRender(arg0: Date) {
  throw new Error("Function not implemented.");
}
