import React from "react";
import { Box, TableCell } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../../components/genericTable/GenericTable";
import turnoService from "../../../services/turnos";
import DeleteIcon from "@mui/icons-material/Delete";
import ObservableService, {
  ObservableEvents,
} from "../../../services/ObservableService";
import { convertUTCToLocal } from "../../../utils/datesUtils/dateUtils";

const ListadoDeTurnosPendientes = () => {
  const navigate = useNavigate();
  const [forceRefresh, setForceRefresh] = React.useState(new Date());
  const [totalTurnos, setTotalTurnos] = React.useState(0);

  const turnosColumns = [
    //{ name: "id", accessor: "id" },
    {
      name: "Dia",
      accessor: "dia",
      customReder: (row: any, column: any) => {
        const date = new Date(row.startTime);
        const day = date.getDate();
        const month = date.toLocaleString("es-ES", { month: "short" });
        return (
          <TableCell
            style={{ color: "#3A8DBE", fontSize: "20px", fontWeight: 600 }}
          >{`${day} ${month}`}</TableCell>
        );
      },
    },
    {
      name: "Hora inicio",
      accessor: "startTime",
      customReder: (row: any, column: any) => {
        let startHour = convertUTCToLocal(row.startTime);
        return (
          <TableCell>
            {startHour}
          </TableCell>
        );
      },
    },
    {
      name: "Hora de fin",
      accessor: "endTime",
      customReder: (row: any, column: any) => {
        let endHour = convertUTCToLocal(row.endTime);
        return (
          <TableCell>
            {endHour}
          </TableCell>
        );
      },
    },
    {
      name: "Pendientes",
      accessor: "pendingCount",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            <button
              onClick={() =>
                navigate(`/panel/event-list/${row.id}?status=pending`, {
                  state: { turnoId: row.id },
                })
              }
              style={{ color: "#FFC107", fontSize: "1.2em" }}
            >{`${row.pendingCount}`}</button>
          </TableCell>
        );
      },
    },
    {
      name: "Aprobados",
      accessor: "approvedCount",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            <button
              onClick={() =>
                navigate(`/panel/event-list/${row.id}?status=approved`, {
                state: { turnoId: row.id },
              })
              }
              style={{ color: "#4CAF50", fontSize: "1.2em" }}
            >{`${row.approvedCount}`}</button>
          </TableCell>
        );
      },
    },
    {
      name: "Completados",
      accessor: "completedCount",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            <button
              onClick={() =>
                navigate(`/panel/event-list/${row.id}?status=completed`, {
                  state: { turnoId: row.id },
                })
              }
              style={{ color: "#3F51B5", fontSize: "1.2em" }}
            >{`${row.completedCount}`}</button>
          </TableCell>
        );
      },
    },
    {
      name: "Cupos",
      accessor: "slots",
      customReder: (row: any, column: any) => {
        return <TableCell>{`${row.count} / ${row.slots}`}</TableCell>;
      },
    },
    { name: "Tipo", accessor: "type" },
  ];

  /**
   * Actions for turnos
   */
  const turnosActions = [
    {
      icon: (row: any) => <VisibilityIcon />,
      onClick: (row: any) => {
        navigate(`/panel/event-list/${row.id}`, {
          state: { turnoId: row.id },
        });
      },
    },
    {
      icon: () => <DeleteIcon />,
      onClick: (row: any) => {
        const confirmDelete = window.confirm(
          "¿Estás seguro que deseas eliminar este turno?",
        );
        if (confirmDelete) {
          turnoService
            .deleteTurno(row.id)
            .then((response: any) => {
              setForceRefresh(new Date());
              ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
                type: "success",
                message: "Se elimino el turno correctamente",
              });
            })
            .catch((error: any) => {});
        }
      },
    },
  ];

  const fetchTurnos = async (
    page: number,
    pageSize: number,
    filter?: string,
  ) => {
    const response = await turnoService.getTurnosFilters(
      page,
      pageSize,
      filter,
    );
    const result = response?.turnos;
    const total = response?.total;
    setTotalTurnos(total);

    return result;
  };
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          width: "90%",
          padding: "0",
          overflow: "auto",
        }}
      >
        <div
          style={{
            paddingBottom: "25px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            alignContent: "flex-end",
          }}
        ></div>
        <GenericTable<any>
          filtersData={[{ param: "status", value: "pending" }]}
          refresh={forceRefresh}
          columns={turnosColumns}
          actions={turnosActions}
          title="turnos"
          fetchData={fetchTurnos}
          totalTurnos={totalTurnos}
          filters={() => {
            return <></>;
          }}
        />
      </Box>
    </Box>
  );
};

export default ListadoDeTurnosPendientes;
