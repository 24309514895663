import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import LocationForm from "./LocationForm";
import { inputLabelStyles, styles } from "./GenericLocationBoxStyles";
import { shouldRenderChildForm } from "../../../utils/forms/formsUtils";
import {
  Checks,
  DataPersonal,
  DatosPersonales,
  NUMERO_CAPITALIZED_OFF,
  Roles,
  Sizes,
  TEXT,
} from "../../../enums/components/componentsEnum";

const genericLocationBox = ({
  formData,
  onChange,
  errors,
  getValidationColor,
  childForm = false,
  type,
  checkFather,
  checkMother,
  isFetch = false,
  loadState,
}: any) => {
  return (
    <Box>
      <Paper sx={styles.paper}>
        <Typography sx={styles.typography}>
          {DatosPersonales.DIRECCION}
        </Typography>
        <Grid container spacing={2} sx={styles.gridItem}>
          <LocationForm
            formData={formData}
            onChange={onChange}
            errors={errors}
            nacimiento={false}
            disabled={false}
            isFetch={isFetch}
            loadState={loadState}
          />
          <Grid item xs={6}>
            <TextField
              id={DataPersonal.STREET}
              label={DatosPersonales.CALLE}
              InputLabelProps={{ sx: inputLabelStyles }}
              type={TEXT}
              disabled={false}
              sx={styles.textField(errors, formData, loadState)}
              value={formData.street ?? ""}
              onChange={(e) => onChange(DataPersonal.STREET, e.target.value)}
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              error={!!errors.street}
              helperText={errors.street}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={NUMERO_CAPITALIZED_OFF}
              label={DatosPersonales.NUMERO}
              InputLabelProps={{ sx: inputLabelStyles }}
              type={TEXT}
              disabled={false}
              sx={styles.textField(errors, formData, loadState)}
              value={formData.number ?? ""}
              onChange={(e) => onChange(DataPersonal.NUMBER, e.target.value)}
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              error={!!errors.number}
              helperText={errors.number}
            />
          </Grid>
          {shouldRenderChildForm(childForm, type, () => (
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        onChange(Checks.CHECK_FATHER, e.target.checked);
                      }}
                      checked={formData.checkFather ?? false}
                      value={formData.checkFather ?? false}
                      disabled={checkMother}
                    />
                  }
                  sx={styles.formGroup}
                  label={Roles.PADRE}
                />
              </FormGroup>
            </Grid>
          ))}
          {shouldRenderChildForm(childForm, type, () => (
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        onChange(Checks.CHECK_MOTHER, e.target.checked);
                      }}
                      checked={formData.checkMother ?? false}
                      disabled={checkFather}
                      value={formData.checkMother ?? false}
                    />
                  }
                  style={styles.checkBox}
                  label={Roles.MADRE}
                />
              </FormGroup>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default genericLocationBox;
