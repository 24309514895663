import React from "react";
import { LoginRegex, MAX_PASS_LENGTH } from "../../utils/login/loginEnum";
import { defaultErrorMessages } from "../../enums/components/componentsEnum";
import { PasswordValidatorProps } from "../../interfaces/interfacesGeneral/interfacesPassword";

export const validatePassword = (
  password: string,
  errorMessages?: Record<string, string>,
): string[] => {
  const messages = errorMessages || defaultErrorMessages;
  const errors: string[] = [];

  if (password.length < MAX_PASS_LENGTH) {
    errors.push(defaultErrorMessages.minLength);
  }

  if (!LoginRegex.MIN_LOWERCASE.test(password)) {
    errors.push(defaultErrorMessages.minLowercase);
  }

  if (!LoginRegex.MIN_UPPERCASE.test(password)) {
    errors.push(defaultErrorMessages.minUppercase);
  }

  if (!LoginRegex.MIN_NUMBERS.test(password)) {
    errors.push(defaultErrorMessages.minNumbers);
  }

  return errors;
};

const PasswordValidator: React.FC<PasswordValidatorProps> = ({ password }) => {
  const passwordValidation = validatePassword(password);

  return (
    <>
      {passwordValidation.length > 0 &&
        passwordValidation.map((errorMessage: any, index: any) => (
          <React.Fragment key={index}>
            {errorMessage}
            <br />
          </React.Fragment>
        ))}
    </>
  );
};

export default PasswordValidator;
