export enum ValidationMessagesEnum {
  MIN_LENGTH = "La contraseña debe tener al menos 8 caracteres",
  MIN_LOWERCASE = "La contraseña debe tener al menos 1 letra minúscula",
  MIN_UPPERCASE = "La contraseña debe tener al menos 1 letra mayúscula",
  MIN_NUMBERS = "La contraseña debe tener al menos 1 número",
  MIN_SYMBOLS = "La contraseña debe tener al menos 1 símbolo",
}

export const MESSAGE_ERROR_USER: string = "Ingrese entre 6 y 8 digitos";

export const LoginRegex = {
  MIN_LOWERCASE: /[a-z]/,
  MIN_UPPERCASE: /[A-Z]/,
  MIN_NUMBERS: /\d/,
  MIN_SYMBOLS: /[^A-Za-z0-9]/,
};

export const MAX_PASS_LENGTH: number = 8;
export const MINIMUN_LENGHT_USER: number = 6;
export const MAXIMUN_LENGHT_USER: number = 8;

export const SESSION_EXPIRED =
  "Tu sesión ha expirado. Por favor, inicia sesión nuevamente.";

export const ERROR = "error";
