export enum ObservableEvents {
  LOGIN = "login",
  SACRAMENTO = "sacramento",
  NOTIFICATION = "notification",
  UPLOAD_CHILD = "upload_child",
  TOKEN_EXPIRED = "token_expired",
}

class ObservableService {
  private observers: { [key in ObservableEvents]?: Function[] } = {};

  addListeners(key: ObservableEvents, callback: Function) {
    if (!this.observers[key]) {
      this.observers[key] = [];
    }
    this.observers[key]?.push(callback);
  }

  removeListeners(key: ObservableEvents, callback: Function) {
    if (this.observers[key]) {
      this.observers[key] = this.observers[key]?.filter(
        (cb) => cb !== callback,
      );
    }
  }

  notifyListeners(key: ObservableEvents, data: any) {
    if (this.observers[key]) {
      this.observers[key]?.forEach((callback) => callback(data));
    }
  }
}

export default new ObservableService();
