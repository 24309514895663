import React from "react";
import { Box, Button, TableCell } from "@mui/material";
import GenericTable from "../../components/genericTable/GenericTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import administratorService from "../../services/administrator";
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const turnosColumns = [
  {
    name: "id",
    accessor: "id",
  },
  {
    name: "Iglesia",
    accessor: "iglesia",
    customReder: (row: any, column: any) => {
      return <TableCell>{row.iglesia.denomination}</TableCell>;
    },
  },
  {
    name: "Roles",
    accessor: "roles",
    customReder: (row: any, column: any) => {
      return <TableCell>{row.roles.join(", ")}</TableCell>;
    },
  },
];

const AdminUsersItem = () => {
  const { document } = useParams();
  const navigate = useNavigate();

  const fetchData = async (): Promise<any> => {
    return await administratorService
      .findOne(document!)
      .then((response: any) => {
        return response.rolesIglesia;
      });
  };

  const turnosActions = [
    {
      icon: (row: any) => <VisibilityIcon />,
      onClick: (row: any) => {},
    },
    {
      icon: (row: any) => <EditIcon />,
      onClick: (row: any) => {},
    },
    {
      icon: (row: any) => <DeleteIcon />,
      onClick: (row: any) => {},
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          width: "80%",
          padding: "0",
          overflow: "auto",
        }}
      >
        <p style={{ color: "black" }}>Users:</p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Volver
        </Button>
        <GenericTable<any>
          columns={turnosColumns}
          actions={turnosActions}
          title="turnos"
          fetchData={fetchData}
        />
      </Box>
    </Box>
  );
};

export default AdminUsersItem;
