import AddRoleForm from "../components/forms/addRoleForm/AddRoleForm";
interface Props {
  isAdmin: boolean;
}

const AddRolePage = ({ isAdmin }: Props) => {
  return <AddRoleForm isAdmin={isAdmin} />;
};

export default AddRolePage;
