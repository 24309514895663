export function getMenuItems(handleCloseSession: any) {
  return [
    {
      name: "SECRETARÍA",
      link: "#",
      options: [
        {
          name: "Bautismo",
          options: [
            { name: "Reservar fecha", link: "/panel/calendario/bautismo" },
            { name: "Certificado", link: "/panel/certificados/bautismo" },
          ],
        },
        {
          name: "Casamientos",
          options: [
            { name: "Reservar fecha", link: "/panel/calendario/casamientos" },
            { name: "Certificado", link: "/panel/certificados/casamiento" },
          ],
        },
        {
          name: "Comunion",
          options: [
            { name: "Reservar fecha", link: "/panel/calendario/comuniones" },
            { name: "Certificado", link: "/panel/certificados/comunion" },
          ],
        },
        {
          name: "Confirmación",
          options: [
            {
              name: "Reservar fecha",
              link: "/panel/calendario/confirmaciones",
            },
            { name: "Certificado", link: "/panel/certificados/confirmacion" },
          ],
        },
        { name: "Personas", link: "/panel/personas" },
      ],
    },
    {
      name: "AGENDA",
      link: "#",
      options: [
        {
          name: "Próximos",
          options: [
            { name: "Sacramentos", link: "/panel/turnos/pendientes" },
            { name: "Misas", link: "/panel/misas/programadas" },
          ],
        },
        {
          name: "Planificador",
          options: [
            { name: "Planificar horarios", link: "/panel/turnos/configuracion" },
            { name: "Crear Feriados", link: "/panel/feriados" },
          ],
        },
        {
          name: "Calendario",
          link: "/panel/calendar",
        },
      ],
    },
    {
      name: "ADMINISTRACIÓN",
      link: "#",
      options: [
        { name: "Roles", link: "/panel/mi-cuenta/roles" },
        {
          name: "Configuración",
          options: [
            { name: "Sectores", link: "/configuracion/sectores" },
            { name: "Iglesia", link: "/configuracion/iglesia" },
          ],
        },
        { name: "Cerrar Sesión", link: "/", action: handleCloseSession },
      ],
    },
  ];
}

export const PUSH_SUPERADMIN = {
  name: "SUPER-ADMIN",
  link: "#",
  options: [
    { name: "Usuarios", link: "/panel/admin-users" },
    { name: "Sacerdotes", link: "/panel/crear-sacerdotes-user" }
  ],
};
