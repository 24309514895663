import React from "react";
import "./CalendarPage.css";
import EventProvider from "../Calendar/contexts/EventContext";
import Calender from "../Calendar/components/Calendar";

/*
const WraperTimeLine = ({
  groups,
  items,
  defaultTimeStart,
  defaultTimeEnd,
}: any) => {
  const [dialogOpen, setDialogOpen] = React.useState({
    state: false,
    data: {} as any,
  });
  const navigate = useNavigate();

  /**
   * Handle dialog
   */
/*
const toggleDialog = (data = {}): void => {
  setDialogOpen({ state: !dialogOpen.state, data: data });
};

return (
  <>
    <Dialog open={dialogOpen.state} onClose={toggleDialog}>
      <DialogContent>
        <Typography>Tipo: {dialogOpen.data?.type}</Typography>
        <Typography>Repeticion: {dialogOpen.data?.repeticion}</Typography>
        <Typography>
          Ocupacion: {dialogOpen.data?.count}/ {dialogOpen.data?.limit}
        </Typography>
        {dialogOpen.data?.count > 0 && (
          <Button
            onClick={() => {
              navigate(`/panel/event-list/${dialogOpen.data?.identifier}`);
            }}
          >
            Ver eventos
          </Button>
        )}
      </DialogContent>
    </Dialog>
    <Timeline
      groups={groups}
      //@ts-ignore
      style={{ backgroundColor: "white" }}
      items={items}
      itemRenderer={({
        item,
        itemContext,
        getItemProps,
        getResizeProps,
      }: any) => {
        const { left: leftResizeProps, right: rightResizeProps } =
          getResizeProps();
        return (
          <div
            className="rct-item"
            {...getItemProps({
              style: {
                position: "relative", // Add this line
                //@ts-ignore
                backgroundColor: itemColors[item.type] || "white",
                //@ts-ignore
                color: item.color,
                borderRadius: 5,
                borderLeftWidth: 1,
                border: "none",
                boxShadow: "0px 3px 6px rgba(0,0,0,0.36)", // Add this line
                borderRightWidth: 1,
              },
              onMouseDown: () => {
                toggleDialog(item);
              },
              onDoubleClick: () => {},
              //@ts-ignore
              ref: (el) => (this.itemRef = el),
            })}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "none",
                //@ts-ignore
                backgroundColor: itemColors[item.type],
                filter: "blur(5px)",
                zIndex: -1,
              }}
            />
            {itemContext.useResizeHandle ? (
              <div {...leftResizeProps} />
            ) : null}

            <div
              style={{
                height: itemContext.dimensions.height,
                overflow: "hidden",
                paddingLeft: 3,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item.type}- Ocupado: {item.count}/{item.limit} - Repeticion:{" "}
              {item.frecuencyType}
            </div>

            {itemContext.useResizeHandle ? (
              <div {...rightResizeProps} />
            ) : null}
          </div>
        );
      }}
      fullUpdate
      itemTouchSendsClick={false}
      stackItems={true}
      itemHeightRatio={0.75}
      canMove={false}
      lineHeight={50}
      canChangeGroup={false}
      canResize={false}
      defaultTimeStart={defaultTimeStart}
      defaultTimeEnd={defaultTimeEnd}
    >
      <TodayMarker date={new Date()} interval={200000}>
        {({ styles, date }) => (
          <div
            style={{
              ...styles,
              position: "absolute",
              zIndex: 100,
              backgroundColor: "gray",
            }}
          />
        )}
      </TodayMarker>
    </Timeline>
  </>
);
};
*/

const Calendar = () => {
  
  return (
    <EventProvider>
      <Calender />  
    </EventProvider>
  );
};

export default Calendar;
