import React, { CSSProperties } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as styles from "./certificationsStyles";
import { Document, Page, pdfjs } from "react-pdf";
import { CertificationType } from "../../utils/cerficates/bautismoEnum";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface DialogComponentProps {
  open: boolean;
  pdfUrl: string;
  onClose: () => void;
  type: CertificationType;
  handleOpenDialog: () => void;
}

const DialogComponent: React.FC<DialogComponentProps> = ({
  open,
  onClose,
  pdfUrl,
  type,
}) => {
  const {
    centerElementStyle,
    containerCertificateStyle,
    dialogTitleStyle,
    dialogContenStyle,
    buttonStyle,
  } = styles;

  const renderButtonBack = (): JSX.Element => {
    return (
      <Grid item xs={12} style={{ display: "flex", height: "3rem" }}>
        <Button onClick={onClose}>
          <ArrowBackIcon />
        </Button>
      </Grid>
    );
  };

  const renderChristeningCertificateWithData = (): JSX.Element => {
    return (
      <Grid item xs={10} style={containerCertificateStyle}>
        <Grid container justifyContent={"center"}>
          {renderChristeningCertificate()}
          {renderWindowContent()}
        </Grid>
      </Grid>
    );
  };

  const renderChristeningCertificate = (): JSX.Element => {
    return (
      <Grid item xs={12}>
        <DialogTitle style={dialogTitleStyle}>
          Certificado de {type}
        </DialogTitle>
      </Grid>
    );
  };

  const renderWindowContent = (): JSX.Element => {
    return (
      <Grid item xs={10}>
        <DialogContent style={dialogContenStyle}>
          <Document file={pdfUrl}>
            <Page pageNumber={1} renderTextLayer={false} />
          </Document>
        </DialogContent>
      </Grid>
    );
  };

  const renderButtonsContainer = (): JSX.Element => {
    return (
      <Grid
        item
        container
        xs={10}
        columns={12}
        gap={1}
        style={{ marginBottom: "1rem" }}
      >
        {renderButton(true)}
        {renderButton(false)}
      </Grid>
    );
  };

  const renderButton = (isSendEmail: boolean): JSX.Element => {
    const buttonText = isSendEmail ? "ENVIAR COPIA POR MAIL" : "IMPRIMIR";
    const handlePrint = () => {
      const printWindow = window.open();
      if (printWindow) {
        printWindow.document.write(
          "<html><head><title>Print</title></head><body>",
        );
        printWindow.document.write(
          '<embed width="100%" height="100%" src="' +
            pdfUrl +
            '" type="application/pdf" />',
        );
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        printWindow.onload = function () {
          printWindow.print();
        };
      }
    };
    return (
      <Grid item xs={12} sm={12} md={4} style={centerElementStyle}>
        <Button
          style={buttonStyle}
          onClick={isSendEmail ? undefined : handlePrint}
        >
          {buttonText}
        </Button>
      </Grid>
    );
  };
  return (
    <Dialog open={open} onClose={onClose} style={centerElementStyle}>
      <Grid container justifyContent="center" style={{ maxWidth: "64rem" }}>
        {renderButtonBack()}
        {renderChristeningCertificateWithData()}
        {renderButtonsContainer()}
      </Grid>
    </Dialog>
  );
};

export default DialogComponent;
