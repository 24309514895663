import { useEffect, useState } from "react";
import iglesiasService from "../../services/iglesias";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import authService from "../../services/auth";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { theme } from "../../theme";

const IglesiaDetailPage = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    iglesiasService
      .getImage(
        "/iglesia/get-image/",
        authService.getIglesiaSelected()?.iglesia?.image,
      )
      .then((response: any) => {
        try {
          //@ts-ignore
          setImagePreview(URL.createObjectURL(response));
        } catch (error) {}
      });
  }, []);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const response: any = await iglesiasService.uploadImage(
        "/iglesia/upload-image/" + authService.getIglesiaSelected()?.iglesia.id,
        file,
      ); // Asegúrate de reemplazar '/your/upload/url' con la URL correcta de tu backend
      if (!!response) {
        try {
          const imageUrl: any = URL.createObjectURL(file);
          setImageUrl(response);
          setImagePreview(imageUrl);
        } catch (error) {}
      }
    }
  };

  return (
    <Grid container justifyContent={"center"} sx={{ height: "100%" }}>
      <Grid item xs={6} style={{ padding: "10%" }}>
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
        >
          <IconButton onClick={handleGoBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            sx={{
              color: theme.font.primary,
              fontSize: "18px",
              fontWeight: 600,
              marginLeft: "15px",
            }}
          >
            Volver
          </Typography>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            justifyContent: "center",
            padding: "5%",
          }}
        >
          <div>
            <Typography
              variant="subtitle1"
              sx={{ margin: "15px 0", fontWeight: 500, color: "#31876C" }}
            >
              Fondo del Panel
            </Typography>
            <img
              src={imagePreview || ""}
              style={{
                width: "300px",
                height: "300px",
                borderRadius: "5px",
                objectFit: "cover",
                paddingBottom: "5px",
              }}
            />
          </div>
          <div>
            <input
              style={{ all: "unset", cursor: "pointer" }}
              type="file"
              onChange={handleFileChange}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default IglesiaDetailPage;
