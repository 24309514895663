import { theme } from "../../../theme";
import { CSSProperties } from "react";

export const stylesReligion = {
  grid: {
    width: "100%",
    marginBottom: "20px",
  },
  typography: {
    display: "flex",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: 600,
    color: theme.font.primary,
    marginBottom: "20px",
  },
  textField: {
    backgroundColor: "white",
  },
  box: {
    "& > img": { mr: 2, flexShrink: 0 },
  },
};

export const inputLabelStyles: CSSProperties = {
  backgroundColor: "white",
};
