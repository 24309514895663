import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Filter {
  param: string;
  value: string;
}

export interface FeriadoFilterState {
  filters: Filter[];
}

const initialState: FeriadoFilterState = {
  filters: [
    {
      param: "date",
      value: "",
    },
    {
      param: "scope",
      value: "",
    },
    {
      param: "state",
      value: "",
    },
    {
      param: "place",
      value: "",
    },
  ],
};

export const feriadoFilterSlice = createSlice({
  name: 'feriadoFilters',
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<{ param: string; value: string }>) => {
      const { param, value } = action.payload;
      const filterIndex = state.filters.findIndex(filter => filter.param === param);
      if (filterIndex !== -1) {
        state.filters[filterIndex].value = value;
      }
    },
    // Acción para restablecer todos los filtros a sus valores iniciales
    resetFilters: (state) => {
      state.filters = initialState.filters.map(filter => ({ ...filter }));
    },
  },
});

// Exportar las acciones
export const { updateFilter, resetFilters } = feriadoFilterSlice.actions;

// Exportar el reducer
export default feriadoFilterSlice.reducer;