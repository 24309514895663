import axios, { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";
import ObservableService, { ObservableEvents } from "../ObservableService";
import eventosService from "../eventos";

export class SacramentosService extends GenericService {
  private idSacramento: number | null = null;
  private idEvent: number | null = null;

  constructor() {
    super();
    ObservableService.addListeners(
      ObservableEvents.SACRAMENTO,
      (idEvent: number) => {
        this.idEvent = idEvent;
      },
    );
  }

  getEventId() {
    return this.idEvent;
  }

  async addPeoples(peoples: any): Promise<any> {
    return await this.post(
      "/sacramento/addPeoples?idSacramento=" + this.idEvent, //TODO get idSacramento
      peoples,
    );
  }

  async updateEventState(stateEvent?: string) {
    let data = "";
    if (stateEvent) {
      data = stateEvent;
    }
    eventosService
      .updateEventState(this.idEvent!, { state: "" }, data as any)
      .then((response: any) => {});
  }
}

const sacramentosService = new SacramentosService();

export default sacramentosService;
