import React, { useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
} from "@mui/material";
import {
  GenericTableProps,
  filerData,
  Column,
  Action,
} from "../../interfaces/components/interfacesForms";
import { stylesGenericTable } from "./GenericTableStyles";
import { DIV } from "../../enums/components/componentsEnum";

const GenericTable = <T,>(props: GenericTableProps<T>): JSX.Element => {
  const [data, setData] = useState<T[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [total, setTotal] = useState(0);
  const fetchData = (
    page: number,
    rowsPerPage: number,
    filters: filerData[] = [],
  ) => {
    const filter = filters.map((f) => `${f.param}=${f.value}`).join("&");

    props.fetchData(page + 1, rowsPerPage, filter).then((response: any) => {
      if (response && Array.isArray(response.data) && response.total !== undefined) {
        setData([...response.data]);
        setTotal(response.total);
      } else if (Array.isArray(response) && response.length === 0) {
        setData([]);
        setTotal(0);
      } else if (Array.isArray(response)) {
        setData([...response]);
        setTotal(response.length);
      } else {
        setData([]);
        setTotal(0);
      }
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchData(page, rowsPerPage, props.filtersData);
  }, [page, rowsPerPage, props.refresh, props.filtersData]);

  return (
    <TableContainer sx={stylesGenericTable.tableStyles}>
      {!!props.filters && <Grid container>{props.filters()}</Grid>}
      <Table sx={stylesGenericTable.tableStyles}>
        <TableHead sx={stylesGenericTable.tableStyles}>
          <TableRow sx={stylesGenericTable.tableStyles}>
            {props.columns.map((column: Column<T>, index: number) => (
              <TableCell key={index} sx={stylesGenericTable.tableStyles}>
                {column.name}
              </TableCell>
            ))}
            <TableCell sx={stylesGenericTable.tableStyles}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={stylesGenericTable.tableStyles}>
          {data.map((row: any, rowIndex: number) => (
            <TableRow key={rowIndex} sx={stylesGenericTable.tableRow}>
              {props.columns.map((column: Column<T>, columnIndex: number) => {
                const key = `${rowIndex}-${columnIndex}`;
                return (
                  <React.Fragment key={key}>
                    {!!column.customReder && column.customReder(row, column)}
                    {!column.customReder && (
                      <TableCell sx={stylesGenericTable.tableStyles} key={key}>
                        {row[column.accessor]}
                      </TableCell>
                    )}
                  </React.Fragment>
                );
              })}
              <TableCell sx={stylesGenericTable.tableStyles}>
                {props.actions.map((action: Action<T>, actionIndex: number) => {
                  const key = `${rowIndex}-${actionIndex}`;
                  return (
                    <IconButton
                      key={key}
                      onClick={() => action.onClick(row)}
                      style={{ color: "rgba(0,0,0, 0.3)" }}
                    >
                      {action.icon(row)}
                    </IconButton>
                  );
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[6, 10, 25]}
        component={DIV}
        count={total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default GenericTable;
