//Utils peopleData

export function hasSacramento(data: any, sacramentoType: string): boolean {
  if (!data || !data.sacramentos) {
    return false;
  }

  return data.sacramentos.some(
    (sacramento: { sacramento: { type: string; checkComplete: boolean } }) => {
      if (!sacramento || !sacramento.sacramento) {
        return false;
      }

      return (
        sacramento.sacramento.type === sacramentoType &&
        sacramento.sacramento.checkComplete
      );
    },
  );
}

export function calcularEdad(birthdate: any) {
  const birthDate = new Date(birthdate);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export enum StaticTexts {
  FICHA_PERSONAL = "Ficha personal",
  TEXT_URL = "https://i.snipboard.io/9NU6q7.jpg",
  NAME = "Nombre:",
  SURNAME = "Apellido:",
  DNI_TYPE = "Tipo de documento:",
  DNI = "Documento:",
  EMAIL = "Email:",
  PHONE = "Telefono:",
  BIRTHDATE = "Fecha de nacimiento:",
  EDAD = "Edad:",
  FATHER_NAME = "Padre",
  MOTHER_NAME = "Madre",
  ADDRESS_LOCALITY = "Ciudad de residencia",
  ADDRES_PROVINCE = "Provincia de residencia",
  ADDRESS_PART = "Partido de residencia",
  ADDRESS_COUNTRY = "Pais de residencia",
  ADDRESS_STREET = "Calle de residencia",
  ADDRESS_NUMBER = "Numero de residencia",
  NACIONALITY = "Nacionalidad",
  BAUTISMO = "Bautismo",
  CONFIRMACION = "Confirmacion",
  COMUNION = "Comunion",
  CASAMIENTO = "Casamiento",
  URL_IMG = "https://i.snipboard.io/9NU6q7.jpg",
  DATOS_PERSONALES = "Datos personales",
  HISTORIA_ECLESIASTICA = "Historia eclesiástica",
  DATOS_RESIDENCIALES = "Datos residenciales",
}

export enum NewSearchEnum {
  NUEVA_BUSQUEDA = "Nueva Búsqueda",
}
