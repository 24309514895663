import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useEffect, useState } from "react";
import {
  LoadState,
  LocationSelectProps,
  Option,
} from "../../../interfaces/components/interfacesAutoComplete";
import { getValidationColor } from "../../../utils/autocomplete/autoCompleteUtils";
import { inputLabelStyles } from "./CountriesComponentStyles";
import { IGLESIA, Sizes } from "../../../enums/components/componentsEnum";
import { useLocation } from "react-router-dom";

const LocationSelect = ({
  id,
  options,
  size,
  label,
  onChange,
  value,
  errors,
  loadState,
  helperText,
  disabled = false,
}: LocationSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const isValueInOptions = !!options && options
    ? options.some((option) => option.name === selectedOption?.name)
    : false;
  const location = useLocation();
  const eventId = location.state?.eventId;

  useEffect(() => {
    if (eventId) {
      loadState.current = LoadState.EDITING;
    }
  }, [loadState]);

  useEffect(() => {
    if (value && value !== selectedOption) {
      if (id === IGLESIA) {
        //@ts-ignore
        const denomination = value?.denomination;
        //@ts-ignore
        const localityName = value?.locality?.name;
        const iglesiaId = value?.id;

        const iglesiaLocality = `${denomination} (${localityName})`;

        const selectedOption = {
          name: iglesiaLocality,
          id: iglesiaId,
        };
        setSelectedOption(selectedOption);
      } else {
        setSelectedOption(value);
      }
      onChange(id, value);
    }

    getValidationColor(errors, errors?.[id], value, loadState?.current);
  }, [value]);

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    const newValue =
      options.find((option) => option.name === event.target.value) || null;
    setSelectedOption(newValue);
    onChange(id, newValue);
  };

  return (
    <FormControl
      variant="outlined"
      size={size}
      margin={Sizes.NORMAL}
      fullWidth
      error={errors?.[id]}
      style={{
        border: getValidationColor(
          errors,
          errors?.[id],
          value,
          loadState?.current,
        ),
        borderRadius: "4px",
      }}
    >
      <InputLabel id={id} sx={inputLabelStyles}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        id={id}
        value={isValueInOptions ? selectedOption?.name : ""}
        onChange={handleOptionChange}
        label={label}
        disabled={disabled}
      >
        {options &&
          options.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default LocationSelect;
