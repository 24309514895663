import TopFrame from "../../assets/shared/topFrame_01.svg";

export function handleError(
  error: any,
  addNotification: Function,
  setLoginSuccess: Function,
  setLoginError: Function,
  queryClient: any,
) {
  const errorMessage =
    error?.response?.data?.message || "Usuario o contraseña incorrectos";
  addNotification(queryClient, "error", errorMessage);
  setLoginSuccess(false);
  setLoginError(true);
}

export function handleSuccess(
  addNotification: Function,
  setAuthenticated: Function,
  setLoginSuccess: Function,
  setLoginError: Function,
  queryClient: any,
) {
  setAuthenticated(true);
  const successMessage = "Inicio de sesión exitoso";
  addNotification(queryClient, "success", successMessage);
  setLoginSuccess(true);
  setLoginError(false);
}

export function handleSettled(queryClient: any) {
  queryClient.invalidateQueries("user");
}

export const CustomSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1366 160"
    preserveAspectRatio="none"
    fill="white"
    style={{
      position: "absolute",
      left: 0,
      width: "100vw",
      height: "180px",
    }}
  >
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      fill={`url(#myImage)`}
      style={{ backgroundColor: "#F4F4F3 !important" }}
    />
    <defs>
      <pattern
        id="myImage"
        patternUnits="userSpaceOnUse"
        fill="white"
        width="1366"
        height="180"
      >
        <image href={TopFrame} fill="white" width="1366" height="180" />
      </pattern>
    </defs>
  </svg>
);
