import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const stylesRegisterForm = {
  box1: {
    width: "50%",
    paddingTop: "3em",
    paddingBottom: "3em",
  },
  box2: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "20px",
  },
  typography1: {
    color: theme.font.primary,
    fontSize: "18px",
    fontWeight: 600,
    marginLeft: "15px",
  },
  typography2: {
    fontSize: "20px",
    fontWeight: 500,
    color: "#31876C",
    marginBottom: "20px",
  },
  typography3: {
    margin: "15px 0",
    fontWeight: 500,
    color: "#31876C",
  },
  typography4: {
    fontWeight: 500,
    color: "#31876C",
    marginBottom: "15px",
  },
  typography5: {
    fontWeight: 500,
    color: "#31876C",
    marginBottom: "15px",
  },
  typography6: {
    fontWeight: 500,
    color: "#31876C",
    marginBottom: "15px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px",
    margin: "auto",
  },
  form: {
    marginTop: "0px",
  },
  grid: {
    "& > .MuiGrid-item": { paddingTop: 0 },
  },
  div: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "5%",
  },
  grid1: {
    marginTop: "15px",
  },
  grid2: {
    marginTop: "15px",
  },
  grid3: {
    marginTop: "15px",
  },
  button: {
    marginTop: "20px",
  },
};

export const stylesRegister = (
  imagePreview: any,
): { button: CSSProperties } => ({
  button: {
    borderRadius: "75px",
    height: "150px",
    width: "150px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${imagePreview})`,
  },
});

export const inputLabelStyles: CSSProperties = {
  backgroundColor: "white",
};
