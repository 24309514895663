import { AlertColor } from "@mui/material";
import { QueryClient } from "react-query";

export const addNotification = (
  queryClient: QueryClient,
  type: AlertColor,
  message: string,
) => {
  const id = Math.random().toString();

  queryClient.setQueryData<Notification[] | undefined>(
    "notifications",
    (prevNotifications: any) => {
      const notifications = prevNotifications || [];
      return [...notifications, { id, type, message }];
    },
  );
};

export const removeNotification = (queryClient: QueryClient, id: string) => {
  queryClient.setQueryData<Notification[] | undefined>(
    "notifications",
    (prevNotifications) => {
      const notifications = prevNotifications || [];
      return notifications.filter(
        (notification: any) => notification.id !== id,
      );
    },
  );
};
