import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class SacerdoteShadowService extends GenericService {
  constructor() {
    super();
  }



  async getSacerdotes(page: number, size: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/sacerdoteShadow/getSacerdotes/" + page + "/" + size,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener sacerdotes");
    }
  }

  async getSacerdotesNotRegistred(page: number, size: number, filter?: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/sacerdoteShadow/getSacerdotesNotRegistred/" + page + "/" + size + "?" + filter,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener sacerdotes");
    }
  }

  async findSacerdoteById(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/sacerdoteShadow/findSacerdoteById/" + id,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener el sector");
    }
  }


}

const sacerdoteShadowService = new SacerdoteShadowService();

export default sacerdoteShadowService;
