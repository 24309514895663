export const errorStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "80%",
  fontSize: "12px",
  color: "red",
};

export const errorStyleOk: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "80%",
  fontSize: "12px",
  color: "green",
};

export const gridErrorStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "15px",
};
