import { GenericService } from "../GenericService";
import ApiResponse from "../ApiResponse";
import { getUserTimeZone } from "../../utils/datesUtils/dateUtils";

export class CalendarioService extends GenericService {
  constructor() {
    super();
  }

  async create(evento: any, eventType: any): Promise<any> {
    return await this.post(`/calendar/createEvent/${eventType}`, evento);
  }

  async getTurnosByDate(date: Date, turnoType: any): Promise<any[]> {
    try {
      const response: ApiResponse<any[]> = await this.get(
        `/calendar/turnosByDate/${turnoType}/${date}`,
      );
      //@ts-ignore
      return response;
    } catch (error) {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  }

  async getTurnosByMonth(date: Date): Promise<any[]> {
    try {
      const response: ApiResponse<any[]> = await this.get(
        `/calendar/turnosByMonth/${date}`,
      );
      //@ts-ignore
      return response;
    } catch (error) {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  }

  async getTurnosByMonthV2(date: Date): Promise<any[]> {
    try {
      const response: ApiResponse<any[]> = await this.get(
        `/calendar/turnosByMonth-v2/${date}`,
      );
      //@ts-ignore
      return response;
    } catch (error) {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  }

  async getTurnosByMonthV2ForBigCalendar(date: Date): Promise<any[]> {
    const timeZone = getUserTimeZone();
    try {
      const response: ApiResponse<any[]> = await this.get(
        `/calendar/turnosByMonth-v2/${date}?timeZone=${timeZone}`,
      );
      //@ts-ignore
      return response;
    } catch (error) {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  }

  async getTurnosByIdSpecification(specificationId: number): Promise<any[]> {
    try {
      const response: ApiResponse<any[]> = await this.get(
        `/calendar/turnoFromSpecificationTurno/${specificationId}`,
      );

      //@ts-ignore
      return response;
    } catch (error) {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  }
}

const calendarioService = new CalendarioService();

export default calendarioService;
