export const stylesAdultForm = {
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 25px",

    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#F8F8F8",
    cursor: "pointer",
    maxWidth: "350px",
    minHeight: "450px",
  },
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },
  typography: {
    fontWeight: "500",
    fontSize: "14px",
    mt: 2,
    textAlign: "center",
  },
  iconButton: {
    fontSize: "48px",
  },
};
