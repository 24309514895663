import { theme } from "../../../theme";

export const styles = {
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px",
    margin: "0 25px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#F8F8F8",
    maxWidth: "350px",
  },
  typography: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "18px",
    fontWeight: 600,
    color: theme.font.primary,
    marginBottom: "20px",
  },
  gridItem: {
    "& > .MuiGrid-item": { paddingTop: 0 },
  },
};
