import { CSSProperties } from "react";
import { theme } from "../../../theme";
import { getTitleAndColor } from "../../../utils/alerts/AlertsUtils";

export const styles = {
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  box1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
    padding: "24px",
    maxWidth: "400px",
    backgroundColor: "#fff",
  },
  typography1: { color: theme.font.primary, fontSize: "14px", mt: 2 },
  typography2: { color: theme.font.primary, fontSize: "14px", mt: 2 },
  typography3: {
    color: "#fff",
    fontSize: "14px",
    padding: "5px 10px",
    fontWeight: 600,
  },
  button: {
    textTransform: "uppercase",
    mt: 3,
    backgroundColor: theme.button.primary,
    "&:hover": theme.button.primary,
  },
};
