import jwt_decode, { JwtPayload } from "jwt-decode";
import ObservableService, {
  ObservableEvents,
} from "../../services/ObservableService";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

//TODO refactor

export const isAuthenticated = () => {
  const access_token = localStorage.getItem("access_token");
  if (
    access_token &&
    typeof access_token === "string" &&
    access_token.trim() !== ""
  ) {
    try {
      const decodedToken = jwt_decode<JwtPayload>(access_token);
      const currentTime = Math.floor(Date.now() / 1000);
      const isTokenValid = decodedToken.exp && decodedToken.exp > currentTime;
      if (!isTokenValid) {
        ObservableService.notifyListeners(ObservableEvents.TOKEN_EXPIRED, null);
      }
      return isTokenValid;
    } catch (error) {
      console.error("Error al decodificar el token JWT:", error);
      return false;
    }
  }
  return false;
};

export const isSuperAdmin = () => {
  const access_token = localStorage.getItem("access_token");
  if (
    access_token &&
    typeof access_token === "string" &&
    access_token.trim() !== ""
  ) {
    try {
      const decodedToken = jwt_decode<JwtPayload>(access_token);
      const currentTime = Math.floor(Date.now() / 1000);
      //@ts-ignore
      return (
        decodedToken.exp &&
        decodedToken.exp > currentTime &&
                                    //@ts-ignore
        decodedToken.systemRols?.includes("SuperAdmin")
      );
    } catch (error) {
      console.error("Error al decodificar el token JWT:", error);
      return false;
    }
  }
  return false;
};

export const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("iglesiaSelected");
  ObservableService.notifyListeners(ObservableEvents.LOGIN, false);
};