import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useQueryClient, useQuery } from "react-query";
import {
  Directions,
  NOTIFICATIONS,
} from "../../enums/components/componentsEnum";

const Notifications: React.FC = () => {
  const queryClient = useQueryClient();
  const [fadeOpacity, setFadeOpacity] = useState(1);

  const { data: notifications = [] } = useQuery<Notification[]>(
    NOTIFICATIONS,
    () => queryClient.getQueryData<Notification[]>(NOTIFICATIONS) ?? [],
  );

  const handleClose = (notificationId: string) => {
    queryClient.setQueryData<any>(NOTIFICATIONS, (prevNotifications: any) => {
      if (!prevNotifications) return undefined;
      return prevNotifications.filter(
        (notification: any) => notification.id !== notificationId,
      );
    });
  };

  useEffect(() => {
    let fadeTimeout: NodeJS.Timeout;

    if (notifications.length > 0) {
      setFadeOpacity(1);
      fadeTimeout = setTimeout(() => {
        setFadeOpacity(0);
      }, 4000);
    }

    return () => {
      clearTimeout(fadeTimeout);
    };
  }, [notifications]);

  return (
    <>
      {notifications.map((notification: any) => (
        <Snackbar
          key={notification.id}
          open
          onClose={() => handleClose(notification.id)}
          anchorOrigin={{
            vertical: Directions.TOP,
            horizontal: Directions.RIGHT,
          }}
          TransitionProps={{
            style: {
              transition: "opacity 0.75s",
              opacity: fadeOpacity, // Utiliza el estado fadeOpacity para controlar la opacidad
            },
          }}
        >
          <Alert
            severity={notification.type}
            onClose={() => handleClose(notification.id)}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export default Notifications;
