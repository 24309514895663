import React from "react";
import { Stepper, Step, StepLabel, Button, Box } from "@mui/material";
import { StepperStyles } from "./StepperStyles";
import {
  ATRAS,
  CONTAINED,
  FINALIZAR,
  InOrder,
  SIGUIENTE,
} from "../../enums/components/componentsEnum";

const StepperComponent: React.FC<any> = ({
  stepsData,
  activeStep,
  setActiveStep,
  handleStepComplete,
  showBackButton = true,
  showNextButton = true,
}) => {
  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={StepperStyles.box}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {stepsData.map((step: any, index: any) => (
          <Step key={index} sx={StepperStyles.step}>
            <StepLabel onClick={() => {}}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === stepsData.length ? (
          <div>
            <Box sx={{ mt: 2 }}>
              <Button onClick={handleReset}>Volver al inicio</Button>
            </Box>
          </div>
        ) : (
          <div>
            <Box sx={StepperStyles.box1}>
              {stepsData[activeStep].component(stepsData[activeStep].action)}
            </Box>
            <Box sx={StepperStyles.box2}>
              {showBackButton && (
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  {ATRAS}
                </Button>
              )}

              {showNextButton && (
                <Button
                  variant={CONTAINED}
                  color={InOrder.PRIMARY}
                  onClick={handleStepComplete}
                >
                  {activeStep === stepsData.length - 1 ? FINALIZAR : SIGUIENTE}
                </Button>
              )}
            </Box>
          </div>
        )}
      </div>
    </Box>
  );
};

export default StepperComponent;
