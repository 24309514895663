export const stylesGenericTable = {
  tableStyles: {
    backgroundColor: "transparent",
    border: "none !important",
    borderCollapse: "separate",
    borderSpacing: "10px 30px",
  },
  tableRow: {
    boxShadow: "0px 0px 5px .1px #3176B0",
    borderRadius: "15px",
  },
};
