import { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import Logo from "../../../assets/logo/default_01.svg";
import { Link, useNavigate } from "react-router-dom";
import Login from "../../../pages/login";
import authService from "../../../services/auth";
import { useMutation, useQueryClient } from "react-query";
import { addNotification } from "../../../utils/notifications";
import { AxiosResponse } from "axios";
import { useMatch } from "react-router-dom";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { stylesNavbar } from "./MainNavbarStyles";
import {
  CONTAINED,
  InOrder,
  LOGO,
  NavbarEnum,
  ROUTE_LOGIN,
} from "../../../enums/components/componentsEnum";
import {
  CustomSvg,
  handleError,
  handleSettled,
  handleSuccess,
} from "../../../utils/navBar/NavBarUtils";

const Navbar = () => {
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const match = useMatch(ROUTE_LOGIN);

  const { authenticated, setAuthenticated } = useAuthentication();

  useEffect(() => {
    if (match) {
      setOpenLogin(true);
    }
  }, [match]);

  const loginMutation = useMutation<void, AxiosResponse, any>(
    async (credentials) => {
      await authService.login(credentials);
    },
    {
      onError: (error: any) => {
        handleError(
          error,
          addNotification,
          setLoginSuccess,
          setLoginError,
          queryClient,
        );
      },
      onSuccess: () => {
        handleSuccess(
          addNotification,
          setAuthenticated,
          setLoginSuccess,
          setLoginError,
          queryClient,
        );
      },
      onSettled: () => {
        handleSettled(queryClient);
      },
    },
  );

  return (
    <Box sx={stylesNavbar.box}>
      <CustomSvg />

      <Link to="/" style={{ zIndex: 10 }}>
        <img src={Logo} alt={LOGO} />
      </Link>

      {authenticated ? (
        <Button
          color={InOrder.PRIMARY}
          variant={CONTAINED}
          sx={stylesNavbar.buttonStyle}
          onClick={() => navigate(ROUTE_LOGIN)}
        >
          {NavbarEnum.MI_CUENTA}
        </Button>
      ) : (
        <Button
          color={InOrder.PRIMARY}
          variant={CONTAINED}
          sx={stylesNavbar.buttonStyle}
          onClick={() => setOpenLogin(true)}
        >
          {NavbarEnum.INGRESAR}
        </Button>
      )}
      {openLogin && (
        <Login
          open={openLogin}
          setOpen={setOpenLogin}
          loginError={loginError}
          loginSuccess={loginSuccess}
          loginMutation={loginMutation}
        />
      )}
    </Box>
  );
};

export default Navbar;
