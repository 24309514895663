import { theme } from "../../theme";

export const stylesParentData = {
  cardGeneral: {
    width: "100%",
  },
  buttonNewSearch: {},
  boxPrincipal: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    gap: "3em",
    height: "87%",
  },
  gridGeneralData: {
    display: "flex",
    justifyContent: "center",
    gap: "8em",
    paddingBottom: "2em",
  },
  gridBoxes: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
  containerTitle: {
    padding: "0.5em",
    paddingBottom: "2em",
    alignItems: "center",
    justifyContent: "center",
  },
  gridTitle: {
    alignItems: "center",
    justifyContent: "flex-end",
  },
  gridSecondTitle: {
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: "1.5em",
    minWidth: "100%",
    margin: "1em",
  },

  typographyTitle: {
    fontWeight: "bold",
  },
  containerImg: {
    justifyContent: "flex-start",
  },
  gridContainerTexts: {
    alignItems: "center",
  },
  gridsTextsStatics: {
    alignItems: "center",

    paddingBottom: "0.9em",
  },
  gridsTextsDynamics: {
    paddingBottom: "0.9em",
    paddingLeft: "1em",
  },
  typographyStaticsTexts: {
    fontSize: "1em",
  },

  typographyDynamicTexts: {
    fontSize: "1em",
  },
};
