import React, { useEffect, useState } from "react";
import administratorService from "../../services/administrator";
import { Box, Button, TableCell } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import GenericTable from "../../components/genericTable/GenericTable";
import { useNavigate, useParams } from "react-router-dom";
import iglesiasService from "../../services/iglesias";
import ProfilePage from "../Profile/ProfilePage";
import Avatar from "react-avatar";

const RowImage = (row: any) => {
  const [imageBackground, setImageBackground] = useState(null);

  useEffect(() => {
    if (!row.imageProfile) return;
    iglesiasService
      .getImage("/iglesia/get-image/", row.imageProfile)
      .then((response: any) => {
        try {
          //@ts-ignore
          setImageBackground(URL.createObjectURL(response));
        } catch (error) {}
      });
  }, []);

  return (
    <TableCell>
      {!!imageBackground && (
        <img
          style={{
            width: "80px",
            height: "80px",
            objectFit: "cover",
            borderRadius: "50%",
          }}
          src={imageBackground}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = "";
          }}
        />
      )}

      {
        <>
          {!imageBackground && (
            <Avatar
              size="80"
              textSizeRatio={1}
              round={true}
              name={row?.nombre + " " + row?.apellido}
            />
          )}
        </>
      }
    </TableCell>
  );
};

const getColorByIndex = (index: number) => {
  const colors = [
    "#FFB3BA",
    "#FFDFBA",
    "#FFFFBA",
    "#BAFFC9",
    "#BAE1FF",
    "#BABEFF",
    "#DABAFF",
    "#FFBAF4",
    "#FFBAD2",
    "#FFBABA",
    "#FFC8BA",
    "#FFF5BA",
    "#D5FFBA",
    "#BAFFD0",
    "#BAFBFF",
    "#C5BAFF",
    "#FFBAFF",
    "#FFBADB",
  ];
  return colors[index % colors.length];
};

const rolesColumns = [
  {
    name: "Iglesia",
    accessor: "iglesia",
    customReder: (row: any, column: any) => {
      return <TableCell>{row.iglesia.denomination}</TableCell>;
    },
  },
  {
    name: "Roles",
    accessor: "rolesIglesia",
    customReder: (row: any, column: any) => {
      return (
        <TableCell>
          {row.roles?.map((item: any, index: number) => {
            return (
              <span
                style={{
                  backgroundColor: getColorByIndex(index),
                  marginLeft: "",
                  padding: "2%",
                  borderRadius: "5px",
                }}
              >
                {item}{" "}
              </span>
            );
          })}
        </TableCell>
      );
    },
  },
];

const RolesIglesiaPage = ({ isAdmin }: any) => {
  const [profileSelected, setProfileSelected] = useState<any>(null);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { id } = useParams();

  let turnosActions = [
    {
      icon: (row: any) => <EditIcon />,
      onClick: (row: any) => {
        setDialogOpen(true);
        setProfileSelected({ item: row, isAdmin: isAdmin, owner: false });
      },
    },
  ];

  const fetchTurnos = async (page: number, pageSize: number) => {
    if (!isAdmin) {
      //@ts-ignore
      let result = await administratorService.findById(id);
      return result.rolesIglesia;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          width: "90%",
          padding: "0",
          overflow: "auto",
        }}
      >
        <p style={{ color: "black" }}></p>
        {dialogOpen && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              backgroundColor: "#3a8dbe",
              padding: "12px",
              zIndex: 100,
              opacity: 1,
              borderRadius: "10px",
            }}
          >
            <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
              <ProfilePage
                isAdmin={isAdmin}
                isOwner={false}
                custom={{
                  fullScreen: "100%",
                  backgroundColor: "transparent",
                  handleGoBack: () => {
                    setDialogOpen(false);
                  },
                  handleClose: () => {
                    setDialogOpen(false);
                  },
                  item: profileSelected.item,
                }}
              />
            </div>
          </div>
        )}
        <Button
          style={{ backgroundColor: "#3A8DBE" }}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(`/panel/mi-cuenta/add-role-iglesia/${id}`, {
              state: { isAdmin },
            });
          }}
        >
          Agregar Iglesia +
        </Button>
        <GenericTable<any>
          //@ts-ignore
          columns={rolesColumns}
          actions={turnosActions}
          title="turnos"
          fetchData={fetchTurnos}
        />
      </Box>
    </Box>
  );
};

export default RolesIglesiaPage;
