import { Box, Grid } from "@mui/material";
import React from "react";
import BaptismForm from "../forms/baptismForm";
import CivilRegistrationForm from "../forms/civilRegistrationForm";
import { BautismoStepStyles } from "./StepsStyles";
import { Sacraments, StepsEnum } from "../../enums/components/componentsEnum";

//todo change BautismoStep for SacramentoStep
const BautismoStep = ({
  formData,
  handleFormChange,
  type,
  errors,
}: any): JSX.Element => {
  return (
    <Grid container style={BautismoStepStyles.grid}>
      <Grid item xs={6}>
        <div style={BautismoStepStyles.div}>
          <BaptismForm
            title={`${StepsEnum.DATOS_DE} ${type}`}
            formData={formData.baptism}
            onChange={(key: any, value: any) =>
              handleFormChange(`${Sacraments.BAPTISM}.${key}`, value)
            }
            customStyle={true}
            errors={errors?.baptism}
            inGeneratedTurn={false}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <div style={BautismoStepStyles.div2}>
          <CivilRegistrationForm
            title={StepsEnum.DATOS_REGISTRO_CIVIL}
            formData={formData.civilRegistration}
            onChange={(key: any, value: any) =>
              handleFormChange(`${StepsEnum.CIVIL_REGISTRATION}.${key}`, value)
            }
            errors={errors?.civilRegistration}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default BautismoStep;
