export const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "10vh",
    },
    innerContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      padding: "0",
      overflow: "auto",
      userSelect: "none" as const,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "40vw",
    },
    formContainer: {
      backgroundColor: "white",
      padding: "4%",
      userSelect: "none" as const,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },
    table: {
      minWidth: 650,
    },
    horarioInput: {
      display: 'flex',
      alignItems: 'center',
    },
    submitButton: {
      textAlign: "center",
      marginTop: "20px",
    },
  };