import React, { useState } from "react";
import { TextField, Button, Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import sectorService from "../../services/sectores";
import { addNotification } from "../../utils/notifications";
import { RoutesEnum } from "../../utils/RoutesEnum";
import { useQueryClient } from "react-query";

const CreateSector = () => {
  const [description, setDescription] = useState("");
  const [use, setUse] = useState("");

  const navigate = useNavigate();

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleUseChange = (event: any) => {
    setUse(event.target.value);
  };
  const queryClient = useQueryClient();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sectorService
      .create({
        description: description,
        use: use,
      })
      .then((result) => {
        addNotification(
          queryClient,
          "success",
          "Sector " + result.description + " creado correctamente.",
        );
        navigate(RoutesEnum.SECTORES);
      })
      .catch((error) => {
        addNotification(
          queryClient,
          "error",
          "No se pudo crear el sector.  Error: " + error,
        );
      });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "25vh",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          padding: "0",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "40vw",
          }}
        >
          <IconButton onClick={handleGoBack}>
            <ArrowBackIcon />
          </IconButton>
          <h2 style={{ color: "black", textAlign: "center" }}>Crear Sector</h2>
          <div></div>
        </Box>
        <div style={{ backgroundColor: "white", padding: "2%" }}>
          <form onSubmit={handleSubmit}>
            <Box>
              <TextField
                id="names"
                label="Nombre"
                type="text"
                value={description}
                onChange={handleDescriptionChange}
                fullWidth
                sx={{ marginBottom: "10px" }}
              />
              <TextField
                label="Uso"
                type="text"
                id="use"
                value={use}
                onChange={handleUseChange}
                fullWidth
              />
            </Box>
            <Box sx={{ textAlign: "center", marginTop: "20px" }}>
              <Button type="submit" variant="contained" color="primary">
                Crear sector
              </Button>
            </Box>
          </form>
        </div>
      </Box>
    </Box>
  );
};

export default CreateSector;
