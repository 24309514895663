import { Box } from "@mui/material";
import ScheduleAppointment from "../scheduler/scheduleAppointment";
import { TurnoStepStyles } from "./StepsStyles";

const TurnoStep = ({
  type,
  formData,
  dateSelected,
  errors,
  handleStepComplete = () => {},
}: any): JSX.Element => {
  return (
    <Box sx={TurnoStepStyles.box}>
      <ScheduleAppointment
        formData={formData.scheduleAppointment}
        onChange={() => {}}
        turnoType={type}
        dateSelected={dateSelected}
        handleStepComplete={handleStepComplete}
        errors={errors.scheduleAppointment}
      />
    </Box>
  );
};

export default TurnoStep;
