import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles = {
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "25px",
    height: "100%",
    width: "100%",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#F8F8F8",
  },
  typography: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "16px",
    fontWeight: 600,
    color: theme.font.primary,
    marginBottom: "20px",
  },
  gridItem: {
    "& > .MuiGrid-item": { paddingTop: 0 },
  },
  typography1: {
    textAlign: "center",
    fontSize: "0.8em",
    color: "DodgerBlue",
    opacity: "0.6",
  },
  typography2: { textAlign: "center", fontSize: "0.8em" },
};

export const inputLabelStyles: CSSProperties = {
  backgroundColor: "white",
};
