import React, { useEffect } from "react";
import { Box, Grid, TableCell } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import GenericTable from "../../components/genericTable/GenericTable";
import DeleteIcon from "@mui/icons-material/Delete";
import turnoExpecificationService from "../../services/turnosExpecification";
import moment from "moment";
import ObservableService, { ObservableEvents } from "../../services/ObservableService";
import { convertUTCToLocal, getOnlyDate } from "../../utils/datesUtils/dateUtils";
import { stylesListadoFeriado } from "../feriados/ListadoFeriados/ListadoFeriadosStyles";
import { CreateFeriadoEnum } from "../../enums/pages/CreateFeriadoEnum";
import feriadosService from "../../services/feriados";
import turnoService from "../../services/turnos";
import { isFeriado2 } from "../../utils/scheduleAppointment/scheduleAppointmentUtils";

const MisasList = () => {
  const [forceRefresh, setForceRefresh] = React.useState(new Date());
  const [totalTurnos, setTotalTurnos] = React.useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const [feriados, setFeriados] = React.useState<any[]>([]);

  useEffect(() => { 
    feriadosService.getFeriadosForCalendar().then((response: any) => {
      setFeriados(response);
    });
  } , []);

  const renderFilters = React.useCallback(() => (
    <Grid
        item
        container
        xs={12}
        gap={2}
        style={stylesListadoFeriado.gridRenderFilters}
    >
        <Grid item xs={1}>
            <button
                style={stylesListadoFeriado.gridButtonRender}
                onClick={() => navigate(-1)}
            >
                {CreateFeriadoEnum.BACK}
            </button>
        </Grid>
    </Grid>
  ), [navigate]);

  const turnosColumns = [
    {
      name: "Fecha",
      accessor: "startTime",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
           {`${moment(getOnlyDate(row.startTime)).format("DD-MM-YYYY")}`}
          </TableCell>
        );
      },
    },  
    { 
      name: "Hora de Inicio / Fin", 
      accessor: "",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            {row.startHour && row.endHour ? `${convertUTCToLocal(row.startHour)} / ${convertUTCToLocal(row.endHour)}` : ''}
          </TableCell>
        );
      },
    },
    { 
      name: "Sector", 
      accessor: "sector.description",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            {row.sector ? row.sector.description : '-'}
          </TableCell>
        );
      },
    
    },
  ];

  /**
   * Actions for turnos
   */
  const turnosActions = [
    {
      icon: (row: any) => <DeleteIcon />,
      onClick: (row: any) => {
        const confirmDelete = window.confirm(
          "¿Estás seguro que deseas eliminar este evento?",
        );
         if (confirmDelete) {
          turnoService.deleteTurnoWithEventId(row.id).then((response: any) => {
            setForceRefresh(new Date());
            ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
              type: "success",
              message: "Se elimino el evento correctamente",
            });
          });
        } 
      },
    },
  ];

  const fetchTurnos = async (
    page: number,
    pageSize: number,
    filter?: string,
  ) => {
    let expecificationId: any = id!;
    const response = await turnoExpecificationService.getTurnosbyId(expecificationId, page, pageSize);
    let turnos = response?.turnos;
    const total = response?.total;
    setTotalTurnos(total);

    turnos = turnos.filter((turno: any) => {
      const fechaTurno = getOnlyDate(turno.startTime);
      if (isFeriado2(fechaTurno, feriados)) {
        if (turno.expecification.criteryFeriados === 'sin_actividad') {
          return false;
        } else if (turno.expecification.criteryFeriados === 'horario_especial') {
          turno.startHour = turno.expecification.startHourSpecial;
          turno.endHour = turno.expecification.endHourSpecial;
        }
      }
      return true;
    });

    return turnos;
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          width: "90%",
          padding: "0",
          overflow: "auto",
        }}
      >
        <div
          style={{
            paddingBottom: "25px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            alignContent: "flex-end",
          }}
        ></div>
        <GenericTable<any>
          filtersData={[{ param: "status", value: "pending" }]}
          refresh={forceRefresh}
          columns={turnosColumns}
          actions={turnosActions}
          title="Misas programadas"
          fetchData={fetchTurnos}
          totalTurnos={totalTurnos}
          filters={renderFilters}
        />
      </Box>
    </Box>
  );
};

export default MisasList;
