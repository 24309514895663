export const StepperStyles = {
  box: {
    width: "100%",
  },
  step: {
    cursor: "pointer",
    "&:hover": {
      color: "#e0e0e0",
      fontWeight: 700,
    },
  },
  box1: {
    mt: 2,
  },
  box2: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0",
    paddingBottom: "2%",
    paddingLeft: "2%",
  },
};
