import { css } from "@emotion/react";

export const stylesPanel = {
  linkStyle: {
    color: "white",
    marginRight: "0px",
    fontFamily: "'Poppins', sans-serif",
    textDecoration: "none",
    padding: "10px 15px",
    height: "100%",
    whiteSpace: "nowrap" as any,
  },
  div: {
    position: "relative" as "relative",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "200px",
    left: "100%",
    top: 15,
    overflow: "hidden",
    position: "absolute",
    borderRadius: "none",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  box1: {
    position: "relative",
    display: "inline-block",
  },
  box2: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    backgroundColor: "rgba(58, 141, 190, 0.5)",
    paddingTop: "15px",
    top: "100% + 35px",
    left: 0,
    borderRadius: "5px",
  },
  box3: {
    display: "flex",
    height: "100%",
    padding: "15px 5%",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    zIndex: 100,
    background: "none",
    backgroundColor: "#3A8DBE",
  },
};

export const submenuStyles = `
  .submenu {
    background-color: #3A8DBE !important;
  }
  .submenu-link {
    background-color: #2C6B9C;
    color: gray;
    border-radius: 5px;
  }
`;
