import React, { Fragment, useEffect } from "react";
import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import Notifications from "./components/notifications";
import moment from "moment";
import "moment/locale/es";
import PrivateNavigator from "./navigator/PrivateNavigator";
import PublicNavigator from "./navigator/PublicNavigator";
import { isAuthenticated } from "./utils/auth";
import ObservableService, {
  ObservableEvents,
} from "./services/ObservableService";
import { addNotification } from "./utils/notifications";
import { ERROR, SESSION_EXPIRED } from "./utils/login/loginEnum";

moment.locale("es");

export const queryClient = new QueryClient();

const App: React.FC = () => {
  const [isLogged, setIsLogged] = React.useState<boolean>(
    isAuthenticated() || false,
  );

  useEffect(() => {
    ObservableService.addListeners(
      ObservableEvents.TOKEN_EXPIRED,
      (status: boolean) => {
        setIsLogged(status);
        addNotification(queryClient, ERROR, SESSION_EXPIRED);
        window.location.href = "/login";
      },
    );
  }, []);

  /**
   * This function render the menu depending on the user is logged or not
   * @returns
   */
  const RenderMenu = (): JSX.Element => {
    const queryClient = useQueryClient();

    if (!isLogged) {
      localStorage.clear();
    }

    useEffect(() => {
      ObservableService.addListeners(
        ObservableEvents.NOTIFICATION,
        (notification: any) => {
          addNotification(queryClient, notification.type, notification.message);
        },
      );
    }, []);

    return isLogged ? <PrivateNavigator /> : <PublicNavigator />;
  };

  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <Notifications />
        <RenderMenu />
      </QueryClientProvider>
    </Fragment>
  );
};

export default App;
