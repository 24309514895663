import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";
import { Arquidiocesis } from "../../pages/events/CompleteEventData/utils/completeDataEnum";

export class ArquidiocesisService extends GenericService {
  constructor() {
    super();
  }

  //TODO
  async getArquidiocesisByIdIgle(): Promise<Arquidiocesis> {
    try {
      const response: AxiosResponse<any[]> = await this.get(
        "/arquidiocesis/findArquidiocesisByIdIgle",
      );
      //@ts-ignore
      return response as any;
    } catch (error) {
      console.log("Error al obtener las arquidiocesis:", error);
      return {} as Arquidiocesis;
    }
  }

  async getAllArquidiocesis(): Promise<Arquidiocesis[]> {
    try {
      const response: AxiosResponse<any[]> = await this.get("/arquidiocesis");
      //@ts-ignore
      const result = response.map(item => ({
        name: `${item.denomination} (${item.abbreviation})`,
        id: item.id
      }));
      return result as any;
    } catch (error) {
      console.log("Error al obtener las arquidiocesis:", error);
      return [] as Arquidiocesis[];
    }
  }
}

const arquidiocesisService = new ArquidiocesisService();

export default arquidiocesisService;
