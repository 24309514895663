import { TextField, Button, Paper, Typography, Box, Grid } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import peopleServie from "../../../services/people";
import { useLocation } from "react-router-dom";
import DocumentTypeComponent from "../../autocomplete/documentType/DocumentTypeComponent";
import RoleSelector from "../../autocomplete/padrinos/PadrinosTypeComponent";
import LocationForm from "../genericLocationForm/LocationForm";
import GenericLocationBox from "../genericLocationForm/GenericLocationBox";
import { LoadState } from "../../../enums/LoadStateEnum";
import {
  DOCUMENT,
  Errors,
  IGLESIA,
  IGLESIA_CAPITALIZED,
  Locaciones,
  Locations,
  Roles,
  RoleFormEnum,
  Sizes,
  CONTAINED,
  InOrder,
  FormTextSend,
  GUARDAR,
  AdultFormEnum,
  DatosPersonales,
  DataPersonal,
  TEXT,
  TIPO,
  DATE,
  ROLE,
  RolesEnum,
  UserRoles,
} from "../../../enums/components/componentsEnum";
import {
  assignResponseToFormData,
  getDocumentTypes,
  getMaritalStatuses,
} from "../../../utils/forms/formsUtils";
import { getValidationColor } from "../../../utils/autocomplete/autoCompleteUtils";
import { inputLabelStyles, styles } from "./AdultFormStyles";
import React from "react";

const AdultForm = ({
  title,
  formData,
  index,
  onRemove,
  onChange,
  errors,
  role,
  showRoleSelect = false,
  statusCivil = false,
}: any) => {
  const [forceRender, setForceRender] = useState(new Date());
  const loadState = useRef(LoadState.DEFAULT);
  const location = useLocation();
  const eventId = location.state?.eventId;
  const [isFetch, setIsFetch] = React.useState(false);

  useEffect(() => {
    if (eventId) {
      loadState.current = LoadState.EDITING;
    }
  }, []);

  const handleFormChange = (key: any, value: string) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }
    onChange(key as any, value);
  };

  const handleDocumentTypeChange = (key: any, value: any) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }
    onChange(key as any, value.id);
  };

  const handleRoleChange = (key: any, value: any) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }
    onChange(key as any, value);
  };

  const resetStateValidator = (
    state: LoadState = LoadState.DEFAULT,
    isFetch = false,
  ) => {
    setIsFetch(isFetch);
    loadState.current = state;
    setForceRender(new Date());
  };

  const handleFetchPerson = async (dni: string, documentType: string) => {
    dni = dni ?? "";
    documentType = documentType ?? "";
    if (!!dni && dni.length > 0 && documentType.length > 0) {
      peopleServie
        .findPeople(dni, documentType)
        .then((response: any) => {
          if (!!response) {
            formData = assignResponseToFormData(formData, response);
            resetStateValidator(LoadState.LOADED, true);
          } else {
            resetStateValidator();
          }
        })
        .catch((error: any) => {
          resetStateValidator();
        });
    } else {
      resetStateValidator();
    }
  };

  return (
    <>
      <Box>
        <Paper sx={styles.paper}>
          {onRemove && index && (
            <Box sx={styles.box}>
              <Button sx={styles.button} onClick={() => onRemove(index)}>
                <CloseIcon sx={styles.closeIcon} />
              </Button>
            </Box>
          )}
          <Typography sx={styles.typography1}>
            {title?.toUpperCase() ?? ""}
          </Typography>
          <Grid container spacing={2} sx={styles.grid1}>
            <Grid item xs={12}>
              {loadState.current == LoadState.LOADED && (
                <Typography sx={styles.typography2}>
                  {AdultFormEnum.MATCH_PEOPLE_WITH} {formData.document_type} N°{" "}
                  {formData.document}
                </Typography>
              )}
              {loadState.current !== LoadState.LOADED && (
                <Typography sx={styles.typography3}>
                  <br></br>
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.NAME}
                label={DatosPersonales.NAME}
                InputLabelProps={{ sx: inputLabelStyles }}
                type={TEXT}
                disabled={false}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.name,
                    formData?.name,
                    loadState?.current,
                  ),
                  opacity: loadState.current == LoadState.LOADED ? 0.8 : 1,
                  borderRadius: "5px",
                }}
                value={formData?.name ?? ""}
                onChange={(e) =>
                  handleFormChange(DataPersonal.NAME, e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors?.name}
                helperText={errors?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.SURNAME}
                label={DatosPersonales.SURNAME}
                type={TEXT}
                InputLabelProps={{ sx: inputLabelStyles }}
                value={formData?.surname ?? ""}
                disabled={false}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.surname,
                    formData?.surname,
                    loadState?.current,
                  ),
                  opacity: loadState.current == LoadState.LOADED ? 0.8 : 1,
                  borderRadius: "5px",
                }}
                onChange={(e) =>
                  handleFormChange(DataPersonal.SURNAME, e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.surname}
                helperText={errors.surname}
              />
            </Grid>
            <Grid item xs={5}>
              <DocumentTypeComponent
                id={DataPersonal.DOCUMENT_TYPE}
                InputLabelProps={{ sx: inputLabelStyles }}
                label={TIPO}
                disabled={false}
                loadState={loadState}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.document_type,
                    formData?.document_type,
                    loadState?.current,
                  ),
                  opacity:
                    loadState.current == LoadState.EDITING &&
                    formData?.document_type !== ""
                      ? 0.6
                      : 1,
                  borderRadius: "5px",
                }}
                countries={getDocumentTypes()}
                onFocus={() => resetStateValidator()}
                onBlur={() =>
                  handleFetchPerson(formData.document, formData.document_type)
                }
                value={formData.document_type ?? ""}
                onChange={handleDocumentTypeChange}
                size={Sizes.SMALL}
                errors={!!errors.document_type}
                helperText={errors.document_type}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id={DataPersonal.DNI}
                label={DatosPersonales.NUMBER_DOCUMENT}
                type={TEXT}
                disabled={false}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.document,
                    formData?.document,
                    loadState?.current,
                  ),
                  opacity:
                    loadState.current == LoadState.EDITING &&
                    formData?.document !== ""
                      ? 0.6
                      : 1,
                  borderRadius: "5px",
                }}
                value={formData.document}
                onBlur={(e) =>
                  handleFetchPerson(formData.document, formData.document_type)
                }
                onChange={(e) =>
                  handleFormChange(DataPersonal.DOCUMENT, e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.document}
                helperText={errors.document}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.PHONE}
                label={DatosPersonales.CELULAR}
                type={DataPersonal.NUMBER}
                InputLabelProps={{ sx: inputLabelStyles }}
                disabled={false}
                value={formData.phone ?? ""}
                onChange={(e) =>
                  handleFormChange(DataPersonal.PHONE, e.target.value)
                }
                margin={Sizes.NORMAL}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.phone,
                    formData?.phone,
                    loadState?.current,
                  ),
                  opacity: loadState.current == LoadState.LOADED ? 0.8 : 1,
                  borderRadius: "5px",
                }}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.EMAIL}
                label={DatosPersonales.EMAIL}
                InputLabelProps={{ sx: inputLabelStyles }}
                type={DataPersonal.EMAIL}
                disabled={false}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.email,
                    formData?.email,
                    loadState?.current,
                  ),
                  opacity: loadState.current == LoadState.LOADED ? 0.8 : 1,
                  borderRadius: "5px",
                }}
                value={formData.email ?? ""}
                onChange={(e) =>
                  handleFormChange(DataPersonal.EMAIL, e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            {statusCivil && (
              <Grid item xs={12}>
                <DocumentTypeComponent
                  id={DataPersonal.STATUS_CIVIL}
                  InputLabelProps={{ sx: inputLabelStyles }}
                  label={DatosPersonales.ESTADO_CIVIL}
                  disabled={false}
                  loadState={loadState}
                  style={{
                    border: getValidationColor(
                      errors,
                      errors?.statusCivil,
                      formData?.statusCivil,
                      loadState?.current,
                    ),
                    opacity:
                      loadState.current == LoadState.EDITING &&
                      formData?.statusCivil !== ""
                        ? 0.6
                        : 1,
                    borderRadius: "5px",
                  }}
                  countries={getMaritalStatuses()}
                  value={formData.statusCivil ?? ""}
                  onChange={handleDocumentTypeChange}
                  size={Sizes.SMALL}
                  errors={!!errors.statusCivil}
                  helperText={""}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                id={DataPersonal.BIRTHDATE}
                label={DatosPersonales.FECHA_NACIMIENTO}
                type={DATE}
                disabled={false}
                value={formData.birthdate ?? ""}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.birthdate,
                    formData?.birthdate,
                    loadState?.current,
                  ),
                  opacity: loadState.current == LoadState.LOADED ? 0.8 : 1,
                  borderRadius: "5px",
                }}
                onChange={(e) =>
                  handleFormChange(DataPersonal.BIRTHDATE, e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.birthdate}
                helperText={errors.birthdate}
              />
            </Grid>
            <LocationForm
              formData={formData}
              onChange={handleFormChange}
              errors={errors}
              nacimiento={true}
              disabled={false}
              isFetch={isFetch}
              loadState={loadState}
            />
            <Grid item xs={12}>
              {showRoleSelect && (
                <RoleSelector
                  id={ROLE}
                  label={UserRoles.ROL}
                  loadState={loadState}
                  roles={[
                    { id: RolesEnum.GODFATHER, name: Roles.PADRINO },
                    { id: RolesEnum.GODMOTHER, name: Roles.MADRINA },
                  ]}
                  disabled={false}
                  value={formData.role ?? ""}
                  //@ts-ignore
                  onChange={(e: any, value: any) => {
                    if (value.id !== formData.role) {
                      handleRoleChange(ROLE, value.id);
                    }
                  }}
                  size={Sizes.SMALL}
                  errors={!!errors.role}
                  helperText={errors.role ?? ""}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {
        //TODO move this form to new component
      }
      <GenericLocationBox
        formData={formData}
        onChange={handleFormChange}
        errors={errors}
        loadState={loadState}
        getValidationColor={getValidationColor}
        isFetch={isFetch}
      />
    </>
  );
};

export default AdultForm;
