import moment, { Moment } from "moment";
import { TimeUnits } from "../../enums/components/componentsEnum";

export function handleOnClick(
  timeSlot: any,
  index: any,
  handleRangeChange: any,
  handleStepComplete: any,
) {
  if (timeSlot.state !== "RESERVED") {
    handleRangeChange(
      timeSlot.start,
      timeSlot.end,
      timeSlot.turnoId,
      timeSlot.specificationId,
      timeSlot.limit,
      timeSlot.count,
      index,
    );
  }
  handleStepComplete();
}

/**
 * Checks if a specific day is a feriado.
 * 
 * @param day The day to check.
 * @param feriados The list of feriados.
 * @returns true if the day is a holiday, false otherwise.
 */

//TODO unificar con isFeriado2
export const isFeriado = (day: Moment | null, feriados: Array<{ date: string, transferTo: string }>): boolean => {
  return feriados.some((feriado) => {
    return moment(day).isSame(moment(feriado.transferTo ? feriado.transferTo : feriado.date), TimeUnits.DAYS);
  });
};

export const isFeriado2 = (dayStr: string, feriados: Array<{ date: string, transferTo?: string }>): boolean => {
  return feriados.some((feriado) => {
    const feriadoDateStr = feriado.transferTo ? feriado.transferTo : feriado.date;
    return dayStr === feriadoDateStr;
  });
};