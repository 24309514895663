import React from "react";
import { TableCell } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GenericTable from "../../components/genericTable/GenericTable";
import turnoService from "../../services/turnos";
import ObservableService, {
  ObservableEvents,
} from "../../services/ObservableService";
import moment from "moment";

const turnosColumns = [
  {
    name: "Fecha",
    accessor: "startTime",
    customReder: (row: any, column: any) => {
      //@ts-ignore
      return (
        <TableCell>
          {moment(row[column.accessor]).format("YYYY-MM-DD")}
        </TableCell>
      );
    },
  },
  { name: "Hora de Inicio", accessor: "startHour" },
  { name: "Hora de Fin", accessor: "endHour" },
  { name: "Cupos", accessor: "slots" },
  { name: "Tipo", accessor: "type" },
];

const ListadoDeTurnos = () => {
  const [forceRefresh, setForceRefresh] = React.useState(new Date());
  const [totalTurnos, setTotalTurnos] = React.useState(0);

  const fetchTurnos = async (page: number, pageSize: number) => {
    const response = await turnoService.getTurnos(page, pageSize);
    const result = response.turnos;
    const total = response.total;
    setTotalTurnos(total);
    return await result;
  };

  const turnosActions = [
    {
      icon: (row: any) => <EditIcon />,
      onClick: (row: any) => {},
    },
    {
      icon: (row: any) => <DeleteIcon />,
      onClick: (row: any) => {
        const confirmDelete = window.confirm(
          "¿Estás seguro que deseas eliminar este turno?",
        );
        if (confirmDelete) {
          turnoService
            .deleteTurno(row.id)
            .then((response: any) => {
              setForceRefresh(new Date());
              ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
                type: "success",
                message: "Se elimino el turno correctamente",
              });
            })
            .catch((error: any) => {});
        }
      },
    },
  ];

  return (
    <GenericTable<any>
      columns={turnosColumns}
      refresh={forceRefresh}
      actions={turnosActions}
      title="Turnos"
      fetchData={fetchTurnos}
      totalTurnos={totalTurnos}
    />
  );
};

export default ListadoDeTurnos;
