import { CSSProperties } from "react";

export const inputLabelStyles: CSSProperties = {
  backgroundColor: "white",
};

export const styles = {
  box: {
    "& > img": { mr: 2, flexShrink: 0 },
  },
};
