import { Box, Grid } from "@mui/material";
import React from "react";
import AdultForm from "../forms/adultForm";
import BaptismForm from "../forms/baptismForm";
import AddAdultForm from "../forms/addAdultForm";
import { GodParentStepStyles } from "./StepsStyles";
import { Sacraments, StepsEnum } from "../../enums/components/componentsEnum";

const GodParentsStep = ({
  godFatherForm,
  handleAddPadrino,
  handleFormChange,
  handlePadrinoChange,
  handleRemovePadrino,
  errors,
  enableBautismoData,
  showRole,
}: any): JSX.Element => {
  return (
    <Grid container spacing={10} sx={GodParentStepStyles.grid}>
      {!!godFatherForm &&
        godFatherForm.map((padrino: any, index: any) => {
          return (
            <Grid item xs={4} key={`${index}-${padrino.name}`}>
              <AdultForm
                key={index}
                role={godFatherForm[index].role} // TODO use padrino and rename all
                title={StepsEnum.PADRINO_MADRINA}
                // @ts-ignore
                formData={padrino}
                onChange={(key: any, value: any) =>
                  handlePadrinoChange(index, key, value)
                }
                errors={errors.godFather[index]}
                showRoleSelect={showRole}
              />
              {enableBautismoData && (
                <div style={GodParentStepStyles.div}>
                  <BaptismForm
                    title={StepsEnum.DATOS_BAUTISMO}
                    formData={padrino}
                    onChange={(key: any, value: any) => {
                      handlePadrinoChange(
                        index,
                        `${Sacraments.BAPTISM}.` + key,
                        value,
                      );
                    }}
                    //errors={errors.baptism}
                    inGeneratedTurn={true}
                  />
                </div>
              )}
            </Grid>
          );
        })}

      <Grid item xs={4}>
        <AddAdultForm onAddPadrino={handleAddPadrino} />
      </Grid>
    </Grid>
  );
};

export default GodParentsStep;
