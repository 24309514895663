import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { stylesParentData } from "./ParentDataStyles";
import {
  NewSearchEnum,
  StaticTexts,
  calcularEdad,
  hasSacramento,
} from "../../utils/peoples/peopleDataEnum";
import { useEffect, useState } from "react";
import peopleServie from "../../services/people";
import { RoutesEnum } from "../../utils/RoutesEnum";

const ParentData = () => {
  const { id, fatherId, motherId } = useParams();
  const [data, setData] = useState({
    name: "",
    surname: "",
    document_type: "",
    document: "",
    email: "",
    phone: "",
    birthdate: "",
    nationality: { name: "" },
    address: {
      locality: {
        name: "",
        part: {
          name: "",
          province: {
            name: "",
            country: { name: "" },
          },
        },
      },
      street: "",
      number: "",
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      let tempData;

      if (fatherId) {
        tempData = await peopleServie.findePeopleById(fatherId);
      } else if (motherId) {
        tempData = await peopleServie.findePeopleById(motherId);
      }

      return tempData;
    };

    fetchData().then((result) => {
      setData(result);
    });
  }, [id, fatherId, motherId]);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        display: "flex",
        justifyContent: "center,",
        alignItems: "center",
        height: "90vh",
        width: "100%",
        paddingLeft: "4em",
      }}
    >
      <Box sx={stylesParentData.boxPrincipal}>
        <Box sx={stylesParentData.buttonNewSearch}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(RoutesEnum.PERSONAS);
            }}
          >
            {NewSearchEnum.NUEVA_BUSQUEDA}
          </Button>
        </Box>
        <Card sx={stylesParentData.cardGeneral}>
          <CardContent>
            <Grid container sx={stylesParentData.containerTitle}>
              <Grid item xs={6} container sx={stylesParentData.gridTitle}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={stylesParentData.typographyTitle}
                >
                  {StaticTexts.FICHA_PERSONAL}
                </Typography>
              </Grid>
              <Grid item xs={6} container sx={stylesParentData.containerImg}>
                <img
                  src={StaticTexts.URL_IMG}
                  alt="Imagen"
                  width="80"
                  height="80"
                />
              </Grid>
            </Grid>
            <Grid container sx={stylesParentData.gridGeneralData}>
              <Grid item xs={4} sx={stylesParentData.gridBoxes}>
                <Grid
                  item
                  xs={7}
                  container
                  sx={stylesParentData.gridSecondTitle}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    sx={stylesParentData.typographyTitle}
                  >
                    {StaticTexts.DATOS_PERSONALES}
                  </Typography>
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.name !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.NAME}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.name}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.surname !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.SURNAME}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.surname}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.document_type !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.DNI_TYPE}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.document_type}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.document !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.DNI}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.document}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.email !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.EMAIL}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.email}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.phone !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.PHONE}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.phone}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.birthdate !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.BIRTHDATE}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.birthdate}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.birthdate !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.EDAD}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {calcularEdad(data.birthdate)}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.nationality.name !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.NACIONALITY}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.nationality.name}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={4} sx={stylesParentData.gridBoxes}>
                <Grid
                  item
                  xs={7}
                  container
                  sx={stylesParentData.gridSecondTitle}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    sx={stylesParentData.typographyTitle}
                  >
                    {StaticTexts.DATOS_RESIDENCIALES}
                  </Typography>
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.address.locality.part.province.country.name !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.ADDRESS_COUNTRY}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.address.locality.part.province.country.name}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.address.locality.part.province.name !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.ADDRES_PROVINCE}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.address.locality.part.province.name}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.address.locality.part.name !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.ADDRESS_PART}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.address.locality.part.name}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.address.locality.name !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.ADDRESS_LOCALITY}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.address.locality.name}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.address.street !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.ADDRESS_STREET}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.address.street}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid container sx={stylesParentData.gridContainerTexts}>
                  {data.address.number !== "" ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        container
                        sx={stylesParentData.gridsTextsStatics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyStaticsTexts}
                        >
                          {StaticTexts.ADDRESS_NUMBER}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={stylesParentData.gridsTextsDynamics}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={stylesParentData.typographyDynamicTexts}
                        >
                          {data.address.number}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ParentData;
