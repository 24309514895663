import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles = {
  grid: {
    padding: "25px",
  },
  box: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
    color: theme.font.primary,
    flexDirection: "column",
  },
  typography: {
    fontSize: "18px",
    textAlign: "center",
    color: "black",
    fontWeight: 600,
    marginBottom: "30px",
  },
  typography1: {
    margin: "10px 0px 0px 10px",
    fontSize: "12px",
  },
  typography2: {
    fontSize: "18px",
    textAlign: "center",
    fontWeight: 600,
    marginBottom: "30px",
    color: "black",
  },
  box1: {
    display: "flex",
    justifyContent: "flex-center",
    textAlign: "left",
    color: theme.font.primary,
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
    width: "80%",
    marginLeft: "10%",
  },
  staticDatePicker: {
    backgroundColor: "#f5f5f5",
    borderRadius: "20px",
  },
};

export const stylesSchedule = (
  index: any,
  timeSlot: any,
  selectedRange: any,
): { div: CSSProperties } => ({
  div: {
    display: "flex",
    width: "100%",
    alignSelf: "center",
    marginTop: "2%",
    backgroundColor: index !== selectedRange?.index ? "#3A8DBE" : "#2C6B9C",
    paddingLeft: "4%",
    paddingRight: "4%",
    borderRadius: "10px",
    color: "white",
    cursor: "pointer",
    opacity: timeSlot.state == "RESERVED" ? 0.3 : 1,
    justifyContent: "center",
    flexDirection: "row",
  },
});
