import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import Logo from "../../assets/logo/default_01.svg";
import CustomModal from "../../shared/modal";
import { theme } from "../../theme";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../hooks/useAuthentication";
import React from "react";
import {
  MAXIMUN_LENGHT_USER,
  MESSAGE_ERROR_USER,
  MINIMUN_LENGHT_USER,
} from "../../utils/login/loginEnum";
import { RoutesEnum } from "../../utils/RoutesEnum";
import PasswordValidator, {
  validatePassword,
} from "../../components/passwordValidation";
import DocumentTypeComponent from "../../components/autocomplete/documentType/DocumentTypeComponent";

const Login = ({
  open,
  setOpen,
  loginError,
  loginSuccess,
  loginMutation,
}: any) => {
  const [fields, setFields] = useState({
    username: "",
    password: "",
    document_type: "DNI",
  });
  const { authenticated, setAuthenticated } = useAuthentication();
  const [errorUser, setErrorUser] = useState({
    error: false,
    message: "",
  });
  const [hasAttemptedLogin, setHasAttemptedLogin] = useState(false);
  const PRESTINE_ERROR: any = { error: false, message: [] };
  const [errorPassword, setErrorPassword] = useState<any>(PRESTINE_ERROR);
  const { username, password, document_type } = fields;

  const validateUser = (username: string) => {
    const validateUser =
      username.length >= MINIMUN_LENGHT_USER &&
      username.length <= MAXIMUN_LENGHT_USER;
    return validateUser;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const handleLogin = async (
    e?:
      | React.FormEvent<HTMLFormElement>
      | React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (e) {
      e.preventDefault();
    }
    setHasAttemptedLogin(true);
    const { username, password, document_type } = fields;
    let shouldExecuteTryUser = true;
    let shouldExecuteTryPass = true;

    if (validateUser(username)) {
      setErrorUser({
        error: false,
        message: "",
      });
    } else {
      setErrorUser({
        error: true,
        message: MESSAGE_ERROR_USER,
      });
      shouldExecuteTryUser = false;
    }

    const passwordValidation = validatePassword(password);

    if (passwordValidation.length > 0) {
      setErrorPassword({
        error: true,
        message: passwordValidation,
      });
      shouldExecuteTryPass = false;
    } else {
      setErrorPassword({
        error: false,
        message: [],
      });
      shouldExecuteTryPass = true;
    }

    if (shouldExecuteTryUser && shouldExecuteTryPass) {
      try {
        await loginMutation.mutateAsync({ username, password, document_type });
        setAuthenticated(true);
        window.location.href = RoutesEnum.PANEL;
      } catch (error) {
        console.error("Error occurred during login:", error);
      }
    } else {
      setOpen(true);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const { isLoading } = loginMutation;

  const buttonStyle = {
    color: theme.button.text,
    "&:hover": {
      backgroundColor: theme.button.hover,
    },
    padding: "12px 30px",
  };

  const handleLoginStyling = {
    backgroundColor: loginMutation.isLoading
      ? theme.button.primary
      : loginSuccess
        ? "#4CAF50"
        : loginError
          ? "#f44336"
          : theme.button.primary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.button.hover,
    },
    padding: "12px 30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color 0.3s",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%",
        height: "100vh",
      }}
    >
      <CustomModal open={open} handleClose={setOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              style={{ fontSize: "24px", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img src={Logo} alt="Logo" />{" "}
              {/* Asegúrate de tener importado tu logo */}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box flex="1 1 auto">
                <Divider />
              </Box>
              <Typography
                sx={{
                  mx: 4,
                  color: "#31876C",
                  textTransform: "uppercase",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                INGRESÁ EN TU CUENTA
              </Typography>
              <Box flex="1 1 auto">
                <Divider />
              </Box>
            </Box>

            <form
              onSubmit={(e) => {
                handleLogin(e);
              }}
              style={{ width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <DocumentTypeComponent
                  id="document_type"
                  name="document_type"
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                    },
                  }}
                  label="Tipo de documento"
                  countries={[
                    { id: "DNI", name: "DNI" },
                    { id: "PASAPORTE", name: "PASAPORTE" },
                    { id: "CUIL", name: "CUIL" },
                    { id: "CUIT", name: "CUIT" },
                    { id: "CDI", name: "CDI" },
                    { id: "TI", name: "TI" },
                    { id: "LC", name: "LC" },
                    { id: "LE", name: "LE" },
                    { id: "CI", name: "CI" },
                  ]}
                  value={document_type}
                  onChange={(id: string, value: any) => {
                    setFields({ ...fields, ["document_type"]: value.id });
                  }}
                  size="small"
                />
                <TextField
                  sx={{ margin: "7.5px 0" }}
                  label="Documento"
                  id="input-document"
                  placeholder="sin puntos y sin espacios"
                  helperText={errorUser.message}
                  name="username"
                  value={username}
                  onChange={handleChange}
                  type="number"
                  error={errorUser.error}
                  required
                />
                <TextField
                  sx={{ margin: "7.5px 0" }}
                  label="Contraseña"
                  type="password"
                  id="input-password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  error={errorPassword.error}
                  required
                  helperText={
                    hasAttemptedLogin && (
                      <PasswordValidator password={password} />
                    )
                  }
                />

                <Button
                  variant="contained"
                  sx={{
                    ...buttonStyle,
                    ...handleLoginStyling,
                    margin: "7.5px 0",
                  }}
                  disabled={isLoading}
                  onClick={() => handleLogin()}
                  type="submit"
                  onKeyPress={handleKeyPress}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : loginSuccess ? (
                    <CheckIcon style={{ color: "#fff" }} />
                  ) : loginError ? (
                    <ClearIcon style={{ color: "#fff" }} />
                  ) : (
                    "Ingresar"
                  )}
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      </CustomModal>
    </div>
  );
};

export default Login;
