import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

const baseURL = process.env.REACT_APP_API_URL;

export class CertificationService extends GenericService {
  constructor() {
    super();
  }

  async getCertificateV2(
    type: string,
    dni: string,
    documentType: string,
  ): Promise<any> {
    const url = `/certificate/download/${type}/${dni}/${documentType}`;
    const response: AxiosResponse<Blob> = await this.getBlob(url, {
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    return response;
  }

  async getCertificatePeople(dni: string, documentType: string): Promise<any> {
    const url = `/certificate/download-person/${dni}/${documentType}`;
    const response: AxiosResponse<Blob> = await this.getBlob(url, {
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    return response;
  }
}

const certificationService = new CertificationService();

export default certificationService;
