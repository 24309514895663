import { TextField, Paper, Typography, Box, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import peopleServie from "../../../services/people";
import DocumentTypeComponent from "../../autocomplete/documentType/DocumentTypeComponent";
import ObservableService, {
  ObservableEvents,
} from "../../../services/ObservableService";
import React from "react";
import LocationForm from "../genericLocationForm/LocationForm";
import { LoadState } from "../../../enums/LoadStateEnum";
import GenericLocationBox from "../genericLocationForm/GenericLocationBox";
import { inputLabelStyles, styles } from "./childFormStyles";
import {
  assignFormDataChild,
  getDocumentTypes,
} from "../../../utils/forms/formsUtils";
import {
  Checks,
  ChildFormEnum,
  DATE,
  DOCUMENT,
  DataPersonal,
  DatosPersonales,
  Sizes,
  TEXT,
  TIPO,
} from "../../../enums/components/componentsEnum";
import { getValidationColor } from "../../../utils/autocomplete/autoCompleteUtils";

const ChildForm = ({
  title,
  formData,
  onChange,
  errors,
  setFormData,
  role,
  type,
}: {
  title: string;
  formData: any;
  onChange: any;
  errors: any;
  setFormData: any;
  role: string;
  type?: string;
}) => {
  const loadState = useRef(LoadState.DEFAULT);
  const location = useLocation();
  const [forceRender, setForceRender] = useState(new Date());
  const [checkMother, setCheckMother] = React.useState(false);
  const [checkFather, setCheckFather] = React.useState(false);
  const [isFetch, setIsFetch] = React.useState(false);

  const eventId = location.state?.eventId;

  useEffect(() => {
    if (eventId) {
      loadState.current = LoadState.EDITING;

      if (!!formData.checkFather && formData.checkFather === true) {
        setCheckFather(formData.checkFather);
      } else {
        if (!!formData.checkMother && formData.checkMother === true) {
          setCheckMother(formData.checkMother);
        }
      }
    }
  }, [eventId, formData]);

  const handleFormChange = (key: any, value: string | boolean) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    } else {
      if (key == Checks.CHECK_MOTHER && typeof value == "boolean") {
        setCheckMother(value);
      } else {
        if (key == Checks.CHECK_FATHER && typeof value == "boolean") {
          setCheckFather(value);
        }
      }
    }

    onChange(key as any, value);
  };

  const handleDocumentTypeChange = (key: any, value: any) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }
    onChange(key as any, value.id);
  };

  const resetStateValidator = (
    state: LoadState = LoadState.DEFAULT,
    isFetch = false,
  ) => {
    setIsFetch(isFetch);
    loadState.current = state;
    setForceRender(new Date());
  };

  const handleFetchPerson = async (dni: string, documentType: string) => {
    dni = dni ?? "";
    documentType = documentType ?? "";
    if (!!dni && dni.length > 0 && documentType.length > 0) {
      peopleServie
        .findPeople(dni, documentType)
        .then((response: any) => {
          if (!!response) {
            formData = assignFormDataChild(formData, response);
            resetStateValidator(LoadState.LOADED, true);
          } else {
            resetStateValidator();
          }

          ObservableService.notifyListeners(
            ObservableEvents.UPLOAD_CHILD,
            response,
          );
        })
        .catch((error: any) => {
          resetStateValidator();
        });
    } else {
      resetStateValidator();
    }
  };

  return (
    <>
      <Box>
        <Paper sx={styles.paper}>
          <Typography sx={styles.typography}>
            {title?.toUpperCase() ?? ""}
          </Typography>
          <Grid container spacing={2} sx={styles.gridItem}>
            <Grid item xs={12}>
              {loadState.current == LoadState.LOADED && (
                <Typography sx={styles.typography1}>
                  {ChildFormEnum.ENCONTRO_PERSONA} {formData.document_type}{" "}
                  {ChildFormEnum.NUM} {formData.document}
                </Typography>
              )}
              {loadState.current !== LoadState.LOADED && (
                <Typography sx={styles.typography2}>
                  <br></br>
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.NAME}
                label={DatosPersonales.NAME}
                type={TEXT}
                InputLabelProps={{ sx: inputLabelStyles }}
                disabled={false}
                value={formData.name ?? ""}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.name,
                    formData?.name,
                    loadState?.current,
                  ),
                  opacity: loadState.current == LoadState.LOADED ? 0.8 : 1,
                  borderRadius: "5px",
                }}
                onChange={(e) =>
                  handleFormChange(DataPersonal.NAME, e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id={DataPersonal.SURNAME}
                label={DatosPersonales.SURNAME}
                disabled={false}
                InputLabelProps={{ sx: inputLabelStyles }}
                type={TEXT}
                InputProps={{
                  style: {
                    border: getValidationColor(
                      errors,
                      errors?.surname,
                      formData?.surname,
                      loadState?.current,
                    ),
                    opacity: loadState.current == LoadState.LOADED ? 0.8 : 1,
                    borderRadius: "5px",
                  },
                }}
                value={formData.surname ?? ""}
                onChange={(e) =>
                  handleFormChange(DataPersonal.SURNAME, e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.surname}
                helperText={errors.surname}
              />
            </Grid>

            <Grid item xs={5}>
              <DocumentTypeComponent
                id={DataPersonal.DOCUMENT_TYPE}
                InputLabelProps={{ sx: inputLabelStyles }}
                loadState={loadState}
                label={TIPO}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.document_type,
                    formData?.document_type,
                    loadState?.current,
                  ),
                  opacity:
                    loadState.current == LoadState.EDITING &&
                    formData?.document_type !== ""
                      ? 0.6
                      : 1,
                  borderRadius: "5px",
                }}
                disabled={false}
                countries={getDocumentTypes()}
                onFocus={() => resetStateValidator()}
                onBlur={() =>
                  handleFetchPerson(formData.document, formData.document_type)
                }
                value={formData.document_type ?? ""}
                onChange={handleDocumentTypeChange}
                size={Sizes.SMALL}
                errors={!!errors.document_type}
                helperText={errors.document_type ?? ""}
              />
            </Grid>

            <Grid item xs={7}>
              <TextField
                id={DataPersonal.DNI}
                label={DatosPersonales.NUMBER_DOCUMENT}
                type={TEXT}
                disabled={false}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.dni,
                    formData?.document,
                    loadState?.current,
                  ),
                  opacity:
                    loadState.current == LoadState.EDITING &&
                    formData?.dni !== ""
                      ? 0.6
                      : 1,
                  borderRadius: "5px",
                }}
                value={formData.document ?? ""}
                onBlur={(e) =>
                  handleFetchPerson(formData.document, formData.document_type)
                }
                onChange={(e) =>
                  handleFormChange(DataPersonal.DOCUMENT, e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.dni}
                helperText={errors.dni}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id={DataPersonal.BIRTHDATE}
                label={DatosPersonales.FECHA_NACIMIENTO}
                type={DATE}
                disabled={false}
                value={formData.birthdate ?? ""}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.birthdate,
                    formData?.birthdate,
                    loadState?.current,
                  ),
                  opacity: loadState.current == LoadState.LOADED ? 0.8 : 1,
                  borderRadius: "5px",
                }}
                onChange={(e) =>
                  handleFormChange(DataPersonal.BIRTHDATE, e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.birthdate}
                helperText={errors.birthdate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id={DataPersonal.EMAIL}
                label={DatosPersonales.EMAIL}
                InputLabelProps={{ sx: inputLabelStyles }}
                type={DataPersonal.EMAIL}
                disabled={false}
                style={{
                  border: getValidationColor(
                    errors,
                    errors?.email,
                    formData?.email,
                    loadState?.current,
                  ),
                  opacity: loadState.current == LoadState.LOADED ? 0.8 : 1,
                  borderRadius: "5px",
                }}
                value={formData.email ?? ""}
                onChange={(e) =>
                  handleFormChange(DataPersonal.EMAIL, e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>

            <LocationForm
              formData={formData}
              onChange={handleFormChange}
              errors={errors}
              nacimiento={true}
              disabled={loadState.current === LoadState.LOADED}
              setFormData={setFormData}
              loadState={loadState}
              isFetch={isFetch}
            />
          </Grid>
        </Paper>
      </Box>
      {}
      <GenericLocationBox
        formData={formData}
        onChange={handleFormChange}
        errors={errors}
        loadState={loadState}
        getValidationColor={getValidationColor}
        childForm={true}
        type={type}
        checkFather={checkFather}
        checkMother={checkMother}
        isFetch={isFetch}
      />
    </>
  );
};

export default ChildForm;
