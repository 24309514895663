import { CSSProperties } from "react";

export const stylesBaptmisForm = (
  customStyle: boolean,
  theme: any,
): { typography: CSSProperties } => ({
  typography: {
    display: "flex",
    justifyContent: !!customStyle ? "center" : "flex-start",
    fontSize: "16px",
    fontWeight: 600,
    color: theme.font.primary,
    marginBottom: "20px",
  },
});

export const stylesBaptism = {
  grid: {
    "& > .MuiGrid-item": { paddingTop: 0 },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "25px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#F8F8F8",
    width: "100%",
  },
};

export const inputLabelStyles: CSSProperties = {
  backgroundColor: "white",
};
