import moment from "moment";

export const handleDayProperties = (day: any, events: any[]) => {
    const dayFormatted = day.$isDayjsObject ? day.format('YYYY-MM-DD') : moment(day, 'YYYY-MM-DD').format('YYYY-MM-DD');
    
    const hasEventStartingToday = events.some(event => moment(event.start).format('YYYY-MM-DD') === dayFormatted);
    
    const isFeriado = events.some(event => moment(event.start).format('YYYY-MM-DD') === dayFormatted && event.title === "Feriado");
  
    let style = {
      ...(hasEventStartingToday ? { backgroundColor: "#add8e6" } : {}),
      ...(isFeriado ? { backgroundColor: "#ff6961" } : {}),
    };
  
    return {
      style,
    };
  };
