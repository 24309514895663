import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { LoadState } from "../../enums/LoadStateEnum";
import { NEW_PASSWORD, Sizes } from "../../enums/components/componentsEnum";
import { inputLabelStyles } from "./documentType/DocumentTypeComponentStyles";

export default function SelectComponent({
  id,
  options,
  size,
  label,
  onChange,
  value,
  errors,
  loadState,
  helperText,
  disabled = false,
  onClear = () => {},
}: any) {
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    let country = options.find((country: any) => country.id == value);
    setSelectedCountry(country);

    if (!!country) {
      onChange(id, country);
    }
  }, [options, value]);

  return (
    <>
      <Autocomplete
        id={id}
        options={options}
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => {
          setSelectedCountry(newValue);
          if (newValue) {
            onChange(id, newValue);
          } else {
            onChange(id, "");
            onClear();
          }
        }}
        key={value?.id}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {
              //@ts-ignore
              option.name
            }
          </Box>
        )}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              autoComplete: NEW_PASSWORD,
            }}
            InputLabelProps={{ sx: inputLabelStyles }}
            size={size}
            margin={Sizes.NORMAL}
            error={errors}
            helperText={helperText}
          />
        )}
      />
    </>
  );
}
