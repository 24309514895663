import axios from "axios";
import jwtDecode from "jwt-decode";
import ObservableService, { ObservableEvents } from "../ObservableService";

const baseURL = process.env.REACT_APP_API_URL;

export class AuthService {
  private readonly axiosInstance;
  private iglesiaSelected = null;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL,
    });
  }

  async login({ username, password, document_type }: any): Promise<void> {
    const response = await this.axiosInstance.post(`${baseURL}/auth/login`, {
      username,
      password,
      document_type,
    });

    //@ts-ignore
    if (response?.status === 400) {
      throw "Invalid Credentials";
    }

    //@ts-ignore
    const { access_token } = response.data;
    localStorage.setItem("access_token", access_token);
    const decodedToken: any = jwtDecode(access_token);
    this.iglesiaSelected = decodedToken.rolesIglesia[0];
    localStorage.setItem(
      "iglesiaSelected",
      JSON.stringify(this.iglesiaSelected),
    );
  }
  selectIglesia(iglesia: any): any {
    this.iglesiaSelected = iglesia;
    localStorage.setItem(
      "iglesiaSelected",
      JSON.stringify(this.iglesiaSelected),
    );
    window.location.reload();
  }

  getIglesiaSelected(): any {
    this.iglesiaSelected = JSON.parse(
      localStorage.getItem("iglesiaSelected") || "null",
    );
    return this.iglesiaSelected;
  }

  async register(formState: any): Promise<void> {
    try {
      const token = await this.getToken();
      if (token) {
        const iglesiaSelected = this.getIglesiaSelected();

        this.axiosInstance
          .post(
            `${baseURL}/auth/register`,
            { ...formState },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: { id_iglesia: iglesiaSelected?.iglesia?.id },
            },
          )
          .then(() => {
            ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
              type: "success",
              message: "Se creo el usuario correctamente",
            });
          })
          .catch((error) => {
            ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
              type: "error",
              message: error.response.data.message,
            });
          });
      } else {
        throw new Error("No hay token de acceso");
      }
    } catch (error: any) {
      ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
        type: "error",
        message: error.response.data.message,
      });

      /*  throw error; */
    }
  }

  async addIglesiaToUser(formState: any): Promise<void> {
    try {
      const token = await this.getToken();
      if (token) {
        const iglesiaSelected = this.getIglesiaSelected();

        this.axiosInstance
          .post(
            `${baseURL}/auth/add-role`,
            { ...formState },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: { id_iglesia: iglesiaSelected?.iglesia?.id },
            },
          )
          .then(() => {
            ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
              type: "success",
              message: "Se creo el usuario correctamente",
            });
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
                type: "error",
                message: error.response.data.message,
              });
            } else {
              ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
                type: "error",
                message: "Error al crear el usuario",
              });
            }
          });
      } else {
        throw new Error("No hay token de acceso");
      }
    } catch (error) {
      throw error;
    }
  }

  async getToken(): Promise<string | null> {
    try {
      const access_token = localStorage.getItem("access_token");
      return access_token || null;
    } catch (error) {
      throw error;
    }
  }

  getUserData(): any {
    const access_token = localStorage.getItem("access_token");
    if (!access_token) {
      throw new Error("No hay token de acceso");
    }

    try {
      const decodedToken = jwtDecode(access_token);
      return decodedToken;
    } catch (error) {
      throw error;
    }
  }
}

export default new AuthService();
