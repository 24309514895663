export const validateChildForm = (formData: any): any => {
  const errors: any = {};

  if (!formData.document?.trim()) {
    errors.dni = "Ingrese su DNI.";
  }

  if (!formData.document_type?.trim()) {
    errors.document_type = "Ingrese su Tipo de documento.";
  }

  if (
    !!formData.phone?.trim() &&
    !/^(\+\d{1,3}[- ]?)?\d{12}$/.test(formData.phone)
  ) {
    errors.phone = "Ingrese un número de teléfono válido.";
  }

  const birthdate = new Date(formData.birthdate);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  birthdate.setHours(0, 0, 0, 0);

  if (birthdate > today || birthdate.getFullYear() < 1900) {
    errors.birthdate = "Ingrese una fecha de nacimiento válida.";
  }
  return errors;
};

export const validateAdultForm = (
  formData: any,
  inTherdStep?: boolean,
): any => {
  const errors: any = {};

  //@ts-ignore
  //Check if all fields are empty
  if (
    Object.values(formData).every(
      (field) => typeof field === "string" && !field.trim(),
    )
  ) {
    return errors;
  }

  const fields = [
    "document",
    "document_type",
    "email",
    "phone",
    "name",
    "surname",
    "role",
  ];
  const hasValue = fields.some((field) => {
    return formData[field]?.trim();
  });

  if (hasValue) {
    if (!formData.document?.trim()) {
      errors.document = "Ingrese su Nro de documento.";
    }

    if (!formData.document_type?.trim()) {
      errors.document_type = "Ingrese su Tipo de documento.";
    }

    if (!formData.email?.trim() && !formData.phone?.trim()) {
      errors.email = "Ingrese su Email o Teléfono.";
      errors.phone = "Ingrese su Teléfono o Email.";
    }

    if (formData.email?.trim() && !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Ingrese un Email válido.";
    }

    if (
      formData.phone?.trim() &&
      !/^(\+\d{1,3}[- ]?)?\d{12}$/.test(formData.phone)
    ) {
      errors.phone = "Ingrese un número de teléfono válido.";
    }

    if (!formData.name?.trim()) {
      errors.name = "Ingrese su Nombre.";
    }

    if (!formData.surname?.trim()) {
      errors.surname = "Ingrese su Apellido.";
    }

    if (inTherdStep) {
      if (!formData.role?.trim()) {
        errors.role = "Ingrese su Rol.";
      }
    }
  }

  return errors;
};

export const validateBaptismForm = (formData: any): any => {
  const errors: any = {};

  if (!formData.church.trim()) {
    errors.church = "Ingrese el nombre de la iglesia.";
  }

  if (!formData.location.trim()) {
    errors.location = "Ingrese la ubicación del bautismo.";
  }

  if (!formData.diocese.trim()) {
    errors.diocese = "Ingrese la diócesis del bautismo.";
  }

  if (!formData.date.trim()) {
    errors.date = "Ingrese la fecha del bautismo.";
  }

  if (
    formData.book === null ||
    formData.book === undefined ||
    formData.book === 0
  ) {
    errors.book = "Ingrese el número del libro de registro civil.";
  }

  if (
    formData.folio === null ||
    formData.folio === undefined ||
    formData.folio === 0
  ) {
    errors.folio = "Ingrese el número del folio de registro civil.";
  }

  return errors;
};

export const validateCivilRegistrationForm = (formData: any): any => {
  const errors: any = {};

  if (!formData.office.trim()) {
    errors.office = "Ingrese la oficina del registro civil.";
  }

  if (!formData.certificate.trim()) {
    errors.certificate = "Ingrese el número del certificado de registro civil.";
  }

  if (!formData.page.trim()) {
    errors.page = "Ingrese el número de la página de registro civil.";
  }

  if (!formData.section.trim()) {
    errors.section = "Ingrese la sección del registro civil.";
  }

  if (!formData.date.trim()) {
    errors.date = "Ingrese la fecha del registro civil.";
  }

  if (
    formData.book === null ||
    formData.book === undefined ||
    formData.book === 0
  ) {
    errors.book = "Ingrese el número del libro de registro civil.";
  }

  return errors;
};

export const validateScheduleAppointment = (data: any, type: string): any => {
  const errors: any = {};

  if (data == null || data?.start == null || data?.end == null) {
    errors.date = "Seleccione una fecha para " + type;
  }

  return errors;
};

export const validateFirstStep = (data: any, type: any): any => {
  const errors: any = {};

  errors.scheduleAppointment = validateScheduleAppointment(data.current, type);

  if (Object.keys(errors.scheduleAppointment).length !== 0) {
    errors.hasError = true;
  }

  return errors;
};

export const validateSecondStep = (formData: any): any => {
  const errors: any = {};
  errors.hasError = false;

  errors.child = validateChildForm({ ...formData.child, role: "" });
  if (Object.keys(errors.child).length !== 0) {
    errors.hasError = true;
  }

  return errors;
};

export const validateThirdStep = (formData: any): any => {
  const errors: any = {};
  let inTherdStep = true;
  errors.hasError = false;

  if (formData) {
    //@ts-ignore
    errors.godFather = formData.map((item) => {
      let result = validateAdultForm(item, inTherdStep);

      return result;
    });

    errors.hasError = errors.godFather.some(
      (result: any) => Object.keys(result).length !== 0,
    );
  }

  return errors;
};

export const validateFourthStep = (formData: any): any => {
  const errors: any = {};

  errors.baptism = validateBaptismForm(formData.baptism);
  errors.civilRegistration = validateCivilRegistrationForm(
    formData.civilRegistration,
  );

  return errors;
};

export const isObjectEmpty = (obj: any): boolean => {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object") {
        if (!isObjectEmpty(obj[key])) {
          return false;
        }
      } else if (
        obj[key] !== undefined &&
        obj[key] !== null &&
        obj[key] !== ""
      ) {
        return false;
      }
    }
  }

  return true;
};

export const isValidationSuccessful = <T>(data: T | T[]): boolean => {
  if (Array.isArray(data)) {
    return data.every((item) => isObjectEmpty(item));
  } else {
    return isObjectEmpty(data);
  }
};
