import React from "react";
import { Box, Grid, TextField } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useLocation, useNavigate } from "react-router-dom";
import GenericTable from "../../components/genericTable/GenericTable";
import sacerdoteShadowService from "../../services/sacerdoteShadow";
import { getSacerdoteShadowColumns, initializeFilters, updateFilters } from "../../utils/pages/SacerdoteShadowListUtil";
import { PersonalInfoId, Sacerdote_Shadow_Enum, Sizes } from "../../enums/components/componentsEnum";
import { styles } from "./SacerdoteShadowStyles";

const SacerdoteShadowList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [forceRefresh, setForceRefresh] = React.useState(new Date());
  const [filters, setFilters] = React.useState(initializeFilters(location));
  const sacerdoteShadowColumns = getSacerdoteShadowColumns();

  const sacerdoteShadowActions = [
    {
      icon: (row: any) => (
        <PersonAddIcon
          titleAccess={Sacerdote_Shadow_Enum.CREATE_USER}
        />
      ),
      onClick: async (row: any) => {
        const sacerdoteShadow = await sacerdoteShadowService.findSacerdoteById(row.id);

        if (sacerdoteShadow) {
          //@ts-ignore
          navigate(`/panel/crear-sacerdotes-user/${sacerdoteShadow.id}`, {
            state: { sacerdoteShadow },
          });
        } else {
          console.log(Sacerdote_Shadow_Enum.ERROR_FINDING_SACERDOTE);
        }
      },
    },
  ];
  /**
   * Fetch sectors
   * @returns
   */
  const fetchSacerdotes = async (page: number, pageSize: number, filter?: string,) => {
    let response;
    try {
      response = await sacerdoteShadowService.getSacerdotesNotRegistred(page, pageSize, filter);
    } catch (error) {
      return [];
    }
    return response;
  };

  const renderFilters = () => {
    return (
      <Grid
        container
        gap={2}
        style={styles.gridRenderFilters}
      >
        <Grid item xs={1}>
          <button
            style={styles.gridButtonRender}
            onClick={() => navigate(-1)}
          >
            {Sacerdote_Shadow_Enum.BACK}
          </button>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id={PersonalInfoId.NAME}
            label={Sacerdote_Shadow_Enum.NAME}
            value={filters.find((filter) => filter.param === PersonalInfoId.NAME)?.value || ''}
            onChange={(event) => {
              const newNombre = event.target.value;
              const updatedFilters = updateFilters(filters, PersonalInfoId.NAME, newNombre);
              setFilters(updatedFilters);
            }}
            size={Sizes.SMALL}
            error={false}
            helperText={""}
          />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id={PersonalInfoId.SURNAME}
              label={Sacerdote_Shadow_Enum.LAST_NAME}
              value={filters.find((filter) => filter.param === PersonalInfoId.SURNAME)?.value || ''}
              onChange={(event) => {
                const newNombre = event.target.value;
                const updatedFilters = updateFilters(filters, PersonalInfoId.SURNAME, newNombre);
                setFilters(updatedFilters);
              }}
              size={Sizes.SMALL}
              error={false}
              helperText={""}
            />
          </Grid>
      </Grid>
    );
  };

  return (
    <Box
      sx={styles.boxReturn1}
    >
      <Box
        sx={styles.boxReturn2}
      >
        <div
          style={styles.divBoxReturn2}
        >
        </div>
        <GenericTable<any>
          filtersData={filters}
          refresh={forceRefresh}
          columns={sacerdoteShadowColumns}
          actions={sacerdoteShadowActions}
          title="Sacerdotes"
          fetchData={fetchSacerdotes}
          filters={renderFilters}
        />
      </Box>
    </Box>
  );
};

export default SacerdoteShadowList;
