import { configureStore } from '@reduxjs/toolkit';
import feriadoFilterSlice, { FeriadoFilterState } from './FeriadosSlice';

export type RootState = {
    feriado: FeriadoFilterState;
};
  
export const store = configureStore({
  reducer: {
    feriado: feriadoFilterSlice,
  },
}); 