export enum CertificationValidationMessages {
  INSERT_DOC = "Ingrese documento",
  DNI_ONLY_NUMBERS = "El DNI debe tener sólo números.",
  DNI_LENGTH = "El DNI debe tener entre 6 y 8 caracteres.",
  CERTIFICATE_NOT_FOUND = "No se encontró certificado.",
}

export const DniValidationRegex = {
  ONLY_NUMBERS: /^\d+$/,
};

export const DNI_MIN_LENGTH: number = 6;
export const DNI_MAX_LENGTH: number = 8;

export enum CertificationType {
  BAUTISMO = "Bautismo",
  CONFIRMACION = "Confirmacion",
  CASAMIENTO = "Casamiento",
  COMUNION = "Comunion",
  ALL = "All",
}

export enum RenderLabelTexts {
  NRO_DOC = "Numero de documento",
}
export enum RenderImputTexts {
  TEXT = "text",
  PLACEHOLDER = "Sin espacios ni puntos",
}

export enum RenderButtonTexts {
  SEE_CERTIFICATE = "VER CERTIFICADO",
}

export enum RenderButtonTextsPeople {
  SEE_PERSON = "VER PERSONA",
}
