import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles = {
  paper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "25px",
    width: "100%",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#F8F8F8",
  },
  box: {
    position: "absolute",
    top: "15px",
    right: "15px",
    zIndex: 1,
  },
  button: {
    width: "25px",
    height: "25px",
    minWidth: "unset",
    borderRadius: "50%",
    backgroundColor: theme.button.error.main,
    color: "#fff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: theme.button.error.hover,
    },
  },
  closeIcon: {
    fontSize: "15px",
  },
  typography1: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "16px",
    fontWeight: 600,
    color: theme.font.primary,
    marginBottom: "20px",
  },
  grid1: {
    "& > .MuiGrid-item": { paddingTop: 0 },
  },
  typography2: {
    textAlign: "center",
    fontSize: "0.8em",
    color: "DodgerBlue",
    opacity: "0.6",
  },
  typography3: {
    textAlign: "center",
    fontSize: "0.8em",
  },
};

export const inputLabelStyles: CSSProperties = {
  backgroundColor: "white",
};
