import { LoadState } from "../../enums/LoadStateEnum";

export const getValidationColor = (
  errors: any,
  errorKey: any,
  value: any,
  currentLoadState: any,
  inLocation: any = false,
) => {
  const isLoaded = currentLoadState == LoadState.LOADED;
  const isEditing = currentLoadState == LoadState.EDITING;
  const noErrors = !errorKey && errors && Object.keys(errors).length == 0;
  const hasValue = value !== "" && value !== undefined && value !== null;

  if (isLoaded && hasValue && noErrors) {
    return "1px solid DodgerBlue";
  } else if (isEditing && hasValue) {
    return "1px solid green";
  }
  return "";
};
