import { Status } from "../../enums/components/componentsEnum";
import { theme } from "../../theme";

// La función getTitleAndColor devuelve el título del mensaje y el color del título según el tipo de alerta.
export const getTitleAndColor = (type: Status) => {
  if (type === Status.SUCCESS) {
    return {
      title: "El turno ha sido reservado con éxito",
      color: theme.alert.success,
    };
  } else {
    return {
      title: "Ha ocurrido un error al reservar el turno",
      color: theme.alert.error,
    };
  }
};
