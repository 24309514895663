import { da } from 'date-fns/locale';
import { get } from 'http';
import moment from 'moment';

export const convertHourToUTC = (localTime: string): string => {
  return moment(localTime, "HH:mm").utc().format("HH:mm");
};

export const convertUTCToLocal = (utcTime: string): string => {
  return moment.utc(utcTime, "HH:mm").local().format("HH:mm");
};

export const convertDateToUTC = (localDate: string): string => {
  return moment(localDate, "YYYY-MM-DD").utc().format("YYYY-MM-DD");
};

export const convertUTCToDate = (utcDate: string): string => {
  return moment.utc(utcDate, "YYYY-MM-DD").local().format("YYYY-MM-DD");
};

export const getOnlyDate = (dateTime: string): string => {
    const datePart = dateTime.includes('T') ?  dateTime.split('T')[0] :  dateTime.split(' ')[0];
    return datePart;
}

export const changeTimezoneToUTC = (date:any) : Date=> {
    return getMomentTimezoneToUTC(date).toDate();
  };

  export const getMomentTimezoneToUTC = (date:any) => {
    const localTime = moment(date);
  
    const utcTime = moment.utc({
      year: localTime.year(),
      month: localTime.month(),
      date: localTime.date(),
      hour: localTime.hour(),
      minute: localTime.minute(),
      second: localTime.second()
    });
  
    return utcTime;
  };


export const getCompletedDateUtcForCalendar = (dateTime: string, hours: string): any => {
    const datePart = getOnlyDate(dateTime);
    const timePart = convertUTCToLocal(hours);
    const completedDate = `${datePart}T${timePart}`;
    return completedDate;
  }

  export const getUserTimeZone = (): string => {
    return moment.tz.guess();
  };
  
  export const getTimeZone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };
