// CompleteEventData.tsx
import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions } from "@mui/material";
import BautismoStep from "../../../components/stepers/BautismoStep";
import { defaultErrorsData, initialFormState } from "../../bautismo/utils";
import arquidiocesisService from "../../../services/arquidiocesis";
import {
  Arquidiocesis,
  CompleteEventDataProps,
} from "./utils/completeDataEnum";
import iglesiasService from "../../../services/iglesias";

const CompleteEventData = ({
  open,
  eventId,
  handleClose,
  handleInputChange,
  handleSubmit,
  type,
}: CompleteEventDataProps) => {
  const [formData, setFormData] = useState<any>(initialFormState);
  const [errors, setErrors] = useState<any>(defaultErrorsData);

  const handleFormChange = (key: any, value: string) => {
    const [parentKey, childKey] = key.split(".") as any;

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [parentKey]: {
        ...prevFormData[parentKey],
        [childKey]: value,
      },
    }));
  };

  const eventStepComponents: { [key: string]: React.ComponentType<any> } = {
    Bautismo: BautismoStep,
    Comunion: BautismoStep,
    Confirmacion: BautismoStep,
    Casamiento: BautismoStep,
  };

  useEffect(() => {
    arquidiocesisService
      .getArquidiocesisByIdIgle()
      .then((result: Arquidiocesis) => {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          baptism: {
            ...prevFormData.baptism,
            diocese: result.denomination,
          },
        }));
      })
      .catch((error: any) => {
        console.log("error al obtener la arquidiocesis", error);
      });

    iglesiasService
      .getIglesiaById()
      .then((result: any) => {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          baptism: {
            ...prevFormData.baptism,
            church: result.denomination,
          },
        }));
      })
      .catch((error: any) => {
        console.log("error al obtener la iglesia", error);
      });
  }, []);

  const StepComponent =
    eventStepComponents[type as keyof typeof eventStepComponents];

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        {StepComponent && (
          <StepComponent
            formData={formData}
            handleFormChange={handleFormChange}
            type={type}
            errors={errors}
          />
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => handleSubmit(formData, eventId)}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CompleteEventData;
